import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import UpdateArticle from "../../Modal/UpdateArticle/UpdateArticle";

const AllPublication = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
  }, [blogs]);

  //delete blog
  const handleDelete = (blog) => {
    const agree = window.confirm("Are you ready to delete ?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-blog/${blog._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This blog has been deleted successfully !");
          }
        });
    }
  };

  return (
    <div className="bg-gray-100 pl-3 md:pl-5 lg:pl-10 xl:pl-14 px-7 md:px-10 md:pb-10">
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-2 border-b w-full pb-2 mt-3">
        All articles{" "}
      </h1>
      <div className="flex flex-col justify-center items-center">
        <table className="w-full ">
          <tbody className="py-3">
            {blogs.map((blog, index) => (
              <tr className="my-2 border-b-2 text-center">
                <td className="text-xl text-gray-900 w-1/4 px-2 py-4">
                  {index + 1}
                </td>
                <td className="text-xl text-gray-900 w-1/4 px-2 py-4  ">
                  {blog.title.slice(0, 25)}...
                </td>
                <td className="text-xl text-gray-900 hover:text-blue-500 cursor-pointer w-1/4 px-2 py-4 ">
                  <label htmlFor={`article-${blog._id}`}>Update article</label>
                  <UpdateArticle
                    _id={blog?._id}
                    title={blog?.title}
                    link={blog?.link}
                    description={blog?.description}
                  />
                </td>

                <td
                  className="text-xl text-gray-900 w-2/4 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  onClick={() => handleDelete(blog)}
                >
                  Remove
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllPublication;
