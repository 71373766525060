import React from "react";

const MediaModal = ({ media, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 max-w-3xl rounded-lg flex">
        <img src={media.img} alt="Image" className="w-1/2 rounded-l-lg" />
        <div className="ml-4 flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-2">{media.imageTitle}</h2>
          <p>{media.imageDescription}</p>
          <button onClick={onClose} className="mt-4 text-blue-500">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MediaModal;
