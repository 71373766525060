import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLoaderData } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BsCloudUpload } from "react-icons/bs";
import "../../Editor/editor.css";
import { toast } from "react-hot-toast";

const UpdateOmniyuaCourse = ({ placeholder }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const theme = "snow";
  const loadedData = useLoaderData();
  const {
    _id,
    img,
    courseName,
    courseTitle,
    otherInfos,
    button,
    date,
    dynamicUrl,
    isEvent,
    isPublish,
  } = loadedData;
  console.log(isEvent, isPublish);

  useEffect(() => {
    if (loadedData && loadedData.content) {
      setEditorHtml(loadedData.content);
    }
  }, [loadedData]);

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  // display image
  const [previewImage, setPreviewImage] = useState();
  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setPreviewImage(e.target.files[0]);
    }
  };
  const handleUpdateImage = async () => {
    try {
      const imgBbKey = "f375a7d7cbf6443df6506145faff4414";
      const formData = new FormData();
      formData.append("image", previewImage);

      const response = await fetch(
        `https://api.imgbb.com/1/upload?key=${imgBbKey}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();
      console.log("API response:", responseData);

      if (responseData.success) {
        console.log(responseData.data.url);
        return responseData.data.url; // Return the updated image URL
      } else {
        console.error("Image upload failed:", responseData.error);
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const updateCourse = async (e) => {
    e.preventDefault();

    try {
      const updatedImg = await handleUpdateImage();
      console.log(updatedImg);

      //course name
      const courseName = document.getElementById("courseName");
      const updatedCourseName = courseName.innerText;
      if (!updatedCourseName) {
        toast.error("Course name can't be empty !!!");
        return;
      }

      //course title
      const courseTitle = document.getElementById("courseTitle");
      const updatedCourseTitle = courseTitle.innerText;
      if (!updatedCourseTitle) {
        toast.error("Course title can't be empty !!!");
        return;
      }

      //course date
      const date = document.getElementById("date");
      const updatedDate = date.innerText;
      if (!updatedDate) {
        toast.error("Course date can't be empty !!!");
        return;
      }

      //course dynamicUrl
      const dynamicUrl = document.getElementById("dynamicUrl");
      const updatedDynamicUrl = dynamicUrl.innerText;
      if (!updatedDynamicUrl) {
        toast.error("Course link can't be empty !!!");
        return;
      }

      //course button
      const button = document.getElementById("button");
      const updatedButton = button.innerText;
      if (!updatedButton) {
        toast.error("Course payment button can't be empty !!!");
        return;
      }

      //course otherInfo
      const otherInfo = document.getElementById("otherInfo");
      const otherInfoArray = Array.from(otherInfo.getElementsByTagName("p"));
      const updatedOtherInfo = otherInfoArray.map(
        (paragraph) => paragraph.innerText
      );
      if (!updatedOtherInfo) {
        toast.error("Course information can't be empty !!!");
        return;
      }

      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/api/update-omniyua-course/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            updatedImg: updatedImg || img,
            updatedCourseName: updatedCourseName,
            updatedCourseTitle: updatedCourseTitle,
            updatedOtherInfo: updatedOtherInfo,
            updatedButton: updatedButton,
            updatedDate: updatedDate,
            updatedDynamicUrl: updatedDynamicUrl,
            editorHtml: editorHtml,
            isPublish,
            isEvent,
          }),
        }
      );

      if (response.ok) {
        toast.success("Course updated successfully");
      } else {
        console.error("Error updating course:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };

  const handleAddNewOtherInfo = () => {
    const dataArray = "Add information here";
    const newData = [];

    newData.push(dataArray);

    try {
      const response = fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/omniyua/add-new-other-information/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newData }),
        }
      );

      if (response.ok) {
        toast.success("Wait few seconds !!!");
        window.location.reload();
      } else {
        toast.success("Wait few seconds !!!");
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <div className="bg-white pb-10">
      <Link
        to="/AdminofOmnilife/update-course"
        className="px-5 md:px-10 lg:px-20 4xl:px-28"
      >
        <button className="relative inline-block text-lg group mt-2 xl:mt-3">
          <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span className="relative">
              <MdOutlineKeyboardBackspace className="text-2xl" />
            </span>
          </span>
          <span
            className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
            data-rounded="rounded-lg"
          ></span>
        </button>
      </Link>
      {/* TOP CONTAINER */}

      <div className="flex flex-col lg:flex-row justify-between items-center py-6 md:py-5 px-5 md:px-10 lg:px-20 4xl:px-28">
        <div>
          <label
            required
            htmlFor="img"
            className=" float-right mb-2 cursor-pointer"
          >
            <div className="w-10">
              <div className="bg-blue-500 rounded-lg p-2">
                <BsCloudUpload
                  onChange={handleUpdateImage}
                  className="text-white text-2xl"
                />
              </div>
            </div>
          </label>
          <input
            className="px-3 py-2 rounded-lg shadow-sm border border-none w-full
                                            focus:outline-none bg-white text-gray-900 hidden"
            type="file"
            name="img"
            onChange={(e) => {
              check(e);
            }}
            id="img"
            accept="image/*"
          />

          <div onChange={previewImage} className="">
            {previewImage ? (
              <img
                className="md:h-96 lg:h-[420px] 2xl:h-[450px] 3xl:h-[500px] 5xl:h-[520px] "
                src={URL.createObjectURL(previewImage)}
                alt=" "
              />
            ) : (
              <img
                className="md:h-96 lg:h-[420px] 2xl:h-[450px] 3xl:h-[500px] 5xl:h-[520px]"
                src={img}
                alt="Course"
              />
            )}{" "}
          </div>
        </div>

        <div className="lg:w-3/5 xl:w-4/6 6xl:w-9/12 lg:pl-5 flex flex-col items-center lg:items-start">
          {/* course Name */}
          <div className="flex justify-between items-center">
            <div
              id="courseName"
              contentEditable
              suppressContentEditableWarning={true}
              className="font-semibold outline-none text-3xl lg:text-5xl text-center lg:text-start text-gray-800 pb-3"
            >
              {courseName}{" "}
            </div>
          </div>
          <div className="flex justify-between items-start ">
            <p
              id="courseTitle"
              contentEditable
              suppressContentEditableWarning={true}
              className="font-normal outline-none text-lg lg:text-3xl text-center lg:text-start text-gray-800 mb-4 lg:pb-8"
            >
              {courseTitle}{" "}
            </p>
          </div>
          {/* others */}
          <div className="space-y-6">
            {otherInfos[0] === "\n" ? (
              <button
                onClick={handleAddNewOtherInfo}
                className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
              >
                Add information
              </button>
            ) : (
              <div
                id="otherInfo"
                contentEditable
                suppressContentEditableWarning={true}
                className="h-full font-normal space-y-6 text-lg lg:text-3xl text-center lg:text-start text-gray-800"
              >
                {otherInfos?.map((other, otherIndex) => (
                  <p key={otherIndex}>{other}</p>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/*  AND DATE */}
      <div className="lg:pt-10 lg:px-24 2xl:px-28 6xl:px-32 ">
        <div className="flex items-start pb-10">
          <div className="flex justify-between items-center">
            <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              Date:
            </p>

            <p
              contentEditable
              suppressContentEditableWarning={true}
              id="date"
              className="outline-none ml-2 font-normal text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]  text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
            >
              {date}{" "}
            </p>
          </div>
        </div>
        {/* dynamic url */}
        <div className="flex items-start pb-10">
          <div className="flex justify-between items-center">
            <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              Course link:
            </p>

            <p
              contentEditable
              suppressContentEditableWarning={true}
              id="dynamicUrl"
              className="outline-none ml-2 font-normal text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]  text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
            >
              {dynamicUrl}{" "}
            </p>
          </div>
        </div>
        {/* payment button */}
        <div className="flex items-start pb-10">
          <div className="outline-none flex justify-between items-center">
            <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              Payment button:
            </p>

            <p
              contentEditable
              suppressContentEditableWarning={true}
              id="button"
              className="outline-none ml-2 font-normal text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]  text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
            >
              {button}{" "}
            </p>
          </div>
        </div>
        <ReactQuill
          theme={theme}
          onChange={handleChange}
          value={editorHtml}
          modules={UpdateOmniyuaCourse.modules}
          formats={UpdateOmniyuaCourse.formats}
          bounds=".app"
          placeholder={placeholder}
          style={{ color: "black" }}
        />

        <button
          onClick={updateCourse}
          className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg mt-6"
        >
          Save Content
        </button>
      </div>
    </div>
  );
};

UpdateOmniyuaCourse.propTypes = {
  placeholder: PropTypes.string,
};

UpdateOmniyuaCourse.modules = {
  toolbar: [
    [{ header: "2" }],
    [{ size: ["large"] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

UpdateOmniyuaCourse.formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default UpdateOmniyuaCourse;
