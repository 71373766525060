import React from 'react';
import AllCourses from '../CoursesSection/CoursesUi/AllCourses';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';


const CourseLayout = () => {
    return (
        <div>
            <Navbar/>
            <AllCourses/>
            <Outlet/>
            
        </div>
    );
};

export default CourseLayout;