import React, { useState } from "react";
import logo from "../assets/logo1.png";
import "./Nom.css"; // Import the CSS file

const NominationForm = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    Name: "",
    phoneNumber: "",
    village: "",
    email: "",
  });

  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate email
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setValidEmail(emailRegex.test(value));
    }

    // Validate phone number
    if (name === "phoneNumber") {
      const phoneRegex = /^\+?\d{10,}$/;
      setValidPhone(phoneRegex.test(value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if both email and phone number are valid before submitting
    if (validEmail && validPhone) {
      onSubmit(formData);
    } else {
      alert("Please enter valid email and phone number.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-filter backdrop-blur-lg mt-20">
      <div className="bg-white p-8 rounded-lg relative w-120 modal-outline">
        <img src={logo} alt="Omni Logo" className="h-10 mb-4" />

        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-gray-600"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex flex-col mb-4">
            <label
              htmlFor="Name"
              className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 mb-2 mt-5"
            >
              Name:
            </label>
            <input
              type="text"
              id="Name"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              className="border border-black px-3 py-2 rounded-md text-black bg-white"
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="phoneNumber"
              className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 mb-2"
            >
              Phone number:
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="border border-black px-3 py-2 rounded-md text-black bg-white"
              placeholder="+91"
              required
            />
            {!validPhone && (
              <p className="text-red-500">Please enter a valid phone number.</p>
            )}
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="village"
              className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 mb-2"
            >
              Village:
            </label>
            <input
              type="text"
              id="village"
              name="village"
              value={formData.village}
              onChange={handleChange}
              className="border border-black px-3 py-2 rounded-md text-black bg-white"
              placeholder="Your nominated village"
              required
            />
          </div>
          <div className="flex flex-col mb-4">
            <label
              htmlFor="email"
              className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 mb-2"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border border-black px-3 py-2 rounded-md text-black bg-white"
              placeholder="Enter your email"
              required
            />
            {!validEmail && (
              <p className="text-red-500">
                Please enter a valid email address.
              </p>
            )}
          </div>
          <button
            type="submit"
            className="mx-auto mb-4 relative inline-block text-lg group"
          >
            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-white transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-gray-800 group-hover:bg-gray-50">
              <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-900"></span>
              <span className="absolute left-0 w-full h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-50 group-hover:-rotate-180 ease"></span>
              <span className="relative">Submit</span>
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default NominationForm;
