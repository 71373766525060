import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import AllPublicationModal from "../../Modal/AllPublicationModal/AllPublicationModal";

export default function PublicationSlider() {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setSliders(data);
      });
  }, [sliders]);

  return (
    <div className="bg-white flex flex-col justify-center   space-y-5 md:space-y-10">
      <div className=" bg-white">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {sliders.map((slider) => (
            <SwiperSlide className="" key={slider.id}>
              <a href={slider.link} target="blank" className="flex flex-col">
                <div className="px-12 md:px-14 lg:px-8 2xl:px-14">
                  <img
                    src={slider.img}
                    className="h-60 w-full md:h-[400px]  lg:h-[650px]  xl:h-[450px] 5xl:h-[550px]  md:px-6 3xl:px-2 pb-2 lg:pb-4 rounded-xl rounded-b-xl xl:rounded-2xl xl:rounded-b-2xl 2xl:rounded-b-3xl 3xl:rounded-3xl"
                    alt="sliderimage"
                  />

                  <p className="font-medium text-[16px] md:text-2xl lg:text-[28px] 3xl:text-[30px] 6xl:text-[24px] text-center xl:text-start  py-2 xl:pl-5 5xl:pl-3 text-gray-800 tracking-normal xl:tracking-wide">
                    {slider.title}
                  </p>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <a
        href="/articles"
        className="cursor-pointer flex justify-center items-center lg:hidden"
      >
        <label class="relative inline-block text-lg group" type="submit">
          <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span class="absolute left-0 w-full h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span class="relative">View all articles</span>
          </span>
          <span
            class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
            data-rounded="rounded-lg"
          ></span>
        </label>
      </a>
    </div>
  );
}
