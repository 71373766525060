import React, { useEffect } from "react";
import contribute from "../../assets/contribute123.png";
import CourseNavbar from "../../Navbar/CourseNavbar";
import Footer from "../../HomePage/Footer";

const ContributionPage = () => {
  //   //razorpay
  useEffect(() => {
    const loadPaymentButtonScript = () => {
      const form = document.getElementById("form");
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_MKihoHGZqjXFsV";

      if (form) {
        form.appendChild(script);
      }
    };

    loadPaymentButtonScript();

    return () => {
      const script = document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/payment-button.js"]'
      );
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);
  return (
    <article content="Contribute" className="bg-white font-sans ">
      <CourseNavbar className="z-50" />
      <div className="">
        <div className="px-5 md:px-10 lg:px-0 lg:pl-10 xl:pl-20 4xl:pl-28  md:pb-0 md:pt-10  flex flex-col  space-y-8 bg-white  hidden lg:block z-0">
          <div className="flex flex-col lg:flex-row justify-between items-center pb-0 md:pb-5 lg:pb-0 z-0">
            <div className="w-full  lg:w-6/12 5xl:w-7/12 6xl:w-7/12  space-y-1 md:space-y-5 lg:space-y-3 xl:space-y-5 4xl:space-y-6 6xl:space-y-7 ">
              <p className="text-sm md:text-[26px] lg:text-[19px] xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start leading-20 md:leading-[40px] lg:leading-[40px] 3xl:leading-[50px] xl:leading-[50px] 2xl:leading-[54px]  6xl:leading-[70px] lg:pr-0 -mt-1  z-0">
                Our aim is to be self-sufficient in our operations by offering
                transformative experiences. However, there are times when
                pursuing our holistic interests requires us to rely on the
                support of generous individuals like you. Your contribution is
                vital in sustaining this noble endeavour and allowing us to
                continue serving the sacredness of life.
              </p>
            </div>
            <div className="w-full z-0     md:w-10/12 lg:w-5/12 xl:w-6/12 5xl:w-6/12 6xl:w-5/12 pr-0 md:pr-10">
              <img
                src={contribute}
                className="w-full z-0 3xl:h-10/12"
                alt="Contribute"
              />
            </div>
          </div>
        </div>
        {/* hidden */}
        <div className="pt-5 md:pt-10 flex flex-col-reverse justify-center lg:hidden">
          <p className=" px-5 py-5 md:px-10 md:py-10  text-[20px] md:text-[26px] lg:text-[23px] xl:text-[25px] 3xl:text-[27px] 4xl:text-[29px] 5xl:text-[31px] 6xl:text-[39px] text-gray-800 font-normal  text-center lg:text-start leading-20 md:leading-[42px] lg:leading-[42px] xl:leading-[50px] 2xl:leading-[54px] 3xl:leading-[60px] 6xl:leading-[70px] lg:pr-10 -mt-1 md:-mt-4 lg:-mt-0">
            Our aim is to be self-sufficient in our operations by offering
            transformative experiences. However, there are times when pursuing
            our holistic interests requires us to rely on the support of
            generous individuals like you. Your contribution is vital in
            sustaining this noble endeavour and allowing us to continue serving
            the sacredness of life.
          </p>
          <img src={contribute} className="w-full lg:hidden" alt="" />
        </div>
      </div>
      <div className="px-5 py-5 md:px-10 lg:px-10 xl:px-20 4xl:px-28  md:pb-0 md:pt-10 lg:pt-5 xl:pt-8 2xl:pt-10  4xl:pt-10 5xl:pt-10">
        <h3 className="text-lg md:text-[25px] lg:text-[40px] font-normal text-gray-800 leading-8 md:leading-[50px] lg:leading-[60px] text-center lg:text-start py-1 md:py-5">
          There are two meaningful ways you can support us: through monetary
          donations or by volunteering your time and skills.
        </h3>
        <div className="flex flex-col lg:flex-row justify-between lg:items-start py-14 lg:py-20 3xl:py-24">
          {/* left */}
          <div className="space-y-4 3xl:space-y-5 mb-14 lg:mb-0 lg:pr-10 xl:pr-16">
            <h1 className="text-[19px] md:text-[23px] lg:text-[19px] xl:text-[25px] 6xl:text-[31px] font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              Monetary Donations
            </h1>
            <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              Your financial contribution plays a crucial role in ensuring the
              continued growth and impact of Omniverse Life. Your donation will
              go towards various aspects of our research and operations,
              including program development, facilitator and researcher support,
              infrastructure, and outreach. Every contribution, no matter the
              amount, makes a difference.
            </p>
            {/* <button class="relative inline-block text-lg group" type="submit">
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Contribute</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </button> */}
            <form className="pt-2" id="form"></form>
          </div>
          {/* right */}
          <div className="space-y-4">
            <h1 className="text-[19px] md:text-[23px] lg:text-[19px] xl:text-[25px] 6xl:text-[31px] font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              Volunteer Your Time and Skills
            </h1>
            <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px] pb-4">
              In addition to monetary support, we also appreciate and value the
              donation of your time and skills. As a volunteer, you can
              contribute your expertise and passion towards specific projects,
              events, or initiatives. By sharing your knowledge and talents, you
              become a willing contributor to the sacredness of life.
            </p>
            <a
              href="https://sharedpublic.notion.site/Contribute-e3a665922df54ad79b1772e1e7f121e0"
              target="blank"
            >
              <button class="relative inline-block text-lg group" type="submit">
                <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                  <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                  <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                  <span class="relative">Volunteer</span>
                </span>
                <span
                  class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                  data-rounded="rounded-lg"
                ></span>
              </button>
            </a>
          </div>
        </div>
        <h3 className="text-base md:text-[22px] lg:text-[27px] font-normal  text-gray-800 leading-8 md:leading-[50px] lg:leading-[60px] text-center  py-1 md:py-5">
          Together, we can create a world where holistic well-being and
          interconnectedness thrive. Thank you for your generosity and support
          in sustaining this noble mission.
        </h3>
        <div className="pt-32 lg:pt-28">
          <Footer />
        </div>
      </div>
    </article>
  );
};

export default ContributionPage;
