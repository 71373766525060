import React from "react";
import man1 from "../assets/trans-need-1.png";
import man2 from "../assets/need2.png";
import rainbow from "../assets/rainbow.png";
import process from "../assets/process2.png";
import approach from "../assets/approach2.png";
import method from "../assets/method2.png";
import line from "../assets/The need.png";

const Need = () => {
  const needs = [
    {
      id: 1,
      image: man1,
      text1: "Self Knowledge & transformation for",
      text2: "Inner Harmony",
    },
    {
      id: 2,
      image: man2,
      text1: "World Knowledge & Systems Transformation for",
      text2: "Outer Harmony",
    },
  ];
  const processes = [
    {
      id: 1,
      image: process,
      title: "Process",
      description: "Reflective",
    },
    {
      id: 2,
      image: approach,
      title: "Approach",
      description: "Omnidisciplinary",
    },
    {
      id: 3,
      image: method,
      title: "Method",
      description: "Think Systematically, Act Locally",
    },
  ];
  return (
    <div className="relative px-5 md:px-10 lg:mb-20  lg:px-8 xl:px-14 4xl:px-24 6xl:px-20 py-0 mt-0 lg:-mt-28 2xl:-mt-28 3xl:-mt-36">
      <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center  py-3 md:py-8 text-gray-800 font-sans">
        The Need
      </h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-24 2xl:gap-x-32">
        {needs.map((n) => (
          <div>
            <div className="flex flex-col justify-center items-center relative hidden lg:block z-30">
              <div className="lg:h-[350px] xl:h-[390px] 2xl:h-[420px] 3xl:h-[450px] 5xl:h-[520px] 6xl:h-[570px]">
                <img
                  className="h-[330px] xl:h-[370px] 2xl:h-[400px] 3xl:h-[430px] 5xl:h-[500px] 6xl:h-[550px] w-full"
                  src={n.image}
                  alt="need"
                />
              </div>
              <div className="py-2 space-y-2">
                <p className="text-center font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 tracking-normal xl:tracking-wide">
                  {n.text1}
                </p>
                <p className="text-center font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 tracking-normal xl:tracking-wide">
                  {n?.text2}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center md:h-[450px] mb-5 md:mb-20 md:px-10 md:mt-10 lg:hidden">
              <img
                className="h-66 w-full md:h-[430px] "
                src={n.image}
                alt="need"
              />

              <div className="pt-5">
                <p className="text-[11px] md:text-[19px] text-center text-gray-800">
                  {n.text1}
                </p>
                <p className="text-[11px] md:text-[19px] text-center text-gray-800">
                  {n?.text2}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className=" md:h-[700px]  lg:h-[800px] xl:h-[1000px] 4xl:h-[1180px] pt-8">
        <img
          src={rainbow}
          className=" hidden md:block md:h-[250px] md:w-[480px]  lg:h-[380px] lg:w-[590px] xl:h-[580px]  xl:w-[830px] 3xl:h-[550px]  3xl:w-[860px]  4xl:w-[800px] 4xl:h-[550px] 6xl:w-[800px] absolute right-0 xl:-right-10  md:bottom-[530px] lg:bottom-[500px] xl:bottom-[500px] 3xl:bottom-[550px] 4xl:bottom-[690px]"
          alt="rainbow"
        />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-5  lg:grid-cols-3 lg:gap-x-10 xl:gap-x-24 md:pt-14 lg:pt-32 xl:pt-60 3xl:pt-56 z-20">
          {processes.map((proText) => (
            <a
              href={proText.route}
              className="flex flex-col justify-center items-center w-full space-y-0 md:space-y-2 3xl:space-y-3 4xl:space-y-4 5xl:space-y-5 pb-4"
            >
              <p className="font-semibold tracking-wider text-center text-2xl xl:text-4xl 6xl:text-5xl  text-gray-800">
                {proText.title}
              </p>
              <p className="font-medium text-[15px] md:text-[16px] xl:text-[18px] 3xl:text-[20px] 4xl:text-[22px]  6xl:text-[24px] text-center text-gray-800 pb-4 cursor-pointer pt-3 z-40">
                {proText.description}
              </p>

              <img
                src={proText.image}
                alt="yoga"
                className="h-72 w-66 md:h-[250px] lg:h-[280px] xl:h-[320px] xl:w-[372px]  3xl:h-[390px] 4xl:h-[390px] 5xl:h-[400px] 6xl:h-[490px] 2xl:w-full rounded-lg z-40 "
              />
            </a>
          ))}
        </div>
      </div>
      <img
        src={line}
        className="hidden md:block md:h-[280px] lg:h-[350px] w-full xl:h-[400px] 4xl:h-[480px] absolute left-0 md:bottom-3 lg:-bottom-12 3xl:-bottom-16 4xl:bottom-3 5xl:-bottom-16 6xl:-bottom-20 z-0"
        alt="line"
      />
    </div>
  );
};

export default Need;
