import React, { useEffect, useState } from "react";
import AllPublicationModal from "../../Modal/AllPublicationModal/AllPublicationModal";
import snail2 from "../../assets/snail22.png";
import PublicationSlider from "./PublicationSlider";

const PublicationUi = () => {
  
  return (
    <div
      className="relative flex flex-col bg-white px-0 py-5 md:pb-10 md:px-7 lg:px-8 6xl:px-20 md:py-10"
      id="articles"
    >
      <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center  pb-5 md:pb-14 6xl:pb-16 text-gray-800">
      Articles
      </h1>
      <div
        className="flex flex-col xl:flex-row-reverse
          justify-between"
      >
        <div className=" w-full xl:w-5/12">
          <img
            src={snail2}
            alt="snail"
            className="hidden xl:block absolute   xl:h-[520px] xl:w-[550px] 2xl:h-[680px] 5xl:h-[700px] 6xl:h-[800px] 3xl:right-0"
          />
        </div>
   <div className="xl:w-7/12">
   <PublicationSlider/>
   <div className="hidden xl:block xl:mt-7 xl:ml-16">
    <a href='/articles' className="cursor-pointer hidden lg:block">
   <label
     
     class="relative inline-block text-lg group"
     type="submit"
   >
     <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
       <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
       <span class="absolute left-0 w-full h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
       <span class="relative">View all articles</span>
     </span>
     <span
       class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
       data-rounded="rounded-lg"
     ></span>
   </label>
   </a>
        
            </div>
   </div>
 
          </div>
      </div>
    
  );
};

export default PublicationUi;
/*

       <div className="grid grid-cols-2 grid-rows-2 gap-x-10 gap-y-10 w-2/3">
  {blogs.map((blog) => (
    <a href={blog.link} target="blank">
      <div className="flex flex-col justify-between items-center w-full  shadow-xl rounded-2xl">
        <img src={blog.img} alt="blog" className="h-[300px] w-[350px] rounded--xl" />
      </div>
    </a>
  ))}
</div>



<h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-center font-normal  text-gray-800">
            Ruminations
          </h1>



          <div className="flex justify-center items-center w-full bg-white">
        <label
          htmlFor="my-modal-3"
          class="relative inline-block text-lg group"
          type="submit"
        >
          <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span class="absolute left-0 w-56 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span class="relative">View all publications</span>
          </span>
          <span
            class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
            data-rounded="rounded-lg"
          ></span>
        </label>

        <AllPublicationModal />
      </div>






       <div className="flex flex-col justify-around lg:w-full xl:w-3/5 relative">
          <div className="grid grid-cols-1  md:grid-cols-2 gap-x-5 lg:gap-x-10 xl:gap-x-20 gap-y-0 w-full  pr-0">
        
          {blogs.map(blog => 
         
            <a href={blog.link} target="blank" className='bg-white rounded-xl cursor-pointer'>

          <div className= " flex flex-col  justify-between items-center w-full h-[380px] bg-white  md:w-[310px] lg:w-full xl:w-[370px]  md:h-[470px] 3xl:h-[550px] 6xl:h-[600px] 6xl:w-full my-4  pb-5">
              <img src={blog.img} alt='blog' className='h-[300px]  md:h-[370px] lg:h-[390px] 3xl:h-[500px] 6xl:h-[580px]'/>
              <h1 className='text-base md:text-xl font-medium px-3 py-1 2xl:py-3 text-gray-800'>{blog.title}</h1>
          </div>
             </a>
      
          )}
          </div>

          <div className="flex justify-center items-center xl:justify-items-end xl:items-end   w-full bg-white absolute -bottom-10 lg:-bottom-16   xl:left-60">
            <label
              htmlFor="my-modal-3"
              class="relative inline-block text-lg group"
              type="submit"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">View all articles</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </label>

            <AllPublicationModal />
          </div>
        </div>
*/
