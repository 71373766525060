import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo1.png";
import Event from "../EventSection/EventUI/Event";

const CourseNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div class=" text-gray-900 z-50 font-sans">
      <div class="px-4 py-5 mx-auto sm:max-w-xl md:min-w-full lg:max-w-screen-xl 2xl:max-w-full md:px-10  lg:px-8 3xl:px-14   lg:py-[20px] xl:px-20 2xl:px-20 4xl:px-28 ">
        <div class="relative flex items-center justify-between ">
          <a href="/">
            <img
              src={logo}
              className="h-10 w-36 md:h-14 md:w-48 lg:h-14 lg:w-52 6xl:h-20 6xl:w-80"
              alt="logo"
            />
          </a>
          <ul class="flex  items-center hidden lg:space-x-4 xl:space-x-[30px] 2xl:space-x-[32px] 6xl:space-x-[41px] lg:flex ">
            <li>
              <Link
                to="/about"
                className="font-medium text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] tracking-wide uppercase text-gray-800 "
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to="/participate"
                className="font-medium text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 "
              >
                Participate
              </Link>
            </li>

            <li>
              <Link
                to="/projects"
                className="font-medium text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] tracking-wide uppercase text-gray-800 "
              >
                Projects
              </Link>
            </li>
            <li>
              <Link
                to="/contribute"
                className="font-medium text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 "
              >
                contribute
              </Link>
            </li>
            <li>
              <Link
                to="/library"
                className="font-medium text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 "
              >
                Library
              </Link>
            </li>

            <li>
              <Link
                to="/articles"
                className="font-medium uppercase text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] tracking-wide text-gray-800 "
              >
                Articles
              </Link>
            </li>

            <li>
              <label
                htmlFor="course-modal"
                className="font-medium uppercase text-base lg:text-[13px] xl:text-[17px] 6xl:text-[22px] tracking-wide text-gray-800 "
              >
                events
              </label>
              <Event />
            </li>
          </ul>
          <div className="lg:hidden">
            <button
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full z-50">
                <div className="p-5 bg-white border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4 md:px-5">
                    <div>
                      <a href="/">
                        <img
                          src={logo}
                          className="h-10 w-36 md:h-14 md:w-48"
                          alt="logo"
                        />
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <Link
                          to="/about"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/participate"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          participate
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/projects"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          projects
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contribute"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          contribute
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/library"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          Library
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/all-articles"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          articles
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/events"
                          className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          events
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseNavbar;
