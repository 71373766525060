import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MdOutlinePublishedWithChanges, MdUnpublished } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import EditVideoModal from "./EditVideoModal";

const AdminAllVideo = () => {
  const [videos, setVideos] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-media-video/")
      .then((res) => res.json())
      .then((data) => {
        setVideos(data);
      });
  }, []);

  const handleDeleteVideo = (video) => {
    const agree = window.confirm("Are you sure to delete this video?");
    if (agree) {
      fetch(`https://omniverse-server-3222344a8118.herokuapp.com/delete-media-video/${video._id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This video has been deleted successfully !");
            setVideos(videos.filter((vid) => vid._id !== video._id));
          }
        });
    }
  };

  const handlePublishVideo = async (id, publishStatus) => {
    const confirmation = window.confirm(
      `Are you sure you want to ${
        publishStatus ? "publish" : "unpublish"
      } this video?`
    );

    if (!confirmation) return;

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/update-video-publish-status/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isPublish: publishStatus }),
        }
      );

      if (response.ok) {
        toast.success(
          `Video ${publishStatus ? "published" : "unpublished"} successfully!`
        );
        setVideos(
          videos.map((vid) =>
            vid._id === id ? { ...vid, isPublish: publishStatus } : vid
          )
        );
      } else {
        console.error("Failed to update publish status");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleEditVideo = (video) => {
    setSelectedVideo(video);
    setIsEditModalOpen(true);
  };

  const handleSaveVideo = async (
    id,
    updatedTitle,
    updatedDescription,
    updatedVideoUrl
  ) => {
    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/update-media-video/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            videoTitle: updatedTitle,
            videoDescription: updatedDescription,
            videoUrl: updatedVideoUrl,
          }),
        }
      );

      if (response.ok) {
        toast.success("Video updated successfully!");
        const updatedVideos = videos.map((vid) =>
          vid._id === id
            ? {
                ...vid,
                videoTitle: updatedTitle,
                videoDescription: updatedDescription,
                videoUrl: updatedVideoUrl,
              }
            : vid
        );
        setVideos(updatedVideos);
      } else {
        console.error("Failed to update video");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setIsEditModalOpen(false);
  };

  return (
    <div className="bg-gray-100 pl-3 md:pl-5 lg:pl-10 xl:pl-14 px-7 md:px-10 py-10">
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-2 border-b w-full pb-2 mt-3">
        All Videos
      </h1>
      <div className="flex flex-col justify-center items-center">
        <table className="w-full max-h-[100vh] overflow-y-scroll scrollbar-hide">
          <tbody className="py-3">
            {videos.map((video, index) => (
              <tr className="my-2 border-b-2 text-center" key={video._id}>
                <td className="text-xl text-gray-900 w-1/4 px-2 py-4">
                  {index + 1}
                </td>
                <td className="text-xl text-gray-900 hover:text-blue-500 cursor-pointer w-2/4 px-2 py-4">
                  <a
                    href={video.videoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {video.videoTitle}
                  </a>
                </td>
                <td
                  className="text-xl text-gray-900 w-1/4 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  onClick={() => handleDeleteVideo(video)}
                >
                  Remove
                </td>
                <td
                  onClick={() =>
                    handlePublishVideo(video._id, !video.isPublish)
                  }
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    {video.isPublish ? (
                      <MdUnpublished className="text-2xl text-red-500 hover:text-white" />
                    ) : (
                      <MdOutlinePublishedWithChanges className="text-2xl text-green-500 hover:text-white" />
                    )}
                  </div>
                </td>
                <td
                  onClick={() => handleEditVideo(video)}
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <FaPencilAlt className="text-xl text-blue-500 hover:text-white" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isEditModalOpen && selectedVideo && (
        <EditVideoModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          video={selectedVideo}
          onSave={handleSaveVideo}
        />
      )}
    </div>
  );
};

export default AdminAllVideo;
