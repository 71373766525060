import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLoaderData } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BsCloudUpload } from "react-icons/bs";
import { toast } from "react-hot-toast";

const UpdateExpertise = ({ placeholder }) => {
  const loadedData = useLoaderData();
  console.log(loadedData);
  const { _id, img, expertiseName } = loadedData || {};

  // display image
  const [previewImage, setPreviewImage] = useState();
  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setPreviewImage(e.target.files[0]);
    }
  };

  const handleUpdateImage = async () => {
    try {
      const imgBbKey = "f375a7d7cbf6443df6506145faff4414";
      const formData = new FormData();
      formData.append("image", previewImage);

      const response = await fetch(
        `https://api.imgbb.com/1/upload?key=${imgBbKey}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();
      console.log("API response:", responseData);
      if (responseData.success) {
        console.log(responseData.data.url);
        return responseData.data.url; // Return the updated image URL
      } else {
        console.error("Image upload failed:", responseData.error);
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const UpdateExpertise = async (e) => {
    e.preventDefault();

    try {
      // Update the image and wait for the URL to resolve
      const updatedImg = await handleUpdateImage();
      console.log(updatedImg);

      // Expertise name
      const expertiseNameElement = document.getElementById("expertiseName");
      const updatedExpertsieName = expertiseNameElement.textContent.trim();
      if (!updatedExpertsieName) {
        toast.error("Expertise name can't be empty !!!");
        return;
      }

      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/api/update-expertise/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            updatedImg: updatedImg || img, // Use the updatedImg if available, otherwise use the original img
            updatedExpertiseName: updatedExpertsieName,
          }),
        }
      );

      if (response.ok) {
        toast.success("Expertise updated successfully");
      } else {
        console.error("Error updating expertise:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating expertise:", error);
    }
  };

  return (
    <div className="bg-white pb-10">
      <Link
        to="/AdminofOmnilife/update-network"
        className="px-5 md:px-10 lg:px-20 4xl:px-28"
      >
        <button className="relative inline-block text-lg group mt-2 xl:mt-3">
          <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span className="relative">
              <MdOutlineKeyboardBackspace className="text-2xl" />
            </span>
          </span>
          <span
            className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
            data-rounded="rounded-lg"
          ></span>
        </button>
      </Link>
      {/* TOP CONTAINER */}

      <div className="flex flex-col lg:flex-row justify-between items-center py-6 md:py-5 px-5 md:px-10 lg:px-20 4xl:px-28">
        <div>
          <label
            required
            htmlFor="img"
            className=" float-right mb-2 cursor-pointer"
          >
            <div className="w-10">
              <div className="bg-blue-500 rounded-lg p-2">
                <BsCloudUpload
                  onChange={handleUpdateImage}
                  className="text-white text-2xl"
                />
              </div>
            </div>
          </label>
          <input
            className="px-3 py-2 rounded-lg shadow-sm border border-none w-full
                                              focus:outline-none bg-white text-gray-900 hidden"
            type="file"
            name="img"
            onChange={(e) => {
              check(e);
            }}
            id="img"
            accept="image/*"
          />

          <div onChange={previewImage} className="">
            {previewImage ? (
              <img
                className="md:h-96 lg:h-[420px] 2xl:h-[450px] 3xl:h-[500px] 5xl:h-[520px] "
                src={URL.createObjectURL(previewImage)}
                alt=" "
              />
            ) : (
              <img
                className="md:h-96 lg:h-[420px] 2xl:h-[450px] 3xl:h-[500px] 5xl:h-[520px]"
                src={img}
                alt="Course"
              />
            )}{" "}
          </div>
        </div>

        <div className="lg:w-3/5 xl:w-4/6 6xl:w-9/12 lg:pl-5 flex flex-col items-center lg:items-start">
          {/* Voluntering Name */}
          <div className="flex justify-between items-center">
            <div
              id="expertiseName"
              contentEditable
              suppressContentEditableWarning={true}
              className="font-semibold outline-none text-3xl lg:text-5xl text-center lg:text-start text-gray-800 pb-3"
            >
              {expertiseName}{" "}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={UpdateExpertise}
        className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg mt-6"
      >
        Save Content
      </button>
    </div>
  );
};

UpdateExpertise.propTypes = {
  placeholder: PropTypes.string,
};

export default UpdateExpertise;
