import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/UserContext";
import loginImage from "../assets/resources.jpg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const { signIn, resetPassword } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  //login
  const handleLogin = (event) => {
    event.preventDefault();
    const form = event.target;
    const email = form.email.value;
    const password = form.password.value;

    signIn(email, password)
      .then((result) => {
        console.log("r", result);
        const user = result.user;

        if (result.user) {
          toast.success("Welcome Admin");
          navigate(from, { replace: true });

          form.reset("");
        } else {
          toast.error("Invalid email / password. Enter correct information");
        }
      })
      .catch((error) => {
        toast.error("Invalid email / password. Enter correct information");
      });
  };
  //reset password
  const handleForgotPassword = (event) => {
    event.preventDefault();

    const email = "snehapoddar11@gmail.com";

    resetPassword(email)
      .then(() => {
        toast.success("Password reset link sent to your email.");
      })
      .catch((error) => {
        toast.error("Failed to send password reset link.");
      });
  };

  return (
    <section class="relative flex flex-wrap lg:h-screen lg:items-center bg-white">
      <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
        <div class="mx-auto max-w-lg text-center">
          <h1 class="text-2xl font-bold sm:text-3xl text-gray-800">
            Login as admin !
          </h1>
        </div>

        <form
          onSubmit={handleLogin}
          class="mx-auto mb-0 mt-8 max-w-md space-y-4"
        >
          <div>
            <label for="email" class="sr-only text-gray-800">
              Email
            </label>

            <div class="relative">
              <input
                type="email"
                name="email"
                class="w-full rounded-lg bg-white outline-none border-gray-200 p-4 pe-12 text-sm shadow-lg text-gray-800"
                placeholder="Enter email"
              />
            </div>
          </div>

          <div>
            <label for="password" class="sr-only text-gray-800">
              Password
            </label>

            <div class="relative">
              <input
                type={passwordType}
                name="password"
                class="w-full rounded-lg bg-white outline-none border-gray-200 p-4 pe-12 text-sm shadow-lg text-gray-800"
                placeholder="Enter password"
              />
              <div
                className="absolute right-3 top-4 md:top-3 md:right-6"
                onClick={togglePassword}
              >
                {passwordType === "password" ? (
                  <AiOutlineEye className="text-gray-800 text-xl md:text-3xl" />
                ) : (
                  <AiOutlineEyeInvisible className="text-gray-800 text-xl md:text-3xl" />
                )}
              </div>
            </div>
          </div>
          <p
            className="text-blue-500 hover:underline cursor-pointer text-base"
            onClick={handleForgotPassword}
          >
            Forget password
          </p>
          <div class="flex items-center justify-between">
            <button
              type="submit"
              class="inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>

      <div class="relative h-64 w-full sm:h-96 lg:h-full lg:w-1/2">
        <img
          alt="Welcome"
          src={loginImage}
          class="absolute inset-0 w-full 3xl:h-11/12"
        />
      </div>
    </section>
  );
};


export default Login;
