import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "./Swiper.css"; // Ensure this path is correct

const Funding = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchOpportunities = async () => {
      const isPublish = true;
      try {
        const fundingsResponse = await fetch(
          `https://omniverse-server-3222344a8118.herokuapp.com/get-funding/${isPublish}`
        );
        const fundingsData = await fundingsResponse.json();

        const volunteeringsResponse = await fetch(
          `https://omniverse-server-3222344a8118.herokuapp.com/get-volunteering/${isPublish}`
        );
        const volunteeringsData = await volunteeringsResponse.json();

        const modifiedVolunteeringsData = volunteeringsData.map(
          (volunteering) => ({
            ...volunteering,
            name: volunteering.volunteerName,
          })
        );

        const combinedOpportunities = [
          ...fundingsData,
          ...modifiedVolunteeringsData,
        ];
        setOpportunities(combinedOpportunities);
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    };

    fetchOpportunities();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Call on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bg-white relative p-5 md:p-10 lg:p-14 xl:px-16 mt-10">
      <div className="container mx-auto ">
        <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-normal text-center text-gray-800 mb-5 md:mb-9 2xl:mb-20">
          Funding & Volunteering
        </h1>
        {opportunities.length > 0 ? (
          <Swiper
            navigation={true}
            modules={[Navigation]}
            slidesPerView={isMobile ? 1 : Math.min(opportunities.length, 2)}
            spaceBetween={5}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={opportunities.length > 1}
            className="mySwiper"
          >
            {opportunities.map((opportunity) => (
              <SwiperSlide key={opportunity._id} className="px-12">
                <div
                  className="bg-white shadow-lg flex flex-col"
                  style={{ borderRadius: "40px", height: "100%" }}
                >
                  <img
                    src={opportunity.img}
                    alt={
                      opportunity.name ||
                      opportunity.fundingName ||
                      opportunity.volunteerName
                    }
                    className="w-full h-60 object-cover"
                    style={{
                      borderTopLeftRadius: "40px",
                      borderTopRightRadius: "40px",
                    }}
                  />
                  <div className="p-6 flex flex-col flex-grow">
                    <div className="flex-grow">
                      <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        {opportunity.name ||
                          opportunity.fundingName ||
                          opportunity.volunteerName}
                      </h2>
                      <p className="text-gray-600 mb-4 overflow-hidden overflow-ellipsis">
                        {opportunity.otherInfos[0]}
                      </p>
                    </div>
                    <div className="text-center mt-auto">
                      <a
                        href="https://omniverse.life/contribute"
                        className="block w-full text-center text-white py-2 rounded-md relative group"
                      >
                        <button
                          className="relative inline-block text-lg group mb-6"
                          type="submit"
                        >
                          <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                            <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                            <span className="relative">
                              {opportunity.buttonName}
                            </span>
                          </span>
                          <span
                            className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                            data-rounded="rounded-lg"
                          ></span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p className="text-center text-gray-600">
            No opportunities available at the moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default Funding;
