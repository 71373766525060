import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";
import UpdateShoonyaModal from "../../Modal/UpdateShoonyaModal";

const UpdateShoonya = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-shoonya-videos"
    )
      .then((res) => res.json())
      .then((data) => {
        setVideos(data);
      });
  }, []);

  // add new video
  const handleAddVideo = (event) => {
    event.preventDefault();
    const form = event.target;
    const videoTitle = form.videoTitle.value;
    const videoLink = form.videoLink.value;

    const addVideo = {
      videoTitle,
      videoLink,
    };

    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/post-shoonya-video",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(addVideo),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success(`You have added a video successfully`);

          form.reset("");
        } else {
          alert("Error");
        }
      })
      .catch((err) => alert(err));
  };

  // delete shoonya video

  const handleDeleteVideo = (video) => {
    const agree = window.confirm("Are you sure to delete this video?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-shoonya-video/${video._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This video has been deleted successfully !");
          }
        });
    }
  };
  return (
    <div className="bg-gray-100 pl-3 md:pl-5 lg:pl-10 xl:pl-14 px-7 md:px-10 py-10 ">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center space-y-1 shadow-lg  w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white ">
          <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 mt-3">
            Add a video{" "}
            <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
          </h1>

          <form
            onSubmit={handleAddVideo}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700  w-full"
          >
            <textarea
              name="videoTitle"
              placeholder="Description of video"
              className="w-full h-24 pt-3 px-2 text-lg md:text-xl border bg-white border-gray-200 rounded-md
                           outline-none text-gray-900 "
              required
            ></textarea>

            <input
              name="videoLink"
              placeholder="Link of video"
              className="w-full h-16 px-2 
                          text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
              required
            />

            {/* button */}
            <button class="relative inline-block text-lg group" type="submit">
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Add now</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </button>
          </form>
        </div>
      </div>
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-3">
        All shoonya videos{" "}
      </h1>
      <div className="flex flex-col justify-center items-center">
        <table className="w-full max-h-[100vh] overflow-y-scroll scrollbar-hide">
          <tbody className="py-3">
            {videos.map((video, index) => (
              <tr className="my-2 border-b-2 text-center">
                <td className="text-xl text-gray-900 w-/4 px-2 py-4">
                  {index + 1}
                </td>
                <td
                  title={video?.videoTitle}
                  className="text-xl text-gray-900 hover:text-blue-500 cursor-pointer w-2/4 px-2 py-4  "
                >
                  <a href={video.videoLink} target="blank">
                    {video?.videoTitle.slice(0, 50)}
                  </a>
                </td>
                <td className=" text-xl text-gray-900 w-1/4 px-2 py-4 hover:text-blue-500 cursor-pointer">
                  <>
                    <label htmlFor={`shoonya-${video._id}`}>Update video</label>
                    <UpdateShoonyaModal
                      _id={video._id}
                      videoLink={video.videoLink}
                      videoTitle={video.videoTitle}
                    />
                  </>
                </td>

                <td
                  onClick={() => handleDeleteVideo(video)}
                  className="text-xl text-gray-900 w-1/4 px-2 py-4 hover:text-blue-500 cursor-pointer"
                >
                  Remove
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpdateShoonya;
