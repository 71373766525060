import React from "react";
import ocean from "../assets/ocean.png";
import bracket from "../assets/bracket.png";
import pahar from "../assets/pahar.png";
import right from "../assets/right.png";

const Illusion = () => {
  return (
    <div>
      <div className="relative hidden lg:block -mt-40 3xl:-mt-24 -mb-10">
        <img
          src={ocean}
          className="w-full  lg:h-[1200px] xl:h-[1600px] "
          alt=""
        />
        <img
          src={bracket}
          className="w-16 xl:w-12  lg:h-[180px] xl:h-[215px] 2xl:h-[229px] absolute lg:left-60 lg:top-[455px] xl:left-60 xl:top-[649px] 5xl:left-72 6xl:left-80"
          alt=""
        />
        <img
          src={bracket}
          className="w-16 xl:w-12 lg:h-[190px] xl:h-[210px] 2xl:h-[225px] absolute lg:left-60 lg:top-[640px] 2xl:top-[885px] xl:left-60 xl:top-[871px] 5xl:left-72 6xl:left-80"
          alt=""
        />

        <img
          src={right}
          className="w-52 h-[40px] xl:w-72 xl:h-[50px] absolute lg:left-40 lg:bottom-[300px] xl:left-52 xl:bottom-[407px] 2xl:bottom-[400px]"
          alt=""
        />
        <p className="text-sm xl:text-[12px] 2xl:text-[16px] text-center text-white absolute lg:left-10 lg:top-[520px] xl:left-28 3xl:left-14 xl:top-[740px]">
          {" "}
          What appears
        </p>
        <p className="text-sm xl:text-[12px] 2xl:text-[16px] text-center text-white absolute lg:left-10 lg:top-[680px] xl:left-28 3xl:left-14 xl:top-[940px]">
          What lies bellow
        </p>
        <p className="text-base md:text-sm xl:text-[12px] 2xl:text-[16px] text-center text-white absolute lg:left-10 lg:top-[710px] xl:left-28 3xl:left-14 xl:top-[963px]">
          the surface
        </p>
        <p className="text-base md:text-sm xl:text-[12px] 2xl:text-[16px]  font-bold text-center text-white absolute lg:left-10 lg:bottom-[360px] xl:left-28 3xl:left-14 xl:bottom-[482px]">
          The Root Cause
        </p>
        <p className="text-base md:text-base xl:text-2xl font-semibold text-center text-red-600 absolute lg:left-14 lg:bottom-[335px] xl:left-28 3xl:left-14 xl:bottom-[454px] 2xl:bottom-[453px]">
          Illusion of Separation
        </p>
        {/* row1 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 xl:gap-x-32 2xl:gap-x-14 gap-y-8 lg:grid-cols-3 absolute lg:top-[430px] lg:right-5 xl:top-[620px] xl:right-10  w-[700px]  xl:w-2/3 2xl:pl-20 4xl:pl-24 6xl:pl-32  2xl:w-4/5 6xl:w-10/12  z-10 ">
          <div className="xl:pl-0 2xl:pl-10 3xl:pl-0">
            <p className="text-sm   xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Climate change,
            </p>
            <p className="text-sm   xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Bio-diversity loss{" "}
            </p>
          </div>
          <div>
            <p className="text-sm   xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Wars, Social Inequity
            </p>
            <p className="text-sm   xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Community breakdown
            </p>
          </div>
          <div className="xl:-pl-5 2xl:pl-5 3xl:pl-16 6xl:pl-28">
            <p className="text-sm   xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Anxiety, diseases,
            </p>
            <p className="text-sm   xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              depression{" "}
            </p>
          </div>
        </div>
        {/* row2 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 xl:gap-x-32 gap-y-8 lg:grid-cols-3  absolute xl:top-[861px] right-5 top-[645px] xl:right-14  w-[700px]  xl:w-2/3 2xl:pl-36 3xl:pl-28 4xl:pl-36 6xl:pl-40  2xl:w-4/5 6xl:w-10/12  2xl:top-[875px] z-10">
          <div className="lg:pl-3 xl:pl-0 3xl:pl-4">
            <p className="text-sm  xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              {" "}
              Ineffective Economic
            </p>
            <p className="text-sm  xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              & Educational System
            </p>
          </div>
          <div>
            <p className="text-sm  xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Inappropriate
            </p>
            <p className="text-sm  xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Social Structures
            </p>
          </div>
          <div className="2xl:-pl-1 3xl:pl-9 6xl:pl-11">
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Conflicting
            </p>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Paradigms & Behavior
            </p>
          </div>
        </div>
        {/* row3 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 xl:gap-x-32 gap-y-8 lg:grid-cols-3 absolute top-[710px] right-5 xl:top-[930px]  xl:right-14 2xl:top-[950px]  w-[700px] xl:w-2/3 2xl:pl-32 3xl:pl-28 4xl:pl-32 6xl:pl-40 2xl:w-4/5 6xl:w-10/12  z-10">
          <div className="lg:pl-3 xl:pl-0 2xl:pl-9 3xl:pl-4 4xl:pl-0">
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Lack of reverence
            </p>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              {" "}
              for nature{" "}
            </p>
          </div>
          <div className="2xl:-pl-7 3xl:pl-4">
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Lack of empathy
            </p>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              &
            </p>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              cooperation
            </p>
          </div>
          <div className="xl:pr-2 2xl:pr-8 3xl:pl-14 4xl:pl-16 6xl:pl-20">
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Lack of virtuous
            </p>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              values & Self
            </p>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-normal text-center text-white">
              Realisation
            </p>
          </div>
        </div>
        {/* row4 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 xl:gap-x-32 gap-y-8 lg:grid-cols-3 absolute bottom-[360px] right-5 xl:bottom-[480px] xl:right-10 2xl:bottom-[457px] w-[700px] xl:w-2/3 2xl:pl-28  2xl:w-4/5 z-10 ">
          <div>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-semibold text-center text-white">
              Of Self from Nature
            </p>
          </div>
          <div>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-semibold text-center text-white">
              of Self from Society
            </p>
          </div>
          <div>
            <p className="text-sm xl:text-[12px] 2xl:text-[16px] font-semibold text-center text-white">
              of Self from Higher Self{" "}
            </p>
          </div>
        </div>
        {/* row 5 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 xl:gap-x-20 2xl:gap-x-11 3xl:gap-x-32 6xl:gap-x-40 gap-y-8 lg:grid-cols-3 absolute top-[720px] right-5 lg: xl:right-8 lg:top-[500px] xl:top-[690px] 2xl:top-[680px] 3xl:top-[680px] 4xl:top-[680px]  w-[700px] xl:w-9/12 lg:pl-3 xl:pl-14 2xl:pl-24 4xl:pl-28 6xl:pl-0 2xl:w-4/5 6xl:w-9/12 z-0 ">
          <img
            src={pahar}
            className="w-60 h-[320px] xl:w-[530px] xl:h-[390px] 2xl:w-[375px] 2xl:h-[430px] 4xl:w-[368px]"
            alt=""
          />
          <img
            src={pahar}
            className="w-60 h-[320px] xl:w-[530px] xl:h-[390px] 2xl:w-[375px] 2xl:h-[430px] 4xl:w-[368px]"
            alt=""
          />
          <img
            src={pahar}
            className="w-60 h-[320px] xl:w-[530px] xl:h-[390px] 2xl:w-[375px] 2xl:h-[430px] 4xl:w-[368px]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Illusion;
