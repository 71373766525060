import React, { useEffect, useState } from "react";
import Footer from "../../HomePage/Footer";

const Omniuya = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-omniyua-course/true"
    )
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
        console.log("data", data);
      });
  }, []);

  return (
    <div
      id="omniyua"
      className="bg-white px-5 py-3 md:px-10 md:pt-10 md:pb-3 xl:px-20 xl:pt-10 font-sans"
    >
      <h1 className="text-[25px] md:text-[35px] lg:text-[37px] xl:text-[42px] 2xl:text-[50px] 3xl:text-[47px] 4xl:text-[50px] 5xl:text-[55px] 6xl:text-[65px] text-gray-800 font-normal  text-center lg:text-start md:leading-[57px] lg:leading-[57px] xl:leading-[59px] 2xl:leading-[64px] 3xl:leading-[69px] 4xl:leading-[76px] 6xl:leading-[70px] pb-5 md:pb-10 lg:py-10">
        For Self-Discovery, Self-Realization and Self-Actualization
      </h1>
      {courses.length === 0 ? (
        <div className="flex flex-col justify-center items-start space-y-6 6xl:space-y-12">
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            As humans, we possess seven centers of experience that define our
            humanity. To unlock our full potential and achieve a sense of
            wholeness (omni), each of these centers must awaken and flourish:
            the instinctual and impulsive, the willing and emotional, the
            communicating and intellectual, and finally, the spiritual. These
            seven dimensions of life draw inspiration from the seven centers of
            perception and experience within our bodies, which have been
            historically referred to as the "Chakras." They form the blueprint
            of a bidirectional ladder that encompasses our internal and external
            worlds. Each dimension has the power to completely shift our
            perception, leading to transformations in meaning, happiness,
            values, and attributes. They are self-contained worlds within the
            world—a holistic and integral part of the human experience, known as
            a "holon."
          </p>

          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            In today's world, a significant challenge arises from the fact that
            many individuals have not realized their innate potential or
            connected with their true selves. Most dimensions remain dormant and
            untapped. Academics may become trapped within the intellectual
            realm, the career-oriented may limit themselves to the occupational
            dimension, athletes may focus solely on the physical aspect, artists
            may confine themselves to the realm of creativity, and spiritual
            seekers may limit their exploration to the spiritual dimension. This
            fragmented existence leads to a profound sense of disconnection and
            disharmony within ourselves and our relationship with the world.
          </p>
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]  font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            OmniYua offers a transformative program specifically designed to
            address this issue and facilitate integral self-transformation. It
            goes beyond mere theoretical concepts, inviting participants on an
            immersive journey that explores the depths of their psyche, body,
            environment, emotions, relationships, intellect, and spirituality.
          </p>
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]  font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            By engaging with the instinctual and impulsive dimension,
            individuals learn to harness and align their primal energy with
            their higher purpose. The willing and emotional dimension helps them
            gain insight into their desires, motivations, and emotions, leading
            to a more authentic and fulfilling existence. The communicating and
            intellectual dimension fosters effective self-expression, knowledge
            acquisition, and the cultivation of wisdom. Lastly, the spiritual
            dimension enables individuals to connect with their innermost
            essence and recognize the interconnectedness of all life.
          </p>
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]   font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            The programme provides a structured process of self-inquiry,
            reflection, and practical exercises tailored to each dimension.
            Participants are guided to delve into the depths of their being,
            confront limiting beliefs and patterns, and embrace new perspectives
            and possibilities. As they progress through the program, they not
            only undergo personal transformation but also contribute to the
            evolution of a more harmonious and interconnected world.
          </p>
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]   font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            In a world where many individuals are trapped in limited
            perspectives and disconnected from their innate potential, OmniYua
            offers a path towards integral self-transformation. It invites
            individuals to embark on a profound journey of self-discovery,
            self-realization, and self-actualization. Through this
            transformative process, they awaken dormant dimensions, expand their
            consciousness, and ultimately embody harmonious co-existence with
            themselves, others, and the world at large.
          </p>
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]   font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            OmniYua warmly welcomes individuals from all walks of life to embark
            on this transformative journey of integral self-transformation. It
            acknowledges that each person's path may be unique, but the ultimate
            destination remains the same—an integrated state of being where self
            and world knowledge converge, and sustainable coexistence
            flourishes.
          </p>
          <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]   font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
            OmniYua's overarching vision is to contribute to the evolution of
            consciousness at both the individual and collective levels. By
            awakening and integrating the seven dimensions, it aims to create a
            ripple effect that positively influences not only the participants
            but also their relationships, communities, and ultimately, the
            world. The ultimate goal is to cultivate a society characterized by
            self-awareness, empathy, compassion, and sustainable coexistence.
          </p>
          <a
            href="https://docs.google.com/forms/d/1fKLPo9NUnKQghxsEc63uluh90lRfL5XUkj_6Qdi8uwc/edit?pli=1"
            target="blank"
            className="mt-5"
          >
            <button class="relative inline-block text-lg group" type="submit">
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Join the waitlist</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </button>
          </a>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 xl:gap-10 mt-10">
          {courses.map((course) => (
            <div className="flex flex-col space-y-4 md:space-y-5 lg:space-y-7 items-start">
              <a href={`/participate/omniyua-course/${course.dynamicUrl}`}>
                <img
                  src={course.img}
                  alt="Event"
                  className="h-[400px] w-full lg:w-full  md:h-[400px] 3xl:h-[500px] cursor-pointer"
                />

                <div className="space-y-4 ">
                  <h1 className="font-semibold tracking-wider font-sans  text-[23px] xl:text-3xl 6xl:text-4xl text-gray-800 pt-6">
                    {course.courseName}
                  </h1>
                  <p className="font-normal font-sans text-start text-[20px] xl:text-2xl 6xl:text-5xl text-gray-800 py-2 3xl:leading-10">
                    {course?.otherInfos[0]?.slice(0, 69)}
                  </p>
                  <p className="font-sans text-start text-[20px] xl:text-2xl 6xl:text-5xl text-gray-800">
                    <span className="font-semibold">Starting from : </span>
                    {course?.date}
                  </p>
                </div>
              </a>
              <a href={`/participate/omniyua-course/${course.dynamicUrl}`}>
                <button
                  class="relative inline-block text-lg group"
                  type="submit"
                >
                  <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                    <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                    <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                    <span class="relative">Know more</span>
                  </span>
                  <span
                    class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                    data-rounded="rounded-lg"
                  ></span>
                </button>
              </a>
            </div>
          ))}
        </div>
      )}

      <div className="pt-20">
        <Footer />
      </div>
    </div>
  );
};

export default Omniuya;
