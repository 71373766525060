import React from "react";
import HeroSction from "../HeroSection/HeroSction";
import Need from "./Need";
import CoursesUi from "../CoursesSection/CoursesUi/CoursesUi";
import PublicationUi from "../PublicationSection/PublicationUi/PublicationUi";
import JoinCommunitySectionUi from "../JoinCommunitySection/JoinCommunitySectionUi/JoinCommunitySectionUi";
import Illusion from "./Illusion";
import Newsletter from "./Newsletter";
import SliderUi from "../Slider/SliderUi/SliderUi";
import Project from "../ProjectSection/ProjectUi/Project";
import UniteUi from "../UniteSection/UniteUi/UniteUi";
import ContributeUi from "../StewardSection/ContributeUi/ContributeUi";
import MobileOcean from "./MobileOcean";

const HomePage = () => {
  return (
    <div className="bg-white font-sans 3xl:overflow-x-hidden">
      <HeroSction />
      <SliderUi />
      <Illusion />
      <MobileOcean />
      <Need />
      <CoursesUi />
      <PublicationUi />
      <Project />
      <JoinCommunitySectionUi />
      <ContributeUi />
      <UniteUi />
      <Newsletter />
    </div>
  );
};

export default HomePage;
