import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { IoIosCloseCircle } from "react-icons/io";

const UpdateProjectLink = ({ projectLink, description, projectTitle, _id }) => {
  const [updatedTitle, setUpdatedTitle] = useState(projectTitle);
  const [updatedLink, setUpdatedLink] = useState(projectLink);
  const [updatedDescription, setUpdatedDescription] = useState(description);

  const handleCloseModal = () => {
    const modalToggle = document.getElementById(`project-${_id}`);
    modalToggle.checked = false;
  };

  const handleEditProjectLink = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-project-info/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            updatedTitle,
            updatedLink,
            updatedDescription,
          }),
        }
      );

      if (response.ok) {
        toast.success("Project information updated successfully");
        handleCloseModal();
      } else {
        toast.error("Failed to update project information");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="">
      <input type="checkbox" id={`project-${_id}`} className="modal-toggle" />
      <div
        className="modal"
        style={{ position: "fixed", top: 0, left: 0, zIndex: 2147483647 }}
      >
        <div className="modal-box w-11/12 max-w-3xl bg-white">
          <IoIosCloseCircle
            className="text-2xl text-gray-800 float-right cursor-pointer"
            onClick={handleCloseModal}
          />
          <p className="text-2xl text-gray-800 mb-3 font-semibold">
            {projectTitle}
          </p>
          <form className="space-y-3" onSubmit={handleEditProjectLink}>
            <input
              name="projectTitle"
              type="text"
              value={updatedTitle}
              onChange={(e) => setUpdatedTitle(e.target.value)}
              className="w-full h-16 px-2 
                text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
              required
            />
            <input
              name="projectLink"
              type="text"
              value={updatedLink}
              placeholder={!projectLink ? "Add a link" : ""}
              onChange={(e) => setUpdatedLink(e.target.value)}
              className="w-full h-16 px-2 
                text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
            />
            <textarea
              name="description"
              type="text"
              value={updatedDescription}
              onChange={(e) => setUpdatedDescription(e.target.value)}
              className="w-full h-32 px-2 
                text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
              required
            />
            <button
              type="submit"
              className="mt-3 px-4 py-3 bg-gray-800 text-white text-xl font-semibold rounded-lg cursor-pointer"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateProjectLink;
