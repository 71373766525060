import React, { useEffect, useState } from "react";
import resource from "../../assets/resources.jpg";
import Footer from "../../HomePage/Footer";
import CourseNavbar from "../../Navbar/CourseNavbar";

const Resource = () => {
  //book state
  const [books, setBooks] = useState([]);
  const [visibleBooks, setVisibleBooks] = useState(3);
  const [showAllBooks, setShowAllBooks] = useState(false);

  //video state
  const [videos, setVideos] = useState([]);
  const [visibleVideos, setVisibleVideos] = useState(2);
  const [showAllVideos, setShowAllVideos] = useState(false);

  //get book
  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/all-resource-book"
    )
      .then((res) => res.json())
      .then((data) => {
        setBooks(data);
        console.log("data", data);
      });
  }, []);

  const handleShowMoreBooks = () => {
    if (!showAllBooks) {
      setVisibleBooks(books.length);
    } else {
      setVisibleBooks(3);
    }
    setShowAllBooks((prevShowAllBooks) => !prevShowAllBooks);
  };
  //get video
  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/all-resource-video"
    )
      .then((res) => res.json())
      .then((data) => {
        setVideos(data);
        console.log("data", data);
      });
  }, []);

  const handleShowMoreVideos = () => {
    if (!showAllVideos) {
      setVisibleVideos(videos.length);
    } else {
      setVisibleVideos(2);
    }
    setShowAllVideos((prevShowAllVideos) => !prevShowAllVideos);
  };

  return (
    <div className="bg-white font-sans overflow-hidden">
      <CourseNavbar />
      <div className="flex justify-center items-center">
        <img
          src={resource}
          className=" md:h-[500px] 3xl:h-[600px] 5xl:h-[650px]"
          alt="rsc"
        />
      </div>
      <div className="px-5 md:px-10 lg:px-14 xl:px-16 4xl:px-20 pt-10 xl:pt-14 pb-3">
        <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center  text-gray-800 my-5 md:my-16">
          Books
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-14 3xl:gap-28 ">
          {books.slice(0, visibleBooks).map((resource) => (
            <a
              key={resource.id} // Make sure each element has a unique key
              href={resource?.bookLink}
              target="blank"
              className="flex flex-col justify-center items-center space-y-3"
            >
              <img
                src={resource.bookImg}
                alt="Book"
                className="h-[400px] w-full 3xl:h-[460px] 5xl:h-[550px] 3xl:w-full"
              />
              <h1 className="text-[16px] md:text-2xl lg:text-2xl xl:text-2xl text-center font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                {resource.bookName}
              </h1>
            </a>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center mt-10 mb-5 xl:mt-14 3xl:mt-16">
          {books.length > 3 && (
            <button
              class="relative inline-block text-lg group "
              onClick={handleShowMoreBooks}
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">
                  {showAllBooks ? "Close all" : "More Books"}
                </span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </button>
          )}
        </div>

        <div>
          <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center  text-gray-800 my-12 md:my-16">
            Videos
          </h1>
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2  gap-4 md:gap-10 md:gap-y-2 lg:gap-y-2  3xl:gap-x-14 4xl:gap-16">
              {videos.slice(0, visibleVideos).map((video) => (
                <div className="flex flex-col justify-center items-center space-y-3 lg:space-y-7 mb-7 md:mb-10 ">
                  <iframe
                    src={video.videoLink}
                    title="YouTube video player"
                    frameborder="0"
                    allowFullscreen
                    className="w-full h-[300px] lg:h-[350px] 2xl:h-[370px] 4xl:h-[400px] 6xl:h-[410px]"
                    controls
                  ></iframe>
                  <h1 className="text-[16px] md:text-2xl lg:text-2xl xl:text-2xl text-center font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                    {video.videoTitle}
                  </h1>
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center justify-center  my-4">
              {videos.length > 2 && (
                <button
                  class="relative inline-block text-lg group "
                  onClick={handleShowMoreVideos}
                >
                  <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                    <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                    <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                    <span class="relative">
                      {showAllVideos ? "Close all" : "More Videos"}
                    </span>
                  </span>
                  <span
                    class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                    data-rounded="rounded-lg"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="pt-24 md:pt-32 xl:pt-28">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Resource;
