import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlinePublishedWithChanges, MdUnpublished } from "react-icons/md";
import { TbTrashOff } from "react-icons/tb";
import { Link } from "react-router-dom";

const AdminFundingForm = () => {
  const [fundings, setFundings] = useState([]);
  const [volunterings, setVolunterings] = useState([]);

  const fetchFundings = async () => {
    try {
      const res = await fetch(
        "https://omniverse-server-3222344a8118.herokuapp.com/all-fundings"
      );
      const data = await res.json();
      setFundings(data);
    } catch (error) {
      console.error("Error fetching fundings:", error);
    }
  };

  const fetchVolunterings = async () => {
    try {
      const res = await fetch(
        "https://omniverse-server-3222344a8118.herokuapp.com/all-volunterings"
      );
      const data = await res.json();
      setVolunterings(data);
    } catch (error) {
      console.error("Error fetching volunterings:", error);
    }
  };

  // Fetch data on mount
  useEffect(() => {
    fetchFundings();
    fetchVolunterings();
  }, []);

  const handleAddNewFundingCart = async () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      fundingName: "Funding name",
      otherInfos: ["Add other information Funding here.", ""],
      buttonName: "Fund",
      isPublish: false,
    };
    try {
      const res = await fetch(
        "https://omniverse-server-3222344a8118.herokuapp.com/create-new-funding",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newObject),
        }
      );
      const data = await res.json();
      toast.success("Funding added successfully");
      fetchFundings(); // Refetch fundings after adding a new one
    } catch (error) {
      console.error("Error adding funding:", error);
    }
  };

  const handleAddNewVolunteringCart = async () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      volunteerName: "Voluntering name",
      otherInfos: ["Add other information Funding here.", ""],
      buttonName: "Volunteer",
      isPublish: false,
    };
    try {
      const res = await fetch(
        "https://omniverse-server-3222344a8118.herokuapp.com/create-new-voluntering",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newObject),
        }
      );
      const data = await res.json();
      toast.success("Volunteering added successfully");
      fetchVolunterings(); // Refetch volunterings after adding a new one
    } catch (error) {
      console.error("Error adding volunteering:", error);
    }
  };

  const handleDeleteFundingCart = async (funding) => {
    const agree = window.confirm("Do you want to delete this funding?");
    if (agree) {
      try {
        const res = await fetch(
          `https://omniverse-server-3222344a8118.herokuapp.com/delete-funding/${funding._id}`,
          {
            method: "DELETE",
          }
        );
        const data = await res.json();
        if (data.deletedCount > 0) {
          toast.success("This funding cart has been deleted successfully!");
          fetchFundings(); // Refetch fundings after deleting one
        }
      } catch (error) {
        console.error("Error deleting funding:", error);
      }
    }
  };

  const handleDeleteVolunteringCart = async (voluntering) => {
    const agree = window.confirm("Do you want to delete this volunteering?");
    if (agree) {
      try {
        const res = await fetch(
          `https://omniverse-server-3222344a8118.herokuapp.com/delete-voluntering/${voluntering._id}`,
          {
            method: "DELETE",
          }
        );
        const data = await res.json();
        if (data.deletedCount > 0) {
          toast.success(
            "This volunteering cart has been deleted successfully!"
          );
          fetchVolunterings(); // Refetch volunterings after deleting one
        }
      } catch (error) {
        console.error("Error deleting volunteering:", error);
      }
    }
  };

  const handlePublishFundingCart = async (_id) => {
    const makePublish = true;
    const confirmation = window.confirm(
      "Are you sure you want to add this funding cart to the website?"
    );
    if (!confirmation) return;

    try {
      const res = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/publish-funding/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isPublish: makePublish }),
        }
      );
      if (res.ok) {
        toast.success("Congrats! Your funding is now added to the site.");
        fetchFundings(); // Refetch fundings after publishing one
      } else {
        console.error("Failed to publish funding");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handlePublishVolunteringCart = async (_id) => {
    const makePublish = true;
    const confirmation = window.confirm(
      "Are you sure you want to add this volunteer cart to the website?"
    );
    if (!confirmation) return;

    try {
      const res = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/publish-volunteering/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isPublish: makePublish }),
        }
      );
      if (res.ok) {
        toast.success("Congrats! Your volunteering is now added to the site.");
        fetchVolunterings(); // Refetch volunterings after publishing one
      } else {
        console.error("Failed to publish volunteering");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleUnpublishFundingCart = async (_id) => {
    const makeunPublish = false;
    const confirmation = window.confirm(
      "Are you sure to unpublish this funding cart?"
    );
    if (!confirmation) return;

    try {
      const res = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/unpublish-funding/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isPublish: makeunPublish }),
        }
      );
      if (res.ok) {
        toast.success("The funding has been removed from the website");
        fetchFundings(); // Refetch fundings after unpublishing one
      } else {
        console.error("Failed to unpublish funding");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleUnpublishVolunteringCart = async (_id) => {
    const makeunPublish = false;
    const confirmation = window.confirm(
      "Are you sure to unpublish this volunteering cart?"
    );
    if (!confirmation) return;

    try {
      const res = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/unpublish-volunteering/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isPublish: makeunPublish }),
        }
      );
      if (res.ok) {
        toast.success("The volunteering has been removed from the website");
        fetchVolunterings(); // Refetch volunterings after unpublishing one
      } else {
        console.error("Failed to unpublish volunteering");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="bg-white px-5 py-5 md:px-10 lg:px-16 xl:px-20 3xl:px-28 4xl:px-28 pt-20 h-full overflow-hidden">
      <div className="flex items-center space-x-5 float-right">
        <div
          onClick={handleAddNewFundingCart}
          className="flex justify-between mb-7 items-center text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg cursor-pointer"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add a funding cart</button>
        </div>
      </div>

      {/* Fundings */}
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-10 overflow-hidden">
        Funding
      </h1>
      <table className="w-full">
        <tbody className="">
          {fundings.map((funding, index) => (
            <tr key={funding._id} className="my-2 border-b-2">
              <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                {index + 1}
              </td>
              <td className="text-xl text-gray-900 w-2/12 px-2 py-4">
                {funding.fundingName && funding.fundingName.slice(0, 10)}
              </td>

              <td className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                <Link
                  to={`/AdminofOmnilife/update-funding/all-information/${funding._id}`}
                >
                  Update funding
                </Link>
              </td>

              <td
                onClick={() => handleDeleteFundingCart(funding)}
                title="Remove"
                className="px-3 py-1 my-2 font-semibold cursor-pointer text-center"
              >
                <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                  <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                </div>
              </td>
              {funding.isPublish === false ? (
                <td
                  onClick={() => handlePublishFundingCart(funding._id)}
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              ) : (
                <td
                  onClick={() => handleUnpublishFundingCart(funding._id)}
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center space-x-5 float-right mt-10">
        <div
          onClick={handleAddNewVolunteringCart}
          className="flex justify-between mb-7 items-center text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg cursor-pointer"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add a volunteer cart</button>
        </div>
      </div>

      {/* Volunteer */}
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-10 overflow-hidden">
        Volunteer
      </h1>
      <table className="w-full">
        <tbody className="">
          {volunterings.map((voluntering, index) => (
            <tr key={voluntering._id} className="my-2 border-b-2">
              <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                {index + 1}
              </td>
              <td className="text-xl text-gray-900 w-2/12 px-2 py-4">
                {voluntering.volunteerName &&
                  voluntering.volunteerName.slice(0, 10)}
              </td>

              <td className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                <Link
                  to={`/AdminofOmnilife/update-voluntering/all-information/${voluntering._id}`}
                >
                  Update volunteer
                </Link>
              </td>

              <td
                onClick={() => handleDeleteVolunteringCart(voluntering)}
                title="Remove"
                className="px-3 py-1 my-2 font-semibold cursor-pointer text-center"
              >
                <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                  <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                </div>
              </td>
              {voluntering.isPublish === false ? (
                <td
                  onClick={() => handlePublishVolunteringCart(voluntering._id)}
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              ) : (
                <td
                  onClick={() =>
                    handleUnpublishVolunteringCart(voluntering._id)
                  }
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminFundingForm;
