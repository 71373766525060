import React, { useEffect, useState } from "react";
import Project from "./Project";
import Footer from "../../HomePage/Footer";
import Navbar from "../../Navbar/Navbar";
const ProjectUi = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-projects")
      .then((res) => res.json())
      .then((data) => {
        setProjects(data);
      });
  }, []);
  return (
    <div className="bg-white min-h-screen font-sans">
      <Navbar />

      <div className="px-5 pt-0  pb-2  md:px-10  lg:px-14 xl:px-16 4xl:px-20 lg:max-h-[100vh] lg:overflow-y-auto lg:scrollbar-hide">
        {projects.map((project, index) => (
          <div className="flex justify-between my-9 md:my-16" key={index}>
            {index % 2 === 0 ? (
              <div
                className="flex flex-col-reverse lg:flex-row justify-between items-center"
                style={{ float: "right" }}
              >
                <div className="pt-7 lg:pt-0 lg:pr-10 space-y-4 lg:space-y-6 w-full lg:w-6/12">
                  <p className="text-[25px] md:text-[28px] font-medium  text-gray-800 w-full">
                    {project.projectTitle}
                  </p>
                  <p className="text-[18px] md:text-2xl xl:text-[18px] 6xl:text-[24px] md:text-start font-semibold  text-gray-800 tracking-normal xl:tracking-wide">
                    Description:
                  </p>
                  <p className="lg:hidden   text-[21px] md:text-[22px] font-normal  text-gray-800 w-full ">
                    {project?.description?.slice(0, 400)} ...
                  </p>
                  <p className="hidden xl:block text-[21px] md:text-[22px] font-normal  text-gray-800 w-full ">
                    {project?.description?.slice(0, 380)} ...
                  </p>
                  <p className="hidden lg:block xl:hidden text-[21px] md:text-[22px] font-normal  text-gray-800 w-full">
                    {project?.description?.slice(0, 215)} ...
                  </p>
                  {project.projectLink ? (
                    <a href={project.projectLink} target="blank">
                      <button
                        className="relative inline-block text-lg group mt-6"
                        type="submit"
                      >
                        <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                          <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                          <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                          <span className="relative">Read more</span>
                        </span>
                        <span
                          className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                          data-rounded="rounded-lg"
                        ></span>
                      </button>
                    </a>
                  ) : (
                    <p className="text-[18px] md:text-2xl xl:text-[18px] 6xl:text-[24px] text-start font-semibold  text-gray-800 tracking-normal xl:tracking-wide">
                      Revealing Soon
                    </p>
                  )}
                </div>
                <img
                  src={project.img}
                  alt=""
                  className="w-full lg:w-6/12 h-[350px]  md:h-[400px] xl:h-[450px]"
                />
              </div>
            ) : (
              <div
                className="flex flex-col-reverse lg:flex-row-reverse justify-between items-center"
                style={{ float: "right" }}
              >
                <div className="pt-7 lg:pt-0 lg:pl-10 space-y-4 lg:space-y-6 w-full lg:w-6/12">
                  <p className="text-[25px] md:text-[28px] font-medium  text-gray-800 w-full">
                    {project.projectTitle}
                  </p>
                  <p className="text-[18px] md:text-2xl xl:text-[18px] 6xl:text-[24px] md:text-start font-semibold  text-gray-800 tracking-normal xl:tracking-wide">
                    Description:
                  </p>
                  <p className="lg:hidden   text-[21px] md:text-[22px] font-normal  text-gray-800 w-full ">
                    {project?.description?.slice(0, 400)} ...
                  </p>
                  <p className="hidden xl:block text-[21px] md:text-[22px] font-normal  text-gray-800 w-full ">
                    {project?.description?.slice(0, 380)} ...
                  </p>
                  <p className="hidden lg:block xl:hidden text-[21px] md:text-[22px] font-normal  text-gray-800 w-full">
                    {project?.description?.slice(0, 215)} ...
                  </p>
                  {project.projectLink ? (
                    <a href={project.projectLink} target="blank">
                      <button
                        className="relative inline-block text-lg group mt-5"
                        type="submit"
                      >
                        <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                          <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                          <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                          <span className="relative">Read more</span>
                        </span>
                        <span
                          className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                          data-rounded="rounded-lg"
                        ></span>
                      </button>
                    </a>
                  ) : (
                    <p className="text-[18px] md:text-2xl xl:text-[18px] 6xl:text-[24px] text-start font-semibold  text-gray-800 tracking-normal xl:tracking-wide">
                      Revealing Soon
                    </p>
                  )}
                </div>
                <img
                  src={project.img}
                  alt=""
                  className="w-full lg:w-6/12 h-[350px]  md:h-[400px] xl:h-[450px]"
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="px-5 pt-20 md:pt-24 xl:pt-28 pb-2  md:px-10  lg:px-14 xl:px-16 4xl:px-20">
        <Footer />
      </div>
    </div>
  );
};

export default ProjectUi;
