import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useLoaderData } from "react-router-dom";
import { FiSave } from "react-icons/fi";
import { BsCloudUpload } from "react-icons/bs";
const AdminAbout = () => {
  const data = useLoaderData();
  const {
    _id,
    hindiText,
    moralText,
    name,
    description,
    aboutImage,
    middleText,
    stewardTitle,
    stewardText,
    stewardNames,
    guidingTitle,
    guidingText,
    guidingNames,
    alignedTitle,
    alignedNames,
    sanghaTitle,
    sanghaText,
    sanghaNames,
  } = data;
  //preview image
  const imgBbKey = "f375a7d7cbf6443df6506145faff4414";

  // display image
  const [previewImage, setPreviewImage] = useState();
  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImage(e.target.files[0]);
    }
  };
  const handleUpdateImage = (event) => {
    event.preventDefault();
    const imgField = document.getElementById("img");
    const img = imgField.files[0];

    const formData = new FormData();
    formData.append("image", img);
    const url = `https://api.imgbb.com/1/upload?key=${imgBbKey}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        if (imgData.success) {
          const updatedImage = imgData.data.url;
          fetch(
            `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-image/${_id}`,
            {
              method: "PUT",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({ updatedImage }),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              console.log(data);

              toast.success(`About image updated successfully`);
            })
            .catch((err) => alert(err));
        }
      });
  };
  //update hinditext
  const [editHindiText, setEditHindiText] = useState([]);
  const handleEditHindiText = async () => {
    const element = document.getElementById("hindiText");
    const hindiText = Array.from(element.getElementsByTagName("p"));
    const updatedHindiText = hindiText.map((text) => text.innerText);
    setEditHindiText(updatedHindiText);
    console.log(updatedHindiText);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-hindi-text/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedHindiText }),
        }
      );

      if (response.ok) {
        toast.success("Hindi Texts updated successfully");
      } else {
        console.error("Failed to update hindi text");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update moral text
  const [editMoralText, setEditMoralText] = useState([]);
  const handleEditMoralText = async () => {
    const element = document.getElementById("moralText");
    const moralText = Array.from(element.getElementsByTagName("p"));
    const updatedMoralText = moralText.map((text) => text.innerText);
    setEditMoralText(updatedMoralText);
    console.log(updatedMoralText);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-moral-text/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedMoralText }),
        }
      );

      if (response.ok) {
        toast.success("Moral Texts updated successfully");
      } else {
        console.error("Failed to update moral text");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update name

  const handleEditName = async () => {
    const name = document.getElementById("name");
    const updatedName = name.innerText;
    console.log(updatedName);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-name/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedName }),
        }
      );

      if (response.ok) {
        toast.success("Name updated successfully");
      } else {
        console.error("Failed to update name.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update description
  const [editDescription, setEditDescription] = useState([]);
  const handleEditDescription = async () => {
    const description = document.getElementById("description");
    const descriptionArray = Array.from(description.getElementsByTagName("p"));
    const updatedDescription = descriptionArray.map(
      (paragraph) => paragraph.innerText
    );
    setEditDescription(updatedDescription);
    console.log(updatedDescription);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-description/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedDescription }),
        }
      );

      if (response.ok) {
        toast.success("Description updated successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update middle text
  const handleEditMiddleText = async () => {
    const middleText = document.getElementById("middleText");
    const updatedMiddleText = middleText.innerText;
    console.log(updatedMiddleText);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-middle-text/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedMiddleText }),
        }
      );

      if (response.ok) {
        toast.success("Middle Text updated successfully");
      } else {
        console.error("Failed to Middle Text.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update steward title
  const handleEditStewardTitle = async () => {
    const stewardTitle = document.getElementById("stewardTitle");
    const updatedStewardTitle = stewardTitle.innerText;
    console.log(updatedStewardTitle);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-steward-title/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedStewardTitle }),
        }
      );

      if (response.ok) {
        toast.success("Steward Title updated successfully");
      } else {
        console.error("Failed to Steward Title.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update steward text
  const handleEditStewardText = async () => {
    const stewardText = document.getElementById("stewardText");
    const updatedStewardText = stewardText.innerText;
    console.log(updatedStewardText);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-steward-text/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedStewardText }),
        }
      );

      if (response.ok) {
        toast.success("Steward text updated successfully");
      } else {
        console.error("Failed to Steward text.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update steward names
  const [editStewardNames, setEditStewardNames] = useState([]);
  const handleEditStewardNames = async () => {
    const stewardNames = document.getElementById("stewardNames");
    const stewardNamesArray = Array.from(
      stewardNames.getElementsByTagName("li")
    );
    const updatedStewardNames = stewardNamesArray.map(
      (paragraph) => paragraph.innerText
    );
    setEditStewardNames(updatedStewardNames);
    console.log(updatedStewardNames);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-steward-names/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedStewardNames }),
        }
      );

      if (response.ok) {
        toast.success("Steward Names updated successfully");
      } else {
        console.error("Failed to update steward names");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  //update guiding title
  const handleEditGuidingTitle = async () => {
    const guidingTitle = document.getElementById("guidingTitle");
    const updatedGuidingTitle = guidingTitle.innerText;
    console.log(updatedGuidingTitle);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-guiding-title/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedGuidingTitle }),
        }
      );

      if (response.ok) {
        toast.success("Guiding Title updated successfully");
      } else {
        console.error("Failed to Guiding Title.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update guiding text
  const handleEditGuidingText = async () => {
    const guidingText = document.getElementById("guidingText");
    const updatedGuidingText = guidingText.innerText;
    console.log(updatedGuidingText);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-guiding-text/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedGuidingText }),
        }
      );

      if (response.ok) {
        toast.success("Guiding text updated successfully");
      } else {
        console.error("Failed to Guiding text.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update guiding names
  const [editGuidingNames, setEditGuidingNames] = useState([]);
  const handleEditGuidingNames = async () => {
    const guidingNames = document.getElementById("guidingNames");
    const guidingArray = Array.from(guidingNames.getElementsByTagName("li"));
    const updatedGuidingNames = guidingArray.map(
      (paragraph) => paragraph.innerText
    );
    setEditGuidingNames(updatedGuidingNames);
    console.log(updatedGuidingNames);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-guiding-names/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedGuidingNames }),
        }
      );

      if (response.ok) {
        toast.success("Guiding Names updated successfully");
      } else {
        console.error("Failed to update guiding names");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update aligned title
  const handleEditAlignedTitle = async () => {
    const alignedTitle = document.getElementById("alignedTitle");
    const updatedAlignedTitle = alignedTitle.innerText;
    console.log(updatedAlignedTitle);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-aligned-title/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedAlignedTitle }),
        }
      );

      if (response.ok) {
        toast.success("Aligned Title updated successfully");
      } else {
        console.error("Failed to Aligned Title.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update aligned names
  const [editAlignedNames, setEditAlignedNames] = useState([]);
  const handleEditAlignedNames = async () => {
    const alignedNames = document.getElementById("alignedNames");
    const alignedArray = Array.from(alignedNames.getElementsByTagName("li"));
    const updatedAlignedNames = alignedArray.map(
      (paragraph) => paragraph.innerText
    );
    setEditAlignedNames(updatedAlignedNames);
    console.log(updatedAlignedNames);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-aligned-names/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedAlignedNames }),
        }
      );

      if (response.ok) {
        toast.success("Aligned Names updated successfully");
      } else {
        console.error("Failed to update aligned names");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update sangha title
  const handleEditSanghaTitle = async () => {
    const sanghaTitle = document.getElementById("sanghaTitle");
    const updatedSanghaTitle = sanghaTitle.innerText;
    console.log(updatedSanghaTitle);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-sangha-title/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedSanghaTitle }),
        }
      );

      if (response.ok) {
        toast.success("Sangha Title updated successfully");
      } else {
        console.error("Failed to Sangha Title.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update sangha text
  const handleEditGuiSanghaText = async () => {
    const sanghaText = document.getElementById("sanghaText");
    const updatedSanghaText = sanghaText.innerText;
    console.log(updatedSanghaText);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-sangha-text/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedSanghaText }),
        }
      );

      if (response.ok) {
        toast.success("Sangha text updated successfully");
      } else {
        console.error("Failed to Sangha text.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //update guiding names
  const [editSanghaNames, setEditSanghaNames] = useState([]);
  const handleEditSanghaNames = async () => {
    const sanghaNames = document.getElementById("sanghaNames");
    const sanghaArray = Array.from(sanghaNames.getElementsByTagName("li"));
    const updatedSanghaNames = sanghaArray.map(
      (paragraph) => paragraph.innerText
    );
    setEditGuidingNames(updatedSanghaNames);
    console.log(updatedSanghaNames);

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/updated-about-sangha-names/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ updatedSanghaNames }),
        }
      );

      if (response.ok) {
        toast.success("Sangha Names updated successfully");
      } else {
        console.error("Failed to update  Sangha names");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <div>
      <div className="bg-white">
        <div className="back-ground bg-center bg-cover min-h-screen  bg-no-repeat w-full pb-0 lg:pb-3 xl:pb-5 6xl:pb-7 md:mb-5">
          <div className="px-5 py-5 md:px-10 lg:px-10 xl:px-20 3xl:px-16 4xl:px-28  md:pb-0 md:pt-20  flex flex-col  space-y-8 font-sans">
            <div className="flex flex-col-reverse lg:flex-row justify-between items-center">
              <div className="w-full lg:w-7/12 space-y-1 md:space-y-5 lg:space-y-3 xl:space-y-5 4xl:space-y-6 6xl:space-y-7">
                {/* hindiText */}
                <div
                  id="hindiText"
                  className="outline-none space-y-3"
                  contentEditable
                  suppressContentEditableWarning={true}
                >
                  {hindiText.map((hindi) => (
                    <p className="text-sm md:text-[26px] lg:text-[19px] xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start">
                      {hindi}
                    </p>
                  ))}
                </div>
                <button
                  onClick={handleEditHindiText}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
                <div className="space-y-1 md:space-y-5 lg:space-y-3 xl:space-y-5 4xl:space-y-6 6xl:space-y-7 md:pt-5 lg:pt-5">
                  {/* moralText */}
                  <div
                    id="moralText"
                    className="outline-none space-y-3"
                    contentEditable
                    suppressContentEditableWarning={true}
                  >
                    {moralText.map((moral) => (
                      <p className="text-sm md:text-[26px] lg:text-[19px] xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start">
                        {moral}
                      </p>
                    ))}
                  </div>
                  <button
                    onClick={handleEditMoralText}
                    className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                  >
                    Save changes
                  </button>

                  {/* name */}

                  <p
                    contentEditable
                    suppressContentEditableWarning={true}
                    id="name"
                    className="outline-none text-sm md:text-[26px] lg:text-[19px] pt-2 6xl:pt-5 5xl:pt-7 xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start"
                  >
                    {name}
                  </p>
                  <button
                    onClick={handleEditName}
                    className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                  >
                    Save changes
                  </button>
                </div>
              </div>
              {/* <div className="w-full md:w-10/12 lg:w-5/12 xl:w-6/12 6xl:w-7/12 mb-5 lg:mb-0">
                <img src={aboutImage} className="w-full " alt="" />
              </div> */}
              <form className="w-full md:w-10/12 lg:w-5/12 xl:w-6/12 6xl:w-7/12 mb-5 lg:mb-0">
                {previewImage ? (
                  <button type="submit" className="float-right mb-2">
                    <div className="bg-blue-500 p-2 rounded-lg ">
                      <FiSave
                        onClick={handleUpdateImage}
                        className="text-white text-2xl"
                      />
                    </div>
                  </button>
                ) : (
                  <label
                    required
                    htmlFor="img"
                    className=" float-right mb-2 cursor-pointer"
                  >
                    <div className="w-10">
                      <div className="bg-blue-500 rounded-lg p-2">
                        <BsCloudUpload className="text-white text-2xl" />
                      </div>
                    </div>
                  </label>
                )}
                <input
                  className="px-3 py-2 rounded-lg shadow-sm border border-none w-full focus:outline-none bg-white text-gray-900 hidden"
                  type="file"
                  name="img"
                  id="img"
                  onChange={check}
                  accept="image/*"
                  required
                />

                <div onChange={previewImage} className="">
                  {previewImage ? (
                    <img
                      className=""
                      src={URL.createObjectURL(previewImage)}
                      alt=""
                    />
                  ) : (
                    <img className="" src={aboutImage} alt="About" />
                  )}
                </div>
              </form>
            </div>
            <div className="flex flex-col justify-center items-start space-y-6">
              <div
                id="description"
                contentEditable
                suppressContentEditableWarning={true}
                className="outline-none flex flex-col justify-center items-start space-y-6"
              >
                {description.map((des) => (
                  <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                    {des}
                  </p>
                ))}
              </div>
              <button
                onClick={handleEditDescription}
                className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
              >
                Save changes
              </button>
              <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                With love & wishes for omni well-being,
                <br></br>
                Omniverse Life
              </p>
            </div>
            <h3
              id="middleText"
              contentEditable
              suppressContentEditableWarning={true}
              className="outline-none text-xl md:text-[31px] lg:text-[40px] font-normal text-gray-800 leading-8 md:leading-[50px] text-center lg:text-start py-1 md:py-5"
            >
              {middleText}
            </h3>
            <button
              onClick={handleEditMiddleText}
              className="w-60 px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
            >
              Save changes
            </button>
            {/* Stewards of the Initiative 1 */}
            <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-10">
              <div className="w-full md:w-3/5 lg:w-2/4 3xl:w-6/12 md:pr-16 3xl:pr-10 4xl:pr-14">
                <h1
                  id="stewardTitle"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none font-semibold md:font-normal text-xl md:text-[35px] lg:text-[43px] text-gray-800 leading-[48px]"
                >
                  {stewardTitle}
                </h1>
                <button
                  onClick={handleEditStewardTitle}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6  mb-5 md:mb-8 lg:mb-10 xl:mb-14"
                >
                  Save changes
                </button>
                <p
                  id="stewardText"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px] pb-7 md:pb-10 lg:pb-14"
                >
                  {stewardText}
                </p>
                <button
                  onClick={handleEditStewardText}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>

              {/*steward names */}
              <div className="w-full md:w-2/5 lg:w-2/5 2xl:w-5/12 3xl:w-6/12 space-y-2 6xl:space-y-4  border-b-2 pb-5 md:pb-9 border-gray-400 ">
                <ul
                  id="stewardNames"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="pl-5"
                >
                  {stewardNames.map((steward) => (
                    <li className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                      {steward}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleEditStewardNames}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
            </div>
            {/* Guiding Lights 2 */}
            <div className="flex flex-col md:flex-row justify-between pt-5 md:pt-10">
              <div className="w-full md:w-3/5 lg:w-2/4 3xl:w-6/12 3xl:pr-10 4xl:pr-14">
                <h1
                  id="guidingTitle"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none text-xl md:text-[35px] lg:text-[43px] font-semibold md:font-normal text-gray-800 leading-[48px]"
                >
                  {guidingTitle}
                </h1>
                <button
                  onClick={handleEditGuidingTitle}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6 mb-5 md:mb-8 lg:mb-10 xl:mb-14"
                >
                  Save changes
                </button>
                <p
                  id="guidingText"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal pb-5 md:pb-0 text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
                >
                  {guidingText}
                </p>
                <button
                  onClick={handleEditGuidingText}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
              {/* Guiding names */}
              <div className="w-full md:w-2/5 lg:w-2/5 2xl:w-5/12 3xl:w-6/12 space-y-2 6xl:space-y-4  border-b-2 pb-5 md:pb-9 border-gray-400 ">
                <ul
                  id="guidingNames"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="pl-5"
                >
                  {guidingNames.map((guideName) => (
                    <li className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                      {guideName}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleEditGuidingNames}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
            </div>
            {/*  Aligned  organizations 3 */}
            <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-10">
              <div className="w-full md:w-2/5 lg:w-2/5 xl:w-2/4 3xl:w-6/12 md:mr-0 lg:mr-52 2xl:mr-56 3xl:mr-5 ">
                <h1
                  id="alignedTitle"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none text-xl md:text-[35px] lg:text-[43px] font-semibold md:font-normal text-gray-800 leading-[48px] 4xl:pr-10"
                >
                  {alignedTitle}
                </h1>
                <button
                  onClick={handleEditAlignedTitle}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
              {/* aligned names */}
              <div className="w-full md:w-2/5 lg:w-2/5 2xl:w-5/12 3xl:w-6/12 space-y-2 6xl:space-y-4  border-b-2 pb-5 md:pb-9 border-gray-400 ">
                <ul
                  id="alignedNames"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="pl-5"
                >
                  {alignedNames.map((align) => (
                    <li className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                      {align}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleEditAlignedNames}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
            </div>

            {/* Sangha  4 */}
            <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-10">
              <div className="w-full md:w-2/5 lg:w-5/12 xl:w-2/4 3xl:w-6/12 lg:mr-52 2xl:mr-56 3xl:mr-5">
                <h1
                  id="sanghaTitle"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none text-xl md:text-[35px] lg:text-[43px] font-semibold md:font-normal text-gray-800 leading-[48px]"
                >
                  {sanghaTitle}
                </h1>
                <button
                  onClick={handleEditSanghaTitle}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6 mb-5 md:mb-8 lg:mb-10 xl:mb-14"
                >
                  Save changes
                </button>
                <p
                  id="sanghaText"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="outline-none text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 pb-5 md:pb-0 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px] 4xl:pr-5 5xl:pr-0"
                >
                  {sanghaText}
                </p>
                <button
                  onClick={handleEditGuiSanghaText}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
              {/*sangha names */}
              <div className="w-full md:w-2/5 lg:w-2/5 2xl:w-5/12 3xl:w-6/12 space-y-2 6xl:space-y-4  border-b-2 pb-5 md:pb-9 border-gray-400 ">
                <ul
                  id="sanghaNames"
                  contentEditable
                  suppressContentEditableWarning={true}
                  className="pl-5"
                >
                  {sanghaNames.map((sangha) => (
                    <li className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                      {sangha}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleEditSanghaNames}
                  className="px-5 py-2 text-lg font-semibold bg-blue-600 text-white hover:bg-blue-400 border rounded-lg shadow-lg my-6"
                >
                  Save changes
                </button>
              </div>
            </div>
            <div className="pt-10 lg:pt-16"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAbout;
