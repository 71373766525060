import React from "react";
import { Link } from "react-router-dom";

const AdminHomePage = () => {
  return (
    <div>
      <nav
        aria-label="Tabs"
        className=" flex flex-col border-gray-100 text-sm font-medium bg-white  justify-center space-y-7 pt-8 "
      >
        <Link
          to="/AdminofOmnilife/update-homepage"
          className="text-2xl font-medium text-gray-800"
        >
          Update HeroSection
        </Link>
        <Link
          to="/AdminofOmnilife/update-homepage/steward-text"
          className="text-2xl font-medium text-gray-800"
        >
          Update steward text
        </Link>

        <Link
          to="/AdminofOmnilife/update-homepage/contribute-text"
          className="text-2xl font-medium text-gray-800"
        >
          Update contribute text
        </Link>
        <Link
          to="/AdminofOmnilife/update-homepage/unite-text"
          className="text-2xl font-medium text-gray-800"
        >
          Update unite text
        </Link>
      </nav>
    </div>
  );
};

export default AdminHomePage;
