// import React, { useContext, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import logo from "../assets/logo1.png";
// import { AuthContext } from "../context/UserContext";
// const AdminNavbar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const { logOut } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const handleLogOut = () => {
//     logOut().then(() => {
//       navigate("/");
//     });
//   };
//   return (
//     <div class="bg-white  text-gray-900 z-50">
//       <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-14  lg:px-12 xl:px-0 lg:py-[20px]">
//         <div class="relative flex items-center justify-between ">
//           <a href="/">
//             <img src={logo} className="h-14 w-52" alt="logo" />
//           </a>
//           <div>
//             <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />

//             <label
//               for="my-drawer-4"
//               onClick={() => setIsMenuOpen(true)}
//               class="relative inline-block text-lg group"
//               type="submit"
//             >
//               <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white cursor-pointer">
//                 <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
//                 <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
//                 <span class="relative">Drawer</span>
//               </span>
//               <span
//                 class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
//                 data-rounded="rounded-lg"
//               ></span>
//             </label>
//             {isMenuOpen && (
//               <div class="drawer-side absolute right-0 lg:right-0">
//                 <label for="my-drawer-4" class="drawer-overlay"></label>
//                 <ul class="text-end space-y-5 p-4 w-full md:w-80 h-full bg-white ">
//                   <li
//                     className="font-medium  text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/update-homepage">
//                       Update Homepage
//                     </Link>
//                   </li>
//                   <li
//                     className="font-medium text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/update-about">Update aBOUT</Link>
//                   </li>
//                   <li
//                     className="font-medium text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/update-course">
//                       Add / update course
//                     </Link>
//                   </li>

//                   <li
//                     className="font-medium  text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife">Add /update articles</Link>
//                   </li>
//                   <li
//                     className="font-medium  text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/update-resource">
//                       Add /update resource
//                     </Link>
//                   </li>

//                   <li
//                     className="font-medium text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/post-project">
//                       Add / UPDATE project
//                     </Link>
//                   </li>
//                   <li
//                     className="font-medium text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/update-shoonya">
//                       update shoonya
//                     </Link>
//                   </li>
//                   <li
//                     className="font-medium text-xl uppercase tracking-wide text-gray-800 "
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setIsMenuOpen(false);
//                     }}
//                   >
//                     <Link to="/AdminofOmnilife/update-pilot-locations">
//                       OmniVillage Pilot Locations
//                     </Link>
//                   </li>
//                   <li>
//                     <button
//                       onClick={handleLogOut}
//                       type="button"
//                       class="font-medium text-xl uppercase py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
//                     >
//                       Log out
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminNavbar;
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo1.png";
import { AuthContext } from "../context/UserContext";
const AdminNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogOut = () => {
    logOut().then(() => {
      navigate("/");
    });
  };
  return (
    <div class="bg-white  text-gray-900 z-50">
      <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-14  lg:px-12 xl:px-0 lg:py-[20px]">
        <div class="relative flex items-center justify-between ">
          <a href="/">
            <img src={logo} className="h-14 w-52" alt="logo" />
          </a>
          <div>
            <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />

            <label
              for="my-drawer-4"
              onClick={() => setIsMenuOpen(true)}
              class="relative inline-block text-lg group"
              type="submit"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white cursor-pointer">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Drawer</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </label>
            {isMenuOpen && (
              <div class="drawer-side absolute right-0 lg:right-0">
                <label for="my-drawer-4" class="drawer-overlay"></label>
                <ul class="text-end space-y-5 p-4 w-full md:w-80 h-full bg-white ">
                  <li
                    className="font-medium  text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-homepage">
                      Update Homepage
                    </Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-about">Update aBOUT</Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-course">
                      Add / update course
                    </Link>
                  </li>

                  <li
                    className="font-medium  text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife">Add /update articles</Link>
                  </li>
                  <li
                    className="font-medium  text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-resource">
                      Add /update resource
                    </Link>
                  </li>

                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/post-project">
                      Add / UPDATE project
                    </Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-shoonya">
                      update shoonya
                    </Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-pilot-locations">
                      OmniVillage Pilot Locations
                    </Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-funding">
                      Omni Village Funding & Vlounteering
                    </Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-media">
                      Add/Update Omnivillage Media
                    </Link>
                  </li>
                  <li
                    className="font-medium text-xl uppercase tracking-wide text-gray-800 "
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMenuOpen(false);
                    }}
                  >
                    <Link to="/AdminofOmnilife/update-network">
                      Omni Village Network
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleLogOut}
                      type="button"
                      class="font-medium text-xl uppercase py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
