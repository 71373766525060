import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";
import AdminAllBook from "./AdminAllBook";
import AdminAllVideo from "./AdminAllVideo";

const AdminResourceForm = () => {
  const imgBbKey = "f375a7d7cbf6443df6506145faff4414";

  // display image
  const [previewImage, setPreviewImage] = useState();
  const check = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImage(e.target.files[0]);
    }
  };
  // add book
  const handleAddBook = (event) => {
    event.preventDefault();
    const form = event.target;
    const bookName = form.bookName.value;
    const bookLink = form.bookLink.value;
    const img = form.img.files[0];

    const formData = new FormData();
    formData.append("image", img);
    const url = `https://api.imgbb.com/1/upload?key=${imgBbKey}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        if (imgData.success) {
          const addBook = {
            bookName,
            bookImg: imgData.data.url,
            bookLink,
          };
          console.log(addBook);

          fetch(
            "https://omniverse-server-3222344a8118.herokuapp.com/post-resource-book",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(addBook),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                toast.success(`You have added a book successfully`);
                setPreviewImage(null);
                form.reset("");
              } else {
                alert("Error");
              }
            })
            .catch((err) => alert(err));
        }
      });
  };
  // add video
  const handleAddVideo = (event) => {
    event.preventDefault();
    const form = event.target;
    const videoTitle = form.videoTitle.value;
    const videoLink = form.videoLink.value;

    const addVideo = {
      videoTitle,
      videoLink,
    };
    console.log(addVideo);

    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/post-resource-video",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(addVideo),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success(`You have added a video successfully`);
          setPreviewImage(null);
          form.reset("");
        } else {
          alert("Error");
        }
      })
      .catch((err) => alert(err));
  };

  return (
    <div className="bg-white ">
      <div className="bg-gray-100">
        <div className="flex justify-center items-center  pt-16 pb-9 min-h-screen px-3  ">
          <div className="flex flex-col items-center space-y-1 shadow-lg  w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white ">
            <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 mt-3">
              Add a book{" "}
              <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
            </h1>

            <div
              onChange={previewImage}
              className="hover:scale-110 duration-300"
            >
              {previewImage ? (
                <img
                  className="h-60 w-60"
                  src={URL.createObjectURL(previewImage)}
                  alt=""
                />
              ) : (
                <img
                  className="h-60 w-60 mb-5"
                  src="https://img.freepik.com/free-vector/happy-hindi-divas-celebration-decorative-background-with-feather-design_1055-15226.jpg?size=626&ext=jpg&ga=GA1.2.1540219272.1675657721&semt=sph"
                  alt=""
                />
              )}
            </div>

            <form
              onSubmit={handleAddBook}
              className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700  w-full"
            >
              <label
                required
                htmlFor="addBook"
                className="px-4 py-3 bg-gray-800 text-white text-xl font-semibold rounded-lg cursor-pointer"
              >
                Upload an image
              </label>
              <input
                className=" px-3 py-2 rounded-lg shadow-sm border  border-none w-full 
                            focus:outline-none  bg-white text-gray-900 hidden"
                type="file"
                name="img"
                id="addBook"
                onChange={check}
                accept="image/*"
                required
              />

              <textarea
                name="bookName"
                placeholder="Name of book"
                className="w-full h-20 pt-3 px-2 text-lg md:text-xl border bg-white border-gray-200 rounded-md
                         outline-none text-gray-900 "
                required
              ></textarea>

              <input
                name="bookLink"
                placeholder="Link of book"
                className="w-full h-16 px-2 
                        text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
                required
              />

              {/* button */}
              <button class="relative inline-block text-lg group" type="submit">
                <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                  <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                  <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                  <span class="relative">Add now</span>
                </span>
                <span
                  class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                  data-rounded="rounded-lg"
                ></span>
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* video form */}
      <div className="bg-gray-100">
        <div className="flex justify-center items-center  px-3  ">
          <div className="flex flex-col items-center space-y-1 shadow-lg  w-[800px] px-3 md:p-10 md:space-y-3 border-2 rounded-md bg-white ">
            <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2 mt-3">
              Add a video{" "}
              <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
            </h1>

            <form
              onSubmit={handleAddVideo}
              className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700  w-full"
            >
              <textarea
                name="videoTitle"
                placeholder="Title of video"
                className="w-full h-20 pt-3 px-2 text-lg md:text-xl border bg-white border-gray-200 rounded-md
                         outline-none text-gray-900 "
                required
              ></textarea>

              <input
                name="videoLink"
                placeholder="Link of video"
                className="w-full h-16 px-2 
                        text-lg md:text-xl border border-gray-200 rounded-md outline-none text-gray-900 bg-white"
                required
              />

              {/* button */}
              <button class="relative inline-block text-lg group" type="submit">
                <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                  <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                  <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                  <span class="relative">Add now</span>
                </span>
                <span
                  class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                  data-rounded="rounded-lg"
                ></span>
              </button>
            </form>
          </div>
        </div>
        <AdminAllBook />
        <AdminAllVideo />
      </div>
    </div>
  );
};

export default AdminResourceForm;
