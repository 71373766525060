import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const AdminAllImages = () => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/all-resource-book"
    )
      .then((res) => res.json())
      .then((data) => {
        setBooks(data);
      });
  }, []);

  // delete book  delete-resource-book
  const handleDeleteBook = (book) => {
    const agree = window.confirm("Are you ready to delete this book?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-resource-book/${book._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This book has been deleted successfully !");
          }
        });
    }
  };
  return (
    <div className="bg-gray-100 pl-3 md:pl-5 lg:pl-10 xl:pl-14 px-7 md:px-10 py-10 ">
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-2 border-b w-full pb-2 mt-3">
        All books{" "}
      </h1>
      <div className="flex flex-col justify-center items-center">
        <table className="w-full max-h-[100vh] overflow-y-scroll scrollbar-hide">
          <tbody className="py-3">
            {books.map((book, index) => (
              <tr className="my-2 border-b-2 text-center">
                <td className="text-xl text-gray-900 w-/4 px-2 py-4">
                  {index + 1}
                </td>
                <td className="text-xl text-gray-900 hover:text-blue-500 cursor-pointer w-2/4 px-2 py-4  ">
                  <a href={book.bookLink} target="blank">
                    {book?.bookName}
                  </a>
                </td>

                <td
                  className="text-xl text-gray-900 w-1/4 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  onClick={() => handleDeleteBook(book)}
                >
                  Remove
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminAllImages;
