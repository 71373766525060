import React, { useState } from "react";
import "./Nom.css";
import NominationForm from "./NominationForm";

const NominatePage = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleFormSubmit = (formData) => {
    const { Name, phoneNumber, village, email } = formData;
    const message = `Name: ${Name}\nPhone Number: ${phoneNumber}\nVillage: ${village}\nEmail: ${email}`;

    const mailtoLink = `mailto:snehapoddar11@gmail.com?subject=Nomination Form Submission&body=${encodeURIComponent(
      message
    )}`;

    // Open mail client
    window.location.href = mailtoLink;

    // Show notification
    const isEmailSent = window.confirm(
      "Your nomination has been submitted successfully. Thank you!"
    );
    if (!isEmailSent) {
      // Handle case where email is not sent
    }
  };

  return (
    <article content="Contribute" className="bg-white font-sans">
      <div className="flex flex-col justify-center items-center p-11 lg:p-17 3xl:p-21 relative mt-16 mb-16">
        <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-normal text-center text-gray-800 mb-5 md:mb-9 2xl:mb-20">
          Nominate A Village
        </h1>

        <div className="flex justify-center">
          <div className="lg:ml-8 lg:mr-10">
            <div className="font-light text-gray-800 text-left text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px]  5xl:text-[23px] 6xl:text-[26px]">
            Empower rural transformation! Nominate a village to join our Smart Omni Villages initiative, bringing sustainable development, innovative solutions, and enhanced quality of life to communities. Let's create a future where every village thrives. Your nomination can make a difference!
            </div>
          </div>
        </div>
        <button
          className="relative inline-block text-lg group mt-10"
          onClick={toggleForm}
        >
          <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span className="absolute left-0 w-full h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span className="relative">Nominate</span>
          </span>
          <span
            className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
            data-rounded="rounded-lg"
          ></span>
        </button>
        {showForm && (
          <NominationForm onClose={toggleForm} onSubmit={handleFormSubmit} />
        )}
      </div>
    </article>
  );
};

export default NominatePage;
