import React, { useState, useEffect, useRef } from "react";

const NetworkExpertise = () => {
  const [networks, setNetworks] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [showExpertiseDropdown, setShowExpertiseDropdown] = useState(false);
  const networkRef = useRef(null);
  const expertiseRef = useRef(null);

  useEffect(() => {
    const isPublish = true;
    fetch(`https://omniverse-server-3222344a8118.herokuapp.com/get-network/${isPublish}`)
      .then((res) => res.json())
      .then((data) => {
        setNetworks(data);
      });

    fetch(`https://omniverse-server-3222344a8118.herokuapp.com/get-expertise/${isPublish}`)
      .then((res) => res.json())
      .then((data) => {
        setExpertise(data);
      });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSectionClick = (section) => {
    if (selectedSection === section) {
      if (section === "network") {
        setShowNetworkDropdown(!showNetworkDropdown);
      } else if (section === "expertise") {
        setShowExpertiseDropdown(!showExpertiseDropdown);
      }
    } else {
      setSelectedSection(section);
      setShowNetworkDropdown(section === "network");
      setShowExpertiseDropdown(section === "expertise");
    }
  };

  return (
    <div className="mt-24 px-5 md:px-10 lg:px-20 4xl:px-28 mb-6">
      <div className="text-center">
        <h1 className="p-10 text-3xl md:text-[45px] 2xl:text-5xl font-normal text-center text-gray-800 mb-5gi md:mb-6 2xl:mb-16">
          Network and Expertise
        </h1>
      </div>
      {/* Network */}
      <div className="flex justify-between items-center">
        <h2
          className={`font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 cursor-pointer mt-4 mb-2`}
          onClick={() => handleSectionClick("network")}
        >
          Network
        </h2>
        <h2
          className={`font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 cursor-pointer mt-4 mb-2`}
          onClick={() => handleSectionClick("network")}
        >
          {showNetworkDropdown ? "^" : "⌄"}
        </h2>
      </div>
      <div
        ref={networkRef}
        style={{
          maxHeight: showNetworkDropdown
            ? `${networkRef.current.scrollHeight}px`
            : "0",
        }}
        className={`border-t border-black transition-max-height duration-300 ease-in-out overflow-hidden`}
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10 md:gap-4 md:gap-y-20 mt-2 p-4 border-b border-black">
          {networks.map((network) => (
            <div key={network._id} className="flex flex-col items-center">
              {!isMobile && (
                <img
                  src={network.img}
                  alt={network.networkName}
                  className="mx-auto mb-2 w-48 h-48 rounded-full object-cover"
                />
              )}
              <p className="text-center text-base lg:text-lg xl:text-xl 6xl:text-2xl font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-10 6xl:leading-12">
                {network.networkName}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Expertise */}
      <div className="flex justify-between items-center">
        <h2
          className={`font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 cursor-pointer mt-4 mb-2`}
          onClick={() => handleSectionClick("expertise")}
        >
          Expertise
        </h2>
        <h2
          className={`font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 cursor-pointer mt-4 mb-2`}
          onClick={() => handleSectionClick("expertise")}
        >
          {showExpertiseDropdown ? "^" : "⌄"}
        </h2>
      </div>
      <div
        ref={expertiseRef}
        style={{
          maxHeight: showExpertiseDropdown
            ? `${expertiseRef.current.scrollHeight}px`
            : "0",
        }}
        className={`border-t border-black transition-max-height duration-300 ease-in-out overflow-hidden`}
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10 md:gap-4  md:gap-y-20  mt-2 p-4 border-b border-black">
          {expertise.map((expertise) => (
            <div key={expertise._id} className="flex flex-col items-center">
              {!isMobile && (
                <img
                  src={expertise.img}
                  alt={expertise.expertiseName}
                  className="mx-auto mb-2 w-48 h-48 rounded-full object-cover"
                />
              )}
              <p className="text-center text-base lg:text-lg xl:text-xl 6xl:text-2xl font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-10 6xl:leading-12">
                {expertise.expertiseName}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NetworkExpertise;
