import React from "react";

import OmniVillageHeroSection from "../HeroSection/OmniVillageHeroSection";

import PilotLocation from "./PilotLocations/PilotLocation";
import Funding from "./Funding&Vlounteering/Funding";
import Media from "./Media/Media";

import NominatePage from "./Nominate";
import NetworkExpertise from "./Network&Expertise/NetworkExpertise";
import Footer from "./Footer";

const OmniVillage = () => {
  return (
    <div className="bg-white font-sans ">
      <div id="smartOmni"></div>
      <div >
        <OmniVillageHeroSection />
      </div>
      <div id="PL" ></div>
      
      <div>
        <PilotLocation />
      </div>
      <div id="F&V" ></div>


      <div >
        <Funding />
      </div>

      <div id="media"></div>
      <div>
        <Media />
      </div>

      <div id="nominate"></div>
      <div>
        <NominatePage />
      </div>

      <div id="network">
        
      </div>
      <div>
        <NetworkExpertise />
      </div>
      <Footer />
    </div>
  );
};

export default OmniVillage;
