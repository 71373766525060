/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import lotus from "../../assets/lotus11.png";

const AllCourses = () => {
  const [activeTab, setActiveTab] = useState('omniyua');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const isTabActive = (tabId) => activeTab === tabId;

  return (
    <div className="px-5 py-5 md:px-10 lg:px-10 xl:px-20 4xl:px-28  md:pb-0 md:pt-20  flex flex-col  space-y-8 font-sans bg-white">
    <div className="flex flex-col-reverse lg:flex-row justify-between items-center pb-0 md:pb-5 lg:pb-0 ">
      <div className="w-full  lg:w-7/12 5xl:w-6/12 6xl:w-7/12  space-y-1 md:space-y-5 lg:space-y-3 xl:space-y-5 4xl:space-y-6 6xl:space-y-7">
        <p className="text-[20px] md:text-[26px] lg:text-[23px] xl:text-[25px] 3xl:text-[27px] 4xl:text-[29px] 5xl:text-[31px] 6xl:text-[39px] text-gray-800 font-normal  text-center lg:text-start leading-20 md:leading-[42px] lg:leading-[42px] xl:leading-[50px] 2xl:leading-[54px] 3xl:leading-[60px] 6xl:leading-[70px] lg:pr-10 -mt-1 md:-mt-4 lg:-mt-0">
          Experiences conscientiously designed to transform the way we think,
          feel and do arriving at self & world knowledge to eventually evolve
          towards harmonious consciousness and co-existence.
        </p>
      </div>
      <div className="w-full  md:w-10/12 lg:w-5/12 5xl:w-6/12 6xl:w-5/12 mb-5 lg:pb-10 pr-0 md:pr-0">
        <img src={lotus} className="w-full" alt="" />
      </div>
    </div>
      <nav
        aria-label="Tabs"
        className="flex flex-col md:flex-row border-b-4 border-gray-100 text-sm font-medium bg-white justify-center space-y-10 md:space-y-0 space-x-0 md:space-x-14 lg:space-x-12 xl:space-x-14 2xl:space-x-20 pt-8 items-center lg:items-start lg:justify-start"
      >
        <NavLink
          exact
          to="/participate"
          className={`text-[32px] md:text-[33px] lg:text-[28px] xl:text-[32px] 2xl:text-[36px] 4xl:text-[39px] 5xl:text-[43px] 6xl:text-[40px] pb-6 font-medium ${
            isTabActive("omniuya") ? "text-gray-800 font-semibold border-b-4 border-gray-800" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("omniuya")}
        >
          Omniyua
        </NavLink>

        <NavLink
          exact
          to="/participate/shoonya"
          className={`text-[32px] md:text-[33px] lg:text-[28px] xl:text-[32px] 2xl:text-[36px] 4xl:text-[39px] 5xl:text-[43px] 6xl:text-[40px] pb-6 font-medium ${
            isTabActive("shoonya") ? "text-gray-800 font-semibold border-b-4 border-gray-800" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("shoonya")}
        >
          Shoonya
        </NavLink>

        <NavLink
          exact
          to="/participate/omni-life"
          className={`text-[32px] md:text-[33px] lg:text-[28px] xl:text-[32px] 2xl:text-[36px] 4xl:text-[39px] 5xl:text-[43px] 6xl:text-[40px] pb-6 font-medium ${
            isTabActive("omnilife") ? "text-gray-800 font-semibold border-b-4 border-gray-800" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("omnilife")}
        >
          Omni Life
        </NavLink>
      </nav>
    </div>
  );
};

export default AllCourses;

