import Privacy from "../HomePage/Privacy";
import AdminPublicationForm from "../PublicationSection/AdminPublication/AdminPublicationForm";
import AdminLayout from "../layout/AdminLayout";
import Main from "../layout/Main";
import RayzorPaymentModal from "../Modal/RayzorPaymentModal/RayzorPaymentModal";
import AdminProjectForm from "../ProjectSection/AdminProject/AdminProjectForm/AdminProjectForm";
import AboutUi from "../AboutSection/AboutUi/AboutUi";
import Error from "../Error/Error";
import AdminUpdateProject from "../ProjectSection/AdminProject/AdminProjectForm/AdminUpdateProject";
import AdminJoinCommunity from "../JoinCommunitySection/AdminJoinCommunity/AdminJoinCommunity";
import AdminHeroSection from "../HeroSection/AdminHeroSection/AdminHeroSection";
import Newsletter from "../HomePage/Newsletter";
import AdminUnite from "../UniteSection/AdminUnite/AdminUnite";
import CourseLayout from "../layout/CourseLayout";
import Omniuya from "../CoursesSection/CoursesUi/Omniyua";
import Soonya from "../CoursesSection/CoursesUi/Soonya";
import Omnilife from "../CoursesSection/CoursesUi/Omnilife";
import AdminContribute from "../StewardSection/AdminContribute/AdminContribute";
import HomepageLayout from "../layout/HomepageLayout";
import Resource from "../ResourceSection/ResourceUI/Resource";
import ProjectUi from "../ProjectSection/ProjectUi/ProjectUi";
import Terms from "../HomePage/Terms";
import UpdateCourse from "../CoursesSection/AdminCourses/UpdateCourse";
import CourseDetails from "../CoursesSection/CoursesUi/CourseDetails";
import MbEvent from "../EventSection/EventUI/MbEvent";
import MbPublication from "../PublicationSection/PublicationUi/MbPublication";
import ContributionPage from "../StewardSection/ContributeUi/ContributionPage";
import AdminAllCourses from "../CoursesSection/AdminCourses/AdminAllCourses";
import PublicationPage from "../PublicationSection/PublicationUi/PublicationPage";
import AdminResourceForm from "../ResourceSection/AdminResource/AdminResourceForm";
import AdminAbout from "../AboutSection/AdminAbout/AdminAbout";
import UpdateShoonya from "../CoursesSection/AdminCourses/UpdateShoonya";
import PrivateRoute from "./PrivateRoute";
import Login from "../Login/Login";
import OmniyuaCourseDetails from "../CoursesSection/CoursesUi/OmniyuaCourseDetails";
import UpdateOmniyuaCourse from "../CoursesSection/AdminCourses/UpdateOmniyuaCourse";
import OmniVillage from "../OmniVillage/OmniVillage";
import AdminPilotLocations from "../OmniVillage/PilotLocations/AdminPilotLocationForm.";
import UpdatePilotLocation from "../OmniVillage/PilotLocations/UpdatePilotLocation";
import LocationDetails from "../OmniVillage/PilotLocations/LocationDetails";
import AdminFunding from "../OmniVillage/Funding&Vlounteering/AdminFundingForm";
import UpdateFunding from "../OmniVillage/Funding&Vlounteering/AdminFundingUpdate";
import AdminMediaForm from "../OmniVillage/Media/AdminMediaForm";
import UpdateVolunteering from "../OmniVillage/Funding&Vlounteering/AdminVolunteerUpdate";
import AdminNetwork from "../OmniVillage/Network&Expertise/AdminNetwork";
import UpdateNetwork from "../OmniVillage/Network&Expertise/NetworkUpdate";
import UpdateExpertise from "../OmniVillage/Network&Expertise/ExpertiseUpdate";
import HomePage from "../HomePage/HomePage";
const { createBrowserRouter } = require("react-router-dom");

export const router = createBrowserRouter([
  {
    path: "*",
    element: <Error />,
  },
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/rayzorpay",
        element: <RayzorPaymentModal />,
      },

      {
        path: "/projects",
        element: <ProjectUi />,
      },
      {
        path: "/contribute",
        element: <ContributionPage />,
      },

      {
        path: "/library",
        element: <Resource />,
      },
      {
        path: "/all-articles",
        element: <MbPublication />,
      },
      {
        path: "/articles",
        element: <PublicationPage />,
      },
      {
        path: "/events",
        element: <MbEvent />,
      },

      {
        path: "/contact",
        element: <Newsletter />,
      },
    ],
  },
  {
    path: "/participate",
    element: <CourseLayout />,
    children: [
      {
        path: "/participate",
        element: <Omniuya />,
      },
      {
        path: "/participate/shoonya",
        element: <Soonya />,
      },

      {
        path: "/participate/omni-life",
        element: <Omnilife />,
      },
    ],
  },

  {
    path: "/participate/omniyua-course/:dynamicUrl",
    element: <OmniyuaCourseDetails />,
    loader: ({ params }) =>
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/omni-yua/course-details/${params.dynamicUrl}`
      ),
  },
  {
    path: "/about",
    element: <AboutUi />,
    loader: () =>
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/get-about-info/64ba0939170b8d9fabc9af1d`
      ),
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
  {
    path: "/terms-condition",
    element: <Terms />,
  },
  {
    path: "/participate/omni-life/:dynamicUrl",
    element: <CourseDetails />,

    loader: ({ params }) =>
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/omni-life/course-details/${params.dynamicUrl}`
      ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/AdminofOmnilife",
    element: (
      <PrivateRoute>
        <AdminLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/AdminofOmnilife",
        element: <AdminPublicationForm />,
      },
      {
        path: "/AdminofOmnilife/update-shoonya",
        element: <UpdateShoonya />,
      },
      {
        path: "/AdminofOmnilife/update-about",
        element: <AdminAbout />,
        loader: () =>
          fetch(
            `https://omniverse-server-3222344a8118.herokuapp.com/get-about-info/64ba0939170b8d9fabc9af1d`
          ),
      },
      {
        path: "/AdminofOmnilife/update-resource",
        element: <AdminResourceForm />,
      },
      {
        path: "/AdminofOmnilife/post-project",
        element: <AdminProjectForm />,
      },
      {
        path: "/AdminofOmnilife/update-course",
        element: <AdminAllCourses />,
      },
      {
        path: "/AdminofOmnilife/update-course/all-information/:id",
        element: <UpdateCourse />,
        loader: ({ params }) =>
          fetch(
            `https://omniverse-server-3222344a8118.herokuapp.com/omni-life/course-detail/${params.id}`
          ),
      },
      {
        path: "/AdminofOmnilife/update-omniyua-course/all-information/:id",
        element: <UpdateOmniyuaCourse />,
        loader: ({ params }) =>
          fetch(
            `https://omniverse-server-3222344a8118.herokuapp.com/omniyua/course-details-id/${params.id}`
          ),
      },
      {
        path: "/AdminofOmnilife/update-project-text",
        element: <AdminUpdateProject />,
      },
      {
        path: "/AdminofOmnilife/update-pilot-locations",
        element: <AdminPilotLocations />,
      },
      {
        path: "/AdminofOmnilife/update-location/all-information/:id",
        element: <UpdatePilotLocation />,
        loader: ({ params }) =>
          fetch(
            `https://omniverse-server-3222344a8118.herokuapp.com/location-detail/${params.id}`
          ),
      },
      {
        path: "/AdminofOmnilife/update-funding",
        element: <AdminFunding />,
      },
      {
        path: "/AdminofOmnilife/update-funding/all-information/:id",
        element: <UpdateFunding />,
        loader: ({ params }) =>
          fetch(
            `https://omniverse-server-3222344a8118.herokuapp.com/funding-detail/${params.id}`
          ),
      },
      {
        path: "/AdminofOmnilife/update-voluntering/all-information/:id",
        element: <UpdateVolunteering />,
        loader: ({ params }) =>
          fetch(`https://omniverse-server-3222344a8118.herokuapp.com/voluntering-detail/${params.id}`),
      },
      {
        path: "/AdminofOmnilife/update-network",
        element: <AdminNetwork />,
      },
      {
        path: "/AdminofOmnilife/update-network/all-information/:id",
        element: <UpdateNetwork />,
        loader: ({ params }) =>
          fetch(`https://omniverse-server-3222344a8118.herokuapp.com/network-detail/${params.id}`),
      },
      {
        path: "/AdminofOmnilife/update-expertise/all-information/:id",
        element: <UpdateExpertise />,
        loader: ({ params }) =>
          fetch(`https://omniverse-server-3222344a8118.herokuapp.com/expertise-detail/${params.id}`),
      },
      {
        path: "/AdminofOmnilife/update-media",
        element: <AdminMediaForm />,
      },
    ],
  },
  {
    path: "/AdminofOmnilife/update-homepage",
    element: <HomepageLayout />,
    children: [
      {
        path: "/AdminofOmnilife/update-homepage",
        element: <AdminHeroSection />,
      },
      {
        path: "/AdminofOmnilife/update-homepage/steward-text",
        element: <AdminContribute />,
      },
      {
        path: "/AdminofOmnilife/update-homepage/contribute-text",
        element: <AdminUnite />,
      },
      {
        path: "/AdminofOmnilife/update-homepage/unite-text",
        element: <AdminJoinCommunity />,
      },
    ],
  },
  {
    path: "/omnivillage",
    element: <OmniVillage />,
    children: [
      {
        path: "/omnivillage/pilot-locations",
      },
    ],
  },
  {
    path: "/omnivillage/pilot-locations/:dynamicUrl",
    element: <LocationDetails />,

    loader: ({ params }) =>
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/location-details/${params.dynamicUrl}`
      ),
  },
]);
