import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../HomePage/Footer";

const MbPublication = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setProjects(data);
      });
  }, []);
  return (
    <div className="bg-white">
      <Navbar />
      <div className="px-5 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 xl:gap-10 md:max-h-[100vh] md:overflow-y-auto md:scrollbar-hide">
          {projects.map((blog) => (
            <a href={blog.link} target="blank">
              <div className=" flex flex-col bg-white justify-between items-center  h-[360px] w-full md:w-[310px] xl:w-[370px]  md:h-[470px] my-4  shadow-xl  rounded-2xl pb-5">
                <img
                  src={blog.img}
                  alt="blog"
                  className="h-[300px]  md:h-[350px] rounded-t-xl"
                />
                <h1 className="text-base md:text-xl px-1 pt-2 font-normal text-gray-800">
                  {blog.title}
                </h1>
              </div>
            </a>
          ))}
        </div>
        <div className="pt-32 md:pt-36">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MbPublication;
