import React, { useEffect, useState } from "react";
import leaf from "../../assets/leaf.png";

const ContributeUi = () => {
  const [contributes, setContributes] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/support-work")
      .then((res) => res.json())
      .then((data) => {
        setContributes(data);
      });
  }, []);
  return (
    <div
      id="contribute"
      className="py-3 md:py-0 px-5 md:px-10  lg:px-16 4xl:px-20 6xl:px-20 flex flex-col justify-center items-start relative"
    >
      <div className="flex justify-between flex-col items-center lg:flex-row-reverse ">
        <div className="">
          <img
            src={leaf}
            alt="leaf"
            className="hidden lg:block lg:h-[630px] xl:h-[770px] 3xl:h-[700px] 4xl:h-[700px] absolute lg:-top-40 xl:-top-48 3xl:-top-52 5xl:-top-64 right-0 z-30"
          />
        </div>
        <div className="w-full lg:w-2/3  pl-0 ">
          <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center lg:text-left text-gray-800 mb-0 md:mb-9 2xl:mb-4">
            Contribute
          </h1>
          {contributes.map((contribute) => (
            <p className="text-[13.65px] md:text-[16px] xl:text-[20px] 6xl:text-[24px] py-4 md:py-0 md:pb-3 text-gray-800">
              {contribute.text}
            </p>
          ))}
        </div>
      </div>

      <a href="/contribute">
        <button
          class="relative inline-block text-lg group cursor-pointer mt-3 z-20"
          type="submit"
        >
          <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span class="absolute left-0 w-full h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span class="relative">Explore how you can help</span>
          </span>
          <span
            class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
            data-rounded="rounded-lg"
          ></span>
        </button>
      </a>
    </div>
  );
};

export default ContributeUi;
