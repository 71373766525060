import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../HomePage/Footer";

const MbEvent = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-every-course/true"
    )
      .then((res) => res.json())
      .then((data) => {
        setEvents(data);
        console.log("mb", data);
      });
  }, []);
  return (
    <div className="bg-white">
      <Navbar />
      <div className="px-5 md:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 xl:gap-10 md:max-h-[100vh] md:overflow-y-auto md:scrollbar-hide pt-10 md:pt-16">
          {events.map((event) => (
            <div className="flex flex-col space-y-4 md:space-y-5 lg:space-y-7 items-start">
              <Link
                to={
                  event?.isPublish
                    ? `/participate/omniyua-course/${event.dynamicUrl}`
                    : `/participate/omni-life/${event.dynamicUrl}`
                }
              >
                <img
                  src={event.img}
                  alt="Event"
                  className="h-[400px] w-full lg:w-full  md:h-[400px] 3xl:h-[450px] cursor-pointer"
                />

                <div className="space-y-4 ">
                  <h1 className="font-semibold tracking-wider font-sans  text-[23px] xl:text-3xl 6xl:text-4xl text-gray-800 pt-6">
                    {event.courseName}
                  </h1>
                  <p className="font-normal font-sans text-start text-[20px] xl:text-2xl 6xl:text-5xl text-gray-800 py-2 3xl:leading-10 4xl:leading-[42px] 5xl:leading-[46px]">
                    {event?.otherInfos[0].slice(0, 69)}...
                  </p>
                  <p className="font-sans text-start text-[20px] xl:text-2xl 6xl:text-5xl text-gray-800">
                    <span className="font-semibold">Starting from : </span>
                    {event?.date}
                  </p>
                </div>
              </Link>
              <Link
                to={
                  event?.isPublish
                    ? `/participate/omniyua-course/${event.dynamicUrl}`
                    : `/participate/omni-life/${event.dynamicUrl}`
                }
              >
                <button
                  class="relative inline-block text-lg group"
                  type="submit"
                >
                  <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                    <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                    <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                    <span class="relative">Know more</span>
                  </span>
                  <span
                    class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                    data-rounded="rounded-lg"
                  ></span>
                </button>
              </Link>
            </div>
          ))}
        </div>
        <div className="pt-28 md:pt-36">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MbEvent;
