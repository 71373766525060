import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlinePublishedWithChanges, MdUnpublished } from "react-icons/md";
import { TbTrashOff } from "react-icons/tb";
import { Link } from "react-router-dom";

const AdminPilotLocationForm = () => {
  const [locations, setLocations] = useState([]);

  //get omnilife course  get-trial-courses  https://omniverse-server-3222344a8118.herokuapp.com/all-courses
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-location")
      .then((res) => res.json())
      .then((data) => {
        setLocations(data);
      });
  }, []);

  //new omnilife object

  const handleAddNewObject = () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      locationName: "Location name",
      otherInfos: ["Add other information of Pilot location here.", ""],
      dynamicUrl: "location-name",
      content: "<p> Type here !!!</p>",
      isPublish: false,
    };
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/create-new-location", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ newObject }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Location added successfully`);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // delete omnilife course
  const handleDelete = (location) => {
    const agree = window.confirm("Do you want to delete this location?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-location/${location._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This location has been deleted successfully !");
          }
        });
    }
  };

  //make it publish
  const handlePublishTrue = async (_id) => {
    const makePublish = true;

    const confirmation = window.confirm(
      "Are you sure you want to add this course to the website?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-location-isPublish-true/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makePublish }),
        }
      );

      if (response.ok) {
        toast.success("Congrats !! Your location now added to the site.");
        window.location.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //make it publish
  const handleisPublishFalse = async (_id) => {
    const makeunPublish = false;

    const confirmation = window.confirm(
      "Are you sure to unpublish this course?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-location-isPublish-false/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makeunPublish }),
        }
      );

      if (response.ok) {
        toast.success("The location has been removed from the website");
        window.location.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="bg-white px-5 py-5 md:px-10 lg:px-16 xl:px-20 3xl:px-28 4xl:px-28 pt-20 h-screen overflow-hidden">
      <div className="flex items-center space-x-5 float-right">
        <div
          onClick={handleAddNewObject}
          className="flex justify-between mb-7 items-center   text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add a Pilot Location</button>
        </div>
      </div>

      {/* omni life */}
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-10 overflow-hidden">
        Pilot Locations{" "}
      </h1>
      <table className="w-full">
        <tbody className="">
          {locations.map((location, index) => (
            <tr className="my-2 border-b-2">
              <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                {index + 1}
              </td>
              <td className="text-xl text-gray-900 w-2/12 px-2 py-4">
                {location.locationName.slice(0, 10)}
              </td>
              <td className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                <Link
                  to={`/AdminofOmnilife/update-location/all-information/${location._id}`}
                >
                  Update location
                </Link>
              </td>
              {location.isPublish === false ? (
                <td
                  onClick={() => handlePublishTrue(location._id)}
                  className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              ) : (
                <td
                  onClick={() => handleisPublishFalse(location._id)}
                  className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              )}

              <td
                onClick={() => handleDelete(location)}
                title="Remove"
                className="px-3 py-1 my-2 font-semibold cursor-pointer text-center"
              >
                <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                  <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPilotLocationForm;
