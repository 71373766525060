import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlinePublishedWithChanges, MdUnpublished } from "react-icons/md";
import { TbTrashOff } from "react-icons/tb";
import { Link } from "react-router-dom";

const AdminNetwork = () => {
  const [networks, setNetworks] = useState([]);
  const [experts, setExpertise] = useState([]);
  // Fetch expertise
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-expertise")
      .then((res) => res.json())
      .then((data) => {
        setExpertise(data);
      });
  }, []);

  // Fetch network
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-networks")
      .then((res) => res.json())
      .then((data) => {
        setNetworks(data);
      });
  }, []);

  // Function to add a new network
  const handleAddNewNetwork = () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      networkName: "Network name",
      isPublish: false,
    };
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/create-new-network", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ newObject }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Network added successfully`);
        window.funding.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Add new expertise
  const handleAddNewExpertise = () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      expertiseName: "Expertise name",
      isPublish: false,
    };
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/create-new-expertise", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ newObject }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Expertise added successfully`);
        window.funding.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Function to delete a funding cart
  const handleDeleteExpertise = (expertise) => {
    // Your code to delete a funding cart...
    const agree = window.confirm("Do you want to delete this funding?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-expertise/${expertise._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This expertise has been deleted successfully !");
          }
        });
    }
  };

  // Function to delete a network
  const handleDeleteNetwork = (network) => {
    const agree = window.confirm("Do you want to delete this network?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-network/${network._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This network has been deleted successfully !");
          }
        });
    }
  };

  // Function to publish a expertise
  const handlePublishExpertise = async (_id) => {
    // Your code to publish a funding cart...
    const makePublish = true;

    const confirmation = window.confirm(
      "Are you sure you want to add this expertise to the website?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-expertise-isPublish-true/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makePublish }),
        }
      );

      if (response.ok) {
        toast.success("Congrats !! Your expertise now added to the site.");
        window.funding.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Function to publish a network
  const handlePublishNetwork = async (_id) => {
    const makePublish = true;

    const confirmation = window.confirm(
      "Are you sure you want to add this volunteer cart to the website?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-network-isPublish-true/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makePublish }),
        }
      );

      if (response.ok) {
        toast.success("Congrats !! Your network now added to the site.");
        window.network.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Function to unpublish expertise
  const handleUnpublishExpertise = async (_id) => {
    const makeunPublish = false;

    const confirmation = window.confirm(
      "Are you sure to unpublish this expertise?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-expertise-isPublish-false//${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makeunPublish }),
        }
      );

      if (response.ok) {
        toast.success("The expertise has been removed from the website");
        window.expertise.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // Function to unpublish a netowork c
  const handleUnpublishNetwork = async (_id) => {
    const makeunPublish = false;

    const confirmation = window.confirm(
      "Are you sure to unpublish this network cart?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-network-isPublish-false/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makeunPublish }),
        }
      );

      if (response.ok) {
        toast.success("The network has been removed from the website");
        window.network.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="bg-white px-5 py-5 md:px-10 lg:px-16 xl:px-20 3xl:px-28 4xl:px-28 pt-20 h-full overflow-hidden">
      <div className="flex items-center space-x-5 float-right">
        <div
          onClick={handleAddNewNetwork}
          className="flex justify-between mb-7 items-center  text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add a network</button>
        </div>
      </div>

      {/* Volunteer */}
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-10 overflow-hidden">
        Network
      </h1>
      <table className="w-full">
        <tbody className="">
          {networks.map((network, index) => (
            <tr className="my-2 border-b-2">
              <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                {index + 1}
              </td>
              <td className="text-xl text-gray-900 w-2/12 px-2 py-4">
                {network.networkName && network.networkName.slice(0, 10)}
              </td>

              <td className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                <Link
                  to={`/AdminofOmnilife/update-network/all-information/${network._id}`}
                >
                  Update network
                </Link>
              </td>

              <td
                onClick={() => handleDeleteNetwork(network)}
                title="Remove"
                className="px-3 py-1 my-2 font-semibold cursor-pointer text-center"
              >
                <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                  <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                </div>
              </td>

              {network.isPublish === false ? (
                <td
                  onClick={() => handlePublishNetwork(network._id)}
                  className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              ) : (
                <td
                  onClick={() => handleUnpublishNetwork(network._id)}
                  className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center space-x-5 float-right mt-10">
        <div
          onClick={handleAddNewExpertise}
          className="flex justify-between mb-7 items-center  text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add an expertise</button>
        </div>
      </div>

      {/* Expertise */}
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-10 overflow-hidden">
        Expertise
      </h1>
      <table className="w-full">
        <tbody className="">
          {experts.map((expertise, index) => (
            <tr className="my-2 border-b-2">
              <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                {index + 1}
              </td>
              <td className="text-xl text-gray-900 w-2/12 px-2 py-4">
                {expertise.expertiseName &&
                  expertise.expertiseName.slice(0, 10)}
              </td>

              <td className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                <Link
                  to={`/AdminofOmnilife/update-expertise/all-information/${expertise._id}`}
                >
                  Update Expertise
                </Link>
              </td>

              <td
                onClick={() => handleDeleteExpertise(expertise)}
                title="Remove"
                className="px-3 py-1 my-2 font-semibold cursor-pointer text-center"
              >
                <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                  <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                </div>
              </td>

              {expertise.isPublish === false ? (
                <td
                  onClick={() => handlePublishExpertise(expertise._id)}
                  className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              ) : (
                <td
                  onClick={() => handleUnpublishExpertise(expertise._id)}
                  className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminNetwork;
