import React from "react";
import CourseNavbar from "../../Navbar/CourseNavbar";
import aboutBg from "../../assets/ABOUT.png";
import Footer from "../../HomePage/Footer";
import { useLoaderData } from "react-router-dom";

const AboutUi = () => {
  const data = useLoaderData();
  const {
    _id,
    hindiText,
    moralText,
    name,
    description,
    aboutImage,
    middleText,
    stewardTitle,
    stewardText,
    stewardNames,
    guidingTitle,
    guidingText,
    guidingNames,
    alignedTitle,
    alignedNames,
    sanghaTitle,
    sanghaText,
    sanghaNames,
  } = data;
  const numberOfColumns = 3;
  const namesPerColumn = 10;

  //sliced steward name
  const slicedStewardNames = Array.from(
    { length: numberOfColumns },
    (_, columnIndex) =>
      stewardNames.slice(
        columnIndex * namesPerColumn,
        (columnIndex + 1) * namesPerColumn
      )
  );
  //sliced guiding name
  const slicedGuidingNames = Array.from(
    { length: numberOfColumns },
    (_, columnIndex) =>
      guidingNames.slice(
        columnIndex * namesPerColumn,
        (columnIndex + 1) * namesPerColumn
      )
  );
  //sliced aligned name
  const slicedAlignedNames = Array.from(
    { length: numberOfColumns },
    (_, columnIndex) =>
      alignedNames.slice(
        columnIndex * namesPerColumn,
        (columnIndex + 1) * namesPerColumn
      )
  );
  //sliced sangha name
  const slicedSanghaNames = Array.from(
    { length: numberOfColumns },
    (_, columnIndex) =>
      sanghaNames.slice(
        columnIndex * namesPerColumn,
        (columnIndex + 1) * namesPerColumn
      )
  );

  return (
    <article content="About Omniverse">
      <div className="bg-white">
        <div
          className="back-ground bg-center bg-cover min-h-screen  bg-no-repeat w-full pb-0 lg:pb-3 xl:pb-5 6xl:pb-7 md:mb-5"
          style={{
            backgroundImage: `url(${aboutBg})`,
          }}
        >
          <CourseNavbar />
          <div className="px-5 py-5 md:px-10 lg:px-10 xl:px-20 3xl:px-16 4xl:px-28  md:pb-0 md:pt-20  flex flex-col  space-y-8 font-sans">
            <div className="flex flex-col-reverse lg:flex-row justify-between items-center">
              <div className="w-full lg:w-7/12 space-y-1 md:space-y-5 lg:space-y-3 xl:space-y-5 4xl:space-y-6 6xl:space-y-7">
                {hindiText.map((hindi) => (
                  <p className="text-sm md:text-[26px] lg:text-[19px] xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start">
                    {hindi}
                  </p>
                ))}

                <div className="space-y-1 md:space-y-5 lg:space-y-3 xl:space-y-5 4xl:space-y-6 6xl:space-y-7 md:pt-5 lg:pt-5">
                  {moralText.map((moral) => (
                    <p className="text-sm md:text-[26px] lg:text-[19px] xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start">
                      {moral}
                    </p>
                  ))}

                  <p className="text-sm md:text-[26px] lg:text-[19px] pt-2 6xl:pt-5 5xl:pt-7 xl:text-[22px] 3xl:text-[27px] 6xl:text-[30px] text-gray-800 font-normal text-center lg:text-start">
                    {name}
                  </p>
                </div>
              </div>
              <div className="w-full md:w-10/12 lg:w-5/12 xl:w-6/12 6xl:w-7/12 mb-5 lg:mb-0">
                <img src={aboutImage} className="w-full " alt="" />
              </div>
            </div>

            <div className="flex flex-col justify-center items-start space-y-6">
              {description.map((des) => (
                <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                  {des}
                </p>
              ))}
              <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                With love & wishes for omni well-being,
                <br></br>
                Omniverse Life
              </p>
            </div>
            <h3 className="text-xl md:text-[31px] lg:text-[40px] font-normal text-gray-800 leading-8 md:leading-[50px] text-center lg:text-start py-1 md:py-5">
              {middleText}
            </h3>
            {/* Stewards of the Initiative 1 */}
            <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-10">
              <div className="w-full md:w-3/5 lg:w-2/4 3xl:w-6/12 md:pr-16 3xl:pr-10 4xl:pr-14">
                <h1 className="font-semibold md:font-normal text-xl md:text-[35px] lg:text-[43px] text-gray-800 leading-[48px] pb-5 md:pb-8 lg:pb-10 xl:pb-14">
                  {stewardTitle}
                </h1>
                <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px] pb-7 md:pb-10 lg:pb-14">
                  {stewardText}
                </p>
              </div>
              <div className="w-full md:w-2/5 lg:w-8/12  xl:w-2/4 2xl:w-6/12 3xl:w-6/12  space-y-2 6xl:space-y-4  border-b-2 pb-0 5xl:pb-8 border-gray-400">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-8 xl:gap-10">
                  {slicedStewardNames.map((columnNames, columnIndex) => (
                    <ul
                      key={columnIndex}
                      className="space-y-2 6xl:space-y-4 pb-5 "
                    >
                      {columnNames.map((name, index) => (
                        <li
                          key={index}
                          className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            {/* Guiding Lights 2 */}
            <div className="flex flex-col md:flex-row justify-between pt-5 md:pt-10">
              <div className="w-full md:w-3/5 lg:w-2/4 3xl:w-6/12 3xl:pr-10 4xl:pr-14">
                <h1 className="text-xl md:text-[35px] lg:text-[43px] font-semibold md:font-normal text-gray-800 leading-[48px] pb-5 md:pb-8 lg:pb-10 xl:pb-14">
                  {guidingTitle}
                </h1>
                <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal pb-5 md:pb-0 text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                  {guidingText}
                </p>
              </div>
              <div className="w-full md:w-2/5 lg:w-8/12  xl:w-2/4 2xl:w-6/12 3xl:w-6/12  space-y-2 6xl:space-y-4  border-b-2 pb-0 5xl:pb-8 border-gray-400">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-8 xl:gap-10">
                  {slicedGuidingNames.map((columnNames, columnIndex) => (
                    <ul
                      key={columnIndex}
                      className="space-y-2 6xl:space-y-4 pb-5 "
                    >
                      {columnNames.map((name, index) => (
                        <li
                          key={index}
                          className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            {/*  Aligned  organizations 3 */}
            <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-10">
              <div className="w-full md:w-2/5 lg:w-2/5 xl:w-2/4 3xl:w-6/12 md:mr-0 lg:mr-52 2xl:mr-56 3xl:mr-5 ">
                <h1 className="text-xl md:text-[35px] lg:text-[43px] font-semibold md:font-normal text-gray-800 leading-[48px] 4xl:pr-10">
                  {alignedTitle}
                </h1>
              </div>
              <div className="w-full md:w-2/5 lg:w-8/12  xl:w-2/4 2xl:w-6/12 3xl:w-6/12  space-y-2 6xl:space-y-4  border-b-2 pb-0 5xl:pb-8 border-gray-400">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-8 xl:gap-10">
                  {slicedAlignedNames.map((columnNames, columnIndex) => (
                    <ul
                      key={columnIndex}
                      className="space-y-2 6xl:space-y-4 pb-5 "
                    >
                      {columnNames.map((name, index) => (
                        <li
                          key={index}
                          className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            {/* Transformers  4 */}
            <div className="flex flex-col md:flex-row justify-between pt-0 md:pt-10">
              <div className="w-full md:w-2/5 lg:w-5/12 xl:w-2/4 3xl:w-6/12 lg:mr-52 2xl:mr-56 3xl:mr-5">
                <h1 className="text-xl md:text-[35px] lg:text-[43px] font-semibold md:font-normal text-gray-800 leading-[48px] pb-5 md:pb-8 lg:pb-10 xl:pb-14">
                  {sanghaTitle}
                </h1>
                <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 pb-5 md:pb-0 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px] 4xl:pr-5 5xl:pr-0">
                  {sanghaText}
                </p>
              </div>
              <div className="w-full md:w-2/5 lg:w-8/12  xl:w-2/4 2xl:w-6/12 3xl:w-6/12  space-y-2 6xl:space-y-4  border-b-2 pb-0 5xl:pb-8 border-gray-400">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-8 xl:gap-10">
                  {slicedSanghaNames.map((columnNames, columnIndex) => (
                    <ul
                      key={columnIndex}
                      className="space-y-2 6xl:space-y-4 pb-5 "
                    >
                      {columnNames.map((name, index) => (
                        <li
                          key={index}
                          className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]"
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            <div className="pt-10 lg:pt-16">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AboutUi;
