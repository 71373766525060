import React, { useEffect, useState } from "react";
import logo from "../assets/omni-logo.png";
import sunset from "../assets/hero-bg1.png";
import HomeNavbar from "../Navbar/HomeNavbar";

const HeroSction = () => {
  const [heroText, setHeroText] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/hero-section")
      .then((res) => res.json())
      .then((data) => {
        setHeroText(data);
      });
  }, [heroText]);
  return (
    <div className="bg-white relative">
      <div
        className="h-[600px] md:h-screen  xl:h-screen 6xl:h-screen w-full back-ground bg-center bg-cover   bg-no-repeat "
        style={{ backgroundImage: `url(${sunset})` }}
      >
        <HomeNavbar />

        <div className="flex flex-col-reverse lg:flex-row justify-between items-center pt-5 md:pt-20">
          <div className="md:pl-10 xl:pl-20 3xl:pl-14 text-center lg:text-start pb-10   lg:pb-36 4xl:px-28 pt-0 md:pt-10">
            {heroText.map((hero) => (
              <>
                <h1 className="font-bold text-[22px] md:text-3xl lg:text-4xl xl:text-5xl text-gray-800 pb-3 xl:pb-4">
                  {hero.title}
                </h1>
                <p className="font-medium md:font-normal text-xl md:text-2xl lg:text-3xl xl:text-3xl text-gray-800 tracking-normal xl:tracking-wide">
                  {hero.paraOne}
                </p>
                <p className="font-medium md:font-normal text-xl md:text-2xl lg:text-3xl xl:text-3xl text-gray-800 tracking-normal xl:tracking-wide">
                  {hero.paraTwo}
                </p>
              </>
            ))}
          </div>
          <img
            className="hidden  xl:block h-[400px] w-[400px] mb-52  xl:mr-28  4xl:mr-40 "
            src={logo}
            alt="logo"
          />
          <img
            className="h-[180px] w-[180px] md:h-[250px] md:w-[250px] lg:h-[350px] lg:w-[350px] xl:hidden mb-7 md:mr-10 lg:mr-24 lg:mb-32"
            src={logo}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSction;
