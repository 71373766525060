import React from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import { Outlet } from "react-router-dom";
import AdminHomePage from "../HomePage/AdminHomePage";

const HomepageLayout = () => {
  return (
    <div className="bg-white min-h-screen font-sans">
      <AdminNavbar />
      <div className="flex justify-around">
        <AdminHomePage />
        <Outlet />
      </div>
    </div>
  );
};

export default HomepageLayout;
