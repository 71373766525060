import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCysCgGvIYsUEinZR5v36Yj_TgKc2C4Yfg",
  authDomain: "omniverse-life.firebaseapp.com",
  projectId: "omniverse-life",
  storageBucket: "omniverse-life.appspot.com",
  messagingSenderId: "805917811006",
  appId: "1:805917811006:web:4874f6d750aba220dd8c6f",
  measurementId: "G-PCKD2YXQXJ"
};


const app = initializeApp(firebaseConfig);
export default app;