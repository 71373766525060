import React from "react";
import { toast } from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";

const AdminJoinCommunity = () => {
  const handleaUniTextUpdate = (e) => {
    e.preventDefault();
    const form = e.target;
    const uniText = { uniteText: form.uniText.value };
    console.log(uniText);
    fetch(
      `https://omniverse-server-3222344a8118.herokuapp.com/update-unite-text/647f0b72a434a959c7e1954c`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(uniText),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("This content is updated successfully");
        }
        form.reset("");
      })
      .catch((err) => console.error(err));
  };
  return (
    <div className="bg-gray-100 flex  justify-center items-start py-10 px-3 md:px-10 lg:py-0 lg:items-center min-h-screen">
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center space-y-1 my-5 w-full lg:w-[800px] px-5 lg:p-10 md:space-y-3 border-2 rounded-md bg-white">
          <h1 className="text-xl py-2 md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2">
            Update unite text{" "}
            <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
          </h1>

          <form
            onSubmit={handleaUniTextUpdate}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update unite text
            </lable>
            <textarea
              name="uniText"
              placeholder="Update unite text"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none text-gray-800"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminJoinCommunity;
