import React, { useEffect, useState } from "react";
import Footer from "../../HomePage/Footer";
import Navbar from "../../Navbar/Navbar";

const PilotLocation = () => {
  const [locations, setLocations] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const isPublish = true; // or false, depending on your requirement
    fetch(
      `https://omniverse-server-3222344a8118.herokuapp.com/get-pilot-location/${isPublish}`
    )
      .then((res) => res.json())
      .then((data) => {
        setLocations(data);
      });
  }, []);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="mt-12 bg-white relative p-4 md:p-8 lg:p-14 xl:p-16 2xl:p-20 3xl:p-14 lg:px-10 xl:px-20 2xl:px-10 3xl:px-14 4xl:px-28 ">
      <h1
        className={`text-3xl md:text-[45px] 2xl:text-5xl font-normal text-center text-gray-800 mb-6 md:mb-9 2xl:mb-4 ${
          windowWidth <= 1024 && "text-center"
        }`}
      >
        Pilot Locations
      </h1>
      {locations
        .slice(0, showMore ? locations.length : 2)
        .map((location, index) => (
          <div
            className={`flex flex-col ${
              windowWidth < 1024 ? "md:flex-col" : "md:flex-row"
            } md:my-20 lg:my-28 justify-between ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            }`}
            key={index}
          >
            {windowWidth < 1024 && (
              <img
                src={location.img}
                alt=""
                className="w-full h-[260px] md:h-[350px] xl:h-[400px] rounded-2xl "
              />
            )}
            <div
              className={`flex flex-col justify-center pt-7  mb-20 md:mb-0 space-y-4 lg:space-y-6 w-full lg:w-6/12 ${
                windowWidth < 1024 && "items-center"
              }`}
            >
              <p
                className={`text-xl lg:text-2xl xl:text-3xl text-gray-800 text-center md:text-left ${
                  windowWidth < 1024 && "text-center"
                }`}
              >
                {location.locationName}
              </p>
              <p
                className={`lg:hidden text-lg md:text-xl text-gray-800 text-center md:text-left ${
                  windowWidth < 1024 && "text-center"
                }`}
              >
                {location?.otherInfos?.slice(0, 400)} ...
              </p>
              <p
                className={`hidden xl:block text-lg md:text-xl text-gray-800 text-center md:text-left ${
                  windowWidth < 1024 && "text-center"
                }`}
              >
                {location?.otherInfos?.slice(0, 380)} ...
              </p>
              <p
                className={`hidden lg:block xl:hidden text-lg md:text-xl text-gray-800 text-center md:text-left ${
                  windowWidth < 1024 && "text-center"
                }`}
              >
                {location?.otherInfos?.slice(0, 215)} ...
              </p>
              {location.dynamicUrl ? (
                <a href={`omnivillage/pilot-locations/${location.dynamicUrl}`}>
                  <button
                    className="relative inline-block text-lg group"
                    type="submit"
                  >
                    <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                      <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                      <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                      <span className="relative">Know more</span>
                    </span>
                    <span
                      className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                      data-rounded="rounded-lg"
                    ></span>
                  </button>
                </a>
              ) : (
                <p
                  className={`text-lg md:text-xl xl:text-lg 6xl:text-xl text-start font-semibold text-gray-800 tracking-normal xl:tracking-wide ${
                    windowWidth < 1024 && "text-center"
                  }`}
                >
                  Revealing Soon
                </p>
              )}
            </div>
            {windowWidth >= 1024 && (
              <img
                src={location.img}
                alt=""
                className="w-full md:w-6/12 lg:w-5/12 h-[260px] md:h-[350px] xl:h-[400px] rounded-2xl"
              />
            )}
          </div>
        ))}
      <div className="flex justify-center mt-0">
        <button
          onClick={toggleShowMore}
          className="relative inline-block text-lg group"
        >
          <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
            <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
            <span className="relative">
              {showMore ? "Show Less" : "Show More"}
            </span>
          </span>
          <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"></span>
        </button>
      </div>
    </div>
  );
};

export default PilotLocation;
