import React from "react";
import omniyua from "../../assets/omniyua1.png";
import shoonya from "../../assets/shoonya1.png";
import omnilife from "../../assets/Omnilife1.png";

const CoursesUi = () => {
  const courses = [
    {
      id: 1,
      image: omniyua,
      category: "OmniYua",
      content: "omniyua",
      text: "For Self Transformation",
      route: "/participate",
    },
    {
      id: 2,
      image: shoonya,
      category: "Shoonya",
      content: "shoonya",
      text: "Important Conversations",
      route: "/participate/shoonya",
    },
    {
      id: 3,
      image: omnilife,
      category: "Omni Life",
      text: "For Harmony of Self with Society & Nature",
      content: "omnilife",
      route: "/participate/omni-life",
    },
  ];
  return (
    <div id="courses" className="relative">
      <div className="bg-white py-10 md:py-10 lg:py-16 px-5 md:px-10 lg:px-12 xl:px-14 6xl:px-20 flex flex-col justify-center items-center ">
        <h1 className="text-3xl md:text-[45px] lg:text-5xl  font-semibold lg:font-normal text-center pt-7 pb-2 md:py-5 text-gray-800 mt-0 md:-mt-14 xl:-mt-20">
          Tools for Well-becoming
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-5  lg:grid-cols-3 lg:gap-x-10 xl:gap-x-24 pt-14">
          {courses.map((course) => (
            <a href={course.route} className="relative w-full">
              <img
                src={course.image}
                alt="yoga"
                className="md:h-[300px] lg:h-[400px] w-full xl:h-[470px] xl:w-[372px] 2xl:h-[500px]  3xl:h-[520px] 4xl:h-[530px] 5xl:h-[550px] 6xl:h-[700px] 2xl:w-full rounded-lg"
              />
              <p className="font-semibold tracking-wider text-center text-2xl xl:text-4xl 6xl:text-5xl  text-white -mt-24 md:-mt-20 xl:-mt-28 mb-16 lg:mb-14 xl:mb-20">
                {course.category}
              </p>

              <p className="font-medium text-[13.65px] md:text-[16px] xl:text-[18px] 6xl:text-[24px] text-gray-800 py-2 cursor-pointer text-center pt-3 md:pt-1 2xl:pt-3 ">
                {course.text}
              </p>
            </a>
          ))}
        </div>
        <div className="flex justify-center items-center pt-7 xl:pt-10 w-full bg-white">
          <a href="/participate">
            <button class="relative inline-block text-lg group" type="submit">
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Know more</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CoursesUi;
