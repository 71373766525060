import React, { useEffect, useState } from "react";
import snail from "../../assets/snail.png";

const UniteUi = () => {
  const [uniteTexts, setUniteTexts] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/unite-text")
      .then((res) => res.json())
      .then((data) => {
        setUniteTexts(data);
      });
  }, [uniteTexts]);
  return (
    <div className="pt-10 pb-8 md:pb-2 md:pt-14 px-5 md:px-10  lg:px-16 6xl:px-20 flex flex-col justify-center items-center relative bg-white">
      <div className="flex justify-between flex-col items-center lg:flex-row ">
        <div className="">
          <img
            src={snail}
            alt="Snail"
            className="h-[900px] xl:h-[950px] absolute left-0 lg:-top-40  hidden lg:block z-10"
          />
        </div>
        <div className="w-full lg:w-2/3 pl-0 lg:pl-7">
          <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center lg:text-start  text-gray-800 mb-1 md:mb-6">
            Unite, Merge & Flow
          </h1>
          {uniteTexts.map((unite) => (
            <p className=" text-[13.65px] md:text-[16px] xl:text-[20px] 6xl:text-[24px] py-4 text-gray-800">
              {unite.uniteText}
              <span className="italic">{unite.italicText}</span>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UniteUi;
