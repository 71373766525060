import React from "react";
import cloud from "../assets/cloud.png";
import Footer from "./Footer";

const Newsletter = () => {
  return (
    <div className="bg-white relative ">
      <div
        className="px-5 pt-16 md:pt-28 lg:pt-0 pb-2 md:py-10 lg:py-5 md:px-10  lg:px-16 4xl:px-20 6xl:px-20 back-ground bg-cover bg-center h-full bg-no-repeat w-full  relative z-0"
        style={{ backgroundImage: `url(${cloud})` }}
      >
        <Footer />
      </div>
    </div>
  );
};
export default Newsletter;
