import React, { useEffect } from "react";
import { Link, useLoaderData } from "react-router-dom";
import "react-quill/dist/quill.core.css";
import "../../Editor/editor.css"
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Footer from "../../HomePage/Footer";
import Navbar from "../../Navbar/Navbar";

const OmniyuaCourseDetails = () => {
  const data = useLoaderData();
  const { _id, img, courseName, courseTitle, otherInfos, button } = data;

  const renderedContent = React.createElement("div", {
    dangerouslySetInnerHTML: { __html: data.content },
  });

  // Convert the React element to an HTML string
  const contentHtml = renderedContent.props.dangerouslySetInnerHTML.__html;

  // Use string manipulation to add the class to specific <a> tags
  const modifiedContentHtml = contentHtml
    .replace(/<span\b/g, '<span class="paragraph"')
    .replace(/<a\b/g, '<a class="blue-link"')
    .replace(/<ul\b/g, '<ul class="custom-ul-list"')
    .replace(/<ol\b/g, '<ol class="custom-ol-list"')
    .replace(/<h1\b/g, '<h1 class="h2"')
    .replace(/<h2\b/g, '<h1 class="h2"');

  // Create a new React element with the modified HTML string
  const modifiedRenderedContent = React.createElement("div", {
    dangerouslySetInnerHTML: { __html: modifiedContentHtml },
  });

  // ... Razorpay
  //razorpay button 1
  useEffect(() => {
    const loadPaymentButtonScript = () => {
      const form = document.getElementById("form");
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = button;

      if (form) {
        form.appendChild(script);
      }
    };
    loadPaymentButtonScript();

    return () => {
      const script = document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/payment-button.js"]'
      );
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);
  //razorpay button 2
  useEffect(() => {
    const loadPaymentButtonScript = () => {
      const form = document.getElementById("payment");
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = button;

      if (form) {
        form.appendChild(script);
      }
    };
    loadPaymentButtonScript();

    return () => {
      const script = document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/payment-button.js"]'
      );
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="bg-white font-sans">
          <Navbar />
      <div className="py-3 md:py-5 px-5 md:px-10 xl:px-20 3xl:px-16 4xl:px-28">
        <Link to="/participate">
          <button className="relative inline-block text-lg group mt-2 xl:mt-3">
            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
              <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
              <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
              <span className="relative">
                <MdOutlineKeyboardBackspace className="text-2xl" />
              </span>
            </span>
            <span
              className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
              data-rounded="rounded-lg"
            ></span>
          </button>
        </Link>
        <div className="pb-10  xl:pb-16">
          {/* top container */}
          <div className="flex flex-col lg:flex-row justify-between items-center py-6">
            <div>
              <img
                className=" md:h-96 lg:h-[420px] 2xl:h-[450px] 3xl:h-[500px] 5xl:h-[520px] mb-7 lg:mb-0"
                src={img}
                alt="CourseImage"
              />
            </div>
            <div className="lg:w-3/5 xl:w-4/6 6xl:w-9/12 lg:pl-5 flex flex-col items-center lg:items-start">
              <h1
                id="title"
                className="font-semibold capitalize text-3xl lg:text-5xl text-center lg:text-start text-gray-800 pb-3"
              >
                {courseName}
              </h1>
              <p className="font-normal text-lg lg:text-3xl text-center lg:text-start text-gray-800 pb-4 lg:pb-8">
                {courseTitle}
              </p>
              {/* others */}
              <div className="space-y-6">
                {otherInfos.map((other) => (
                  <p className="font-normal text-lg lg:text-3xl text-center lg:text-start text-gray-800">
                    {other}
                  </p>
                ))}
              </div>
              <div className="">
                <form className="pt-2" id="form"></form>
              </div>
            </div>
          </div>
          <div className="2xl:pt-10 lg:px-24 2xl:px-28 6xl:px-32">
            <div className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-7  md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
              {modifiedRenderedContent}
            </div>
            <form className="pt-2" id="payment"></form>
          </div>
          
    
      
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OmniyuaCourseDetails;