import React from "react";
import OmniVillageBackground from "../assets/OmniVillage/water.png";
import HeroImage from "../assets/OmniVillage/HeroImage.png";
import ContentImg1 from "../assets/OmniVillage/KnowledgeEarth.png";
import ContentImg2 from "../assets/OmniVillage/info2_2.png";
import animation from "../assets/OmniVillage/INFO2.mov";
import OmniVillageNav from "../Navbar/OmniVillageNav";
import "../HeroSection/OVHS.css";

const OmniVillageHeroSection = () => {
  return (
    <div className="relative overflow-hidden">
      <OmniVillageNav />
      <div
        className="h-screen w-screen md:h-screen lg:h-screen xl:h-screen back-ground bg-center bg-cover bg-no-repeat relative flex flex-col justify-center items-center"
        style={{
          backgroundImage: `url(${OmniVillageBackground})`,
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center pt-5 w-full">
          <div className="md:pl-10 xl:pl-20 3xl:pl-14 text-center lg:text-start pb-10 lg:pb-36 4xl:px-28 pt-0">
            <>
              <h1 className="font-bold text-[22px] md:text-3xl lg:text-4xl xl:text-5xl text-gray-800 pb-3 xl:pb-4">
                Smart Omni Village
              </h1>
              <p className="font-medium md:font-normal text-xl md:text-2xl lg:text-3xl xl:text-3xl text-gray-800 tracking-normal xl:tracking-wide">
                An Integral Approach to Development 
              </p>
              <p className="font-medium md:font-normal text-xl md:text-2xl lg:text-3xl xl:text-3xl text-gray-800 tracking-normal xl:tracking-wide">
              for Omni Wellbeing
              </p>
           
            </>
          </div>
          <img
            className="hidden xl:block responsive-img responsive-img-lg1 mb-52 xl:mr-28 4xl:mr-40"
            src={HeroImage}
            alt="HeroImg"
          />
          <img
            className="h-[200px] w-[200px] md:h-[270px] md:w-[270px] lg:h-[370px] lg:w-[370px] xl:hidden mb-7 lg:mr-24 lg:mb-32 responsive-img"
            src={HeroImage}
            alt="HeroImg"
          />
        </div>
      </div>
     <div className="container mx-auto px-5 py-9 md:px-10 lg:px-10 xl:px-16 3xl:px-14 bg-white overflow-hidden mb-12 px-4k-0">
       <h1 className="text-3xl md:text-5xl font-normal text-center text-gray-800 mb-9">
        Why Integral Approach?
      </h1>

      <div className="flex flex-col lg:flex-row justify-between items-center space-y-5 lg:space-y-0 lg:space-x-5">
        <img
          src={ContentImg1}
          alt="Content Image 1"
          className="responsive-img responsive-img-lg"
        />
        <div className="space-y-3 xl:space-y-4 lg:p-0 lg:pl-4 lg:pr-10 lg:w-1/2">
          <p className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 py-1">
            The old development model has failed.
          </p>
          <p className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800">
            Reasons for failure:
          </p>
          <div className="flex flex-wrap items-center">
            <span className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 before:inline-block before:content-['\2716'] before:mr-2 before:relative before:top-[2px]">Founded on a misinformed paradigm that humans are to dominate over nature.</span>
          </div>
          <div className="flex flex-wrap items-center">
            <span className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 before:inline-block before:content-['\2716'] before:mr-2 before:relative before:top-[2px]">Misdefines development: unidimensional focus on GDP.</span>
          </div>
          <div className="flex flex-wrap items-center">
            <span className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 before:inline-block before:content-['\2716'] before:mr-2 before:relative before:top-[2px]">Based on the outdated knowledge system of the 19th century.</span>
          </div>
          <div className="flex flex-wrap items-center">
            <span className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 before:inline-block before:content-['\2716'] before:mr-2 before:relative before:top-[2px]">Takes a siloed approach.</span>
          </div>
        </div>
      </div>``
</div>

<div className="mt-20 container mx-auto px-5 py-3 md:px-10 lg:px-10 xl:px-16 3xl:px-14 bg-white overflow-hidden mb-12 px-4k-0">
  <div className="flex flex-col lg:flex-row-reverse justify-between items-center lg:space-y-0">
    <img
      src={ContentImg2}
      alt="Content Image 2"
      className="responsive-img2 responsive-img2-lg"
    />
    <div className="space-y-3 xl:space-y-4 lg:p-0 lg:pr-4 lg:w-1/2">
      <p className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 py-1">
        Omni Approach puts the life back into the center of the development agenda
      </p>
      <p className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800 py-1">
        By integrating the five domains that shape the experience of human life and the choices.
      </p>
      <p className="font-light text-sm md:text-lg lg:text-base xl:text-lg 3xl:text-xl text-gray-800">
        Because at the center of all development actions is a human body, mind, and spirit, it balances outer progress with inner progress.
      </p>
    </div>
  </div>
</div>


<div className="container mx-auto px-5 p-14 md:px-10 lg:px-10 xl:px-16 3xl:px-14 bg-white overflow-hidden ">
  <div className="container mx-auto px-5  md:px-10 lg:px-10   bg-white overflow-hidden ">
    <div className="flex flex-col lg:flex-row justify-between items-center lg:items-center lg:h-full">
      <div className="flex justify-center lg:w-2/3"> {/* Increased width */}
        <video className="responsive-video" loop autoPlay muted>
          <source src={animation} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="flex flex-col justify-center items-center lg:items-start lg:w-1/3 lg:pl-10 lg:h-full">
        <h1 className="text-2xl md:text-[25px] 2xl:text-[35px] font-normal text-center lg:text-left text-gray-800 ">
          It takes an eightfold ecosystem approach to design and development
        </h1>
      </div>
    </div>
  </div>
</div>

    </div>


     
  );
};

export default OmniVillageHeroSection;
