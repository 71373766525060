import React, { useEffect, useState } from "react";
import PublicationSlider from "../Media/PubicateArticles";
import ReactPlayer from "react-player";
import MediaModal from "./MediaModal";
import snail2 from "../../assets/OmniVillage/finalTurtle.png";
import "./Media.css";

const Media = () => {
  const [medias, setMedias] = useState({ videos: [], images: [] });
  const [visibleMedias, setVisibleMedias] = useState(3);
  const [showAllMedias, setShowAllMedias] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    const isPublish = true;
    fetch(`https://omniverse-server-3222344a8118.herokuapp.com/get-media/${isPublish}`)
      .then((res) => res.json())
      .then((data) => {
        setMedias(data);
        console.log("data", data);
      });
  }, []);

  const handleShowMoreMedias = () => {
    if (!showAllMedias) {
      setVisibleMedias(medias.videos.length + medias.images.length);
    } else {
      setVisibleMedias(3);
    }
    setShowAllMedias(!showAllMedias);
  };

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
  };

  const handleCloseModal = () => {
    setSelectedMedia(null);
  };

  // Merge images and videos arrays into a single array with alternating items
  const mergedMedias = [];
  for (
    let i = 0;
    i < Math.max(medias.videos.length, medias.images.length);
    i++
  ) {
    if (medias.images[i]) {
      mergedMedias.push({ ...medias.images[i], type: "image" });
    }
    if (medias.videos[i]) {
      mergedMedias.push({ ...medias.videos[i], type: "video" });
    }
  }

  return (
    <div className="mt-20 p-0 md:px-10 lg:px-10 xl:px-20 2xl:px-10 3xl:px-14 4xl:px-28">
      <div id="media" className="bg-white font-sans overflow-hidden mt-8 mb-24">
        <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-normal text-center text-gray-800 mb-0 md:mb- 2xl:mb-20">
          Media
        </h1>
        <div
          className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 md:gap-14 3xl:gap-18"
          style={{ marginTop: "35px" }}
        >
          {mergedMedias.slice(0, visibleMedias).map((media) => (
            <div
              key={media.id}
              className="flex flex-col justify-center items-center space-y-3 cursor-pointer"
              onClick={() => handleMediaClick(media)}
            >
              <div className="media-wrapper">
                {media.type === "image" ? (
                  <img
                    src={media.img}
                    alt="Image"
                    className="media-item"
                  />
                ) : (
                  <ReactPlayer
                    url={media.videoLink}
                    width="100%"
                    height="100%"
                    controls={true}
                    className="media-item"
                  />
                )}
              </div>
              <h1 className="text-[16px] md:text-2xl lg:text-2xl xl:text-2xl text-center font-semibold text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
                {media.type === "image" ? media.imageTitle : media.videoTitle}
              </h1>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center mt-10 mb-5 xl:mt-14 3xl:mt-16">
          {mergedMedias.length > 3 && (
            <button
              className="relative inline-block text-lg group"
              onClick={handleShowMoreMedias}
            >
              <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span className="relative">
                  {showAllMedias ? "Close all" : "Show More"}
                </span>
              </span>
              <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"></span>
            </button>
          )}
        </div>
      </div>
      <div
      className="relative flex flex-col bg-white px-0 py-5 md:pb-10 md:px-7 lg:px-8 6xl:px-20 md:py-10"
      id="articles"
    >
      <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-normal lg:font-normal text-center  pb-5 md:pb-14 6xl:pb-16 text-gray-800">
      Articles
      </h1>
      <div
        className="flex flex-col xl:flex-row-reverse
          justify-between"
      >
        <div className=" w-full xl:w-5/12">
          <img
            src={snail2}
            alt="snail"
            className="hidden xl:block absolute   xl:h-[450px] xl:w-[550px] 5xl:h-[600px] 6xl:h-[650px] 6xl:w-[800px] 6xl:pb-20 3xl:right-0"
          />
        </div>
   <div className="xl:w-7/12">
   <PublicationSlider/>
   </div> </div>
      </div>

      {selectedMedia && (
        <MediaModal media={selectedMedia} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default Media;
