import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavbar from '../Navbar/AdminNavbar';

const AdminLayout = () => {
    return (
        <div className='font-sans'>
            <AdminNavbar/>
            <Outlet/>
        </div>
    );
};

export default AdminLayout;