import React, { useEffect, useState } from "react";
import sunflower from "../../assets/sunflower.png";

const JoinCommunitySectionUi = () => {
  const [stewardText, setStewardText] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/steward-text")
      .then((res) => res.json())
      .then((data) => {
        setStewardText(data);
      });
  }, [stewardText]);
  return (
    <div
      id="community"
      className=" pb-3 pt-5 md:pt-10 mb:pb-0 px-5 md:px-10  lg:px-16 6xl:px-20 flex flex-col justify-center items-center relative"
    >
      <img
        className="absolute left-0 lg:-top-14 2xl:-top-16 3xl:-top-16 4xl:-top-12 5xl:-top-14 6xl:-top-40 z-0 hidden lg:block lg:h-[520px] xl:h-[580px] 4xl:h-[550px]  6xl:h-[760px] "
        src={sunflower}
        alt="sunflower"
      />
      <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center lg:text-start  text-gray-800 mb-3">
        Stewards
      </h1>
      <div className="flex justify-center flex-col items-center lg:flex-row pt-2 pb-4">
        <div className="w-full lg:w-5/12 "></div>
        <div className="w-full lg:w-7/12  2xl:-ml-10 3xl:ml-0 pb-2 lg:pb-7 xl:pb-10 6xl:pb-14">
          <h1 className="text-[13.65px] md:text-[16px] xl:text-[20px] 6xl:text-[24px] font-semibold text-gray-800">
            #TheMightyWe
          </h1>
          {stewardText.map((ste) => (
            <p className="text-[13.65px] md:text-[16px] xl:text-[20px] 6xl:text-[24px] py-2 lg:py-4 text-gray-800">
              {ste.stewardText}
            </p>
          ))}
          <a href="/about">
            <button
              class="relative inline-block text-lg group cursor-pointer mt-3"
              type="submit"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-52 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Who we are</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunitySectionUi;
