import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MdOutlinePublishedWithChanges, MdUnpublished } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import EditImageModal from "./EditImageModal";

// 1. Image Upload Function
const handleUpdateImage = async (previewImage) => {
  try {
    const imgBbKey = "f375a7d7cbf6443df6506145faff4414";
    const formData = new FormData();
    formData.append("image", previewImage);

    const response = await fetch(
      `https://api.imgbb.com/1/upload?key=${imgBbKey}`,
      {
        method: "POST",
        body: formData,
      }
    );

    const responseData = await response.json();
    console.log("API response:", responseData);

    if (responseData.success) {
      console.log(responseData.data.url);
      return responseData.data.url; // Return the updated image URL
    } else {
      console.error("Image upload failed:", responseData.error);
      return null;
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

// 2. AdminAllImages Component
const AdminAllImages = () => {
  const [images, setImages] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-media-image/")
      .then((res) => res.json())
      .then((data) => setImages(data));
  }, []);

  const handleDeleteImage = (image) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      fetch(`https://omniverse-server-3222344a8118.herokuapp.com/delete-media-image/${image._id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success("Image deleted successfully!");
            setImages(images.filter((img) => img._id !== image._id));
          }
        });
    }
  };

  const handlePublishImage = async (id, publishStatus) => {
    const confirmation = window.confirm(
      `Are you sure you want to ${
        publishStatus ? "publish" : "unpublish"
      } this image?`
    );

    if (!confirmation) return;

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/update-image-publish-status/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isPublish: publishStatus }),
        }
      );

      if (response.ok) {
        toast.success(
          `Image ${publishStatus ? "published" : "unpublished"} successfully!`
        );
        setImages(
          images.map((img) =>
            img._id === id ? { ...img, isPublish: publishStatus } : img
          )
        );
      } else {
        console.error("Failed to update publish status");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleEditImage = (image) => {
    setSelectedImage(image);
    setIsEditModalOpen(true);
  };

  // 3. handleSaveImage function inside AdminAllImages
  const handleSaveImage = async (
    id,
    updatedTitle,
    updatedDescription,
    updatedImageFile
  ) => {
    let imageUrl = null;

    if (updatedImageFile) {
      imageUrl = await handleUpdateImage(updatedImageFile);
      if (!imageUrl) {
        toast.error("Failed to upload the image.");
        return;
      }
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/update-media-image/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imageTitle: updatedTitle,
            imageDescription: updatedDescription,
            img: imageUrl, // Use the new image URL if it was updated
          }),
        }
      );

      if (response.ok) {
        toast.success("Image updated successfully!");
        const updatedImages = images.map((img) =>
          img._id === id
            ? {
                ...img,
                imageTitle: updatedTitle,
                imageDescription: updatedDescription,
                img: imageUrl ? imageUrl : img.img,
              }
            : img
        );
        setImages(updatedImages);
      } else {
        console.error("Failed to update image");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setIsEditModalOpen(false);
  };

  return (
    <div className="bg-gray-100 pl-3 md:pl-5 lg:pl-10 xl:pl-14 px-7 md:px-10 py-10">
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-2 border-b w-full pb-2 mt-3">
        All Images
      </h1>
      <div className="flex flex-col justify-center items-center">
        <table className="w-full max-h-[100vh] overflow-y-scroll scrollbar-hide">
          <tbody className="py-3">
            {images.map((image, index) => (
              <tr className="my-2 border-b-2 text-center" key={image._id}>
                <td className="text-xl text-gray-900 w-1/4 px-2 py-4">
                  {index + 1}
                </td>
                <td className="text-xl text-gray-900 hover:text-blue-500 cursor-pointer w-2/4 px-2 py-4">
                  <a target="_blank" rel="noopener noreferrer">
                    {image.imageTitle}
                  </a>
                </td>
                <td
                  className="text-xl text-gray-900 w-1/4 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  onClick={() => handleDeleteImage(image)}
                >
                  Remove
                </td>
                <td
                  onClick={() =>
                    handlePublishImage(image._id, !image.isPublish)
                  }
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    {image.isPublish ? (
                      <MdUnpublished className="text-2xl text-red-500 hover:text-white" />
                    ) : (
                      <MdOutlinePublishedWithChanges className="text-2xl text-green-500 hover:text-white" />
                    )}
                  </div>
                </td>
                <td
                  onClick={() => handleEditImage(image)}
                  className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                    <FaPencilAlt className="text-xl text-blue-500 hover:text-white" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isEditModalOpen && selectedImage && (
        <EditImageModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          image={selectedImage}
          onSave={handleSaveImage}
        />
      )}
    </div>
  );
};

export default AdminAllImages;
