import React, { useState, useEffect } from "react";

const EditImageModal = ({ isOpen, onClose, image, onSave }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [imageTitle, setImageTitle] = useState(image.imageTitle);
  const [imageDescription, setImageDescription] = useState(
    image.imageDescription
  );

  useEffect(() => {
    setImageTitle(image.imageTitle);
    setImageDescription(image.imageDescription);
  }, [image]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImage(e.target.files[0]);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    onSave(image._id, imageTitle, imageDescription, previewImage);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white w-[600px] px-3 md:p-8 md:space-y-3 border-2 rounded-md">
        <div className="hover:scale-110 duration-300 flex justify-center mb-5">
          {previewImage ? (
            <img
              className="h-40 w-40"
              src={URL.createObjectURL(previewImage)}
              alt="Preview"
            />
          ) : (
            <img className="h-40 w-40 mb-5" src={image.img} alt="Current" />
          )}
        </div>

        <form
          onSubmit={handleSave}
          className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
        >
          <label
            htmlFor="editImage"
            className="px-4 py-3 bg-gray-800 text-white text-xl font-semibold rounded-lg cursor-pointer"
          >
            Upload an image
          </label>
          <input
            className="hidden"
            type="file"
            name="img"
            id="editImage"
            onChange={handleFileChange}
            accept="image/*"
          />

          <textarea
            name="imageTitle"
            placeholder="Title of Image"
            value={imageTitle}
            onChange={(e) => setImageTitle(e.target.value)}
            className="w-full h-12 pt-3 px-2 text-lg md:text-xl border bg-white border-gray-200 rounded-md outline-none text-gray-900"
            required
          ></textarea>

          <textarea
            name="imageDescription"
            placeholder="Description of Image"
            value={imageDescription}
            onChange={(e) => setImageDescription(e.target.value)}
            className="w-full h-32 pt-3 px-2 text-lg md:text-xl border bg-white border-gray-200 rounded-md outline-none text-gray-900"
            required
          ></textarea>

          <button className="relative inline-block text-lg group" type="submit">
            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
              <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
              <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
              <span className="relative">Save</span>
            </span>
            <span
              className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
              data-rounded="rounded-lg"
            ></span>
          </button>
        </form>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditImageModal;
