import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlinePublishedWithChanges, MdUnpublished } from "react-icons/md";
import { TbTrashOff } from "react-icons/tb";
import { Link } from "react-router-dom";

const AdminAllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [omniyuaCourses, setOmniyuaCourses] = useState([]);

  //get omnilife course  get-trial-courses  https://omniverse-server-3222344a8118.herokuapp.com/all-courses
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-courses")
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
      });
  }, []);

  //get omniyua course   https://omniverse-server-3222344a8118.herokuapp.com/get-omniyua-course
  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-omniyua-course"
    )
      .then((res) => res.json())
      .then((data) => {
        setOmniyuaCourses(data);
      });
  }, []);

  //new omnilife object

  const handleAddNewObject = () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      courseName: "Course name",
      courseTitle: "Course title",
      otherInfos: ["Add other information of course here.", ""],
      content: "<p> Type here !!!</p>",
      date: "01-01-2023",
      isEvent: false,
      button: "payment-button",
      isUpcoming: false,
      dynamicUrl: "course-name",
    };
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/create-new-object",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ newObject }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Course added successfully`);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  //new omniyua object

  const handleAddNewOmniyuaObject = () => {
    const newObject = {
      img: "https://i.ibb.co/TwDsXjY/300369269-1702452933473398-4463316480368542160-n.jpg",
      courseName: "Course-name",
      courseTitle: "Course title",
      otherInfos: ["Add other information of course here.", ""],
      date: "01-01-2023",
      button: "payment-button",
      dynamicUrl: "course-name",
      content: "<p> Type here !!!</p>",
      isPublish: false,
      isEvent: false,
    };
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/create-new-omniyua-course",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ newObject }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Course added successfully`);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // delete omnilife course
  const handleDelete = (course) => {
    const agree = window.confirm("Are you ready to delete this course?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-course/${course._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This course has been deleted successfully !");
          }
        });
    }
  };
  // delete omniyua course
  const handleOmniyuaDelete = (course) => {
    const agree = window.confirm("Are you ready to delete this course?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-omniyua-course/${course._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This course has been deleted successfully !");
          }
        });
    }
  };
  // post as previous exp
  const postPrevious = (img) => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/post-previous-course",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ img }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success(`Added this course to previous successfully`);
        }
      })
      .catch((err) => console.log(err));
  };

  // ------------------------Omnilife isEvent & publish status change --------------------//
  //change upcoming false
  const handleUpcomingFalse = async (_id, img) => {
    const changeIsUpcoming = false;

    const confirmation = window.confirm(
      "Are you sure to add this course to previous experience? Remember you can't change it later."
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-isUpcoming-false/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsUpcoming }),
        }
      );

      if (response.ok) {
        toast.success("Removed from upcoming experience successfully");

        // Call the postPrevious function with the required parameter
        postPrevious(img);

        window.location.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  //change isEent false
  const handleEventFalse = async (_id) => {
    const changeIsEvent = false;

    const confirmation = window.confirm(
      "Are you sure to remove this course from event?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-isEvent-false/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsEvent }),
        }
      );

      if (response.ok) {
        toast.success("Removed from event successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //change isEent true
  const handleEventTrue = async (_id) => {
    const changeIsEvent = true;

    const confirmation = window.confirm(
      "Are you sure to add this course to event?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-isEvent-true/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsEvent }),
        }
      );

      if (response.ok) {
        toast.success("Added to event successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //make it publish
  const handlePublishTrue = async (_id) => {
    const makePublish = true;

    const confirmation = window.confirm("Are you sure to publish this course?");

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-omnilife-publish/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makePublish }),
        }
      );

      if (response.ok) {
        toast.success("Congrats !! Your course is live now.");
        window.location.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //make it publish
  const handleUnpublishTrue = async (_id) => {
    const makeunPublish = false;

    const confirmation = window.confirm(
      "Are you sure to unpublish this course?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-omnilife-unpublish/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ makeunPublish }),
        }
      );

      if (response.ok) {
        toast.success("Your course isn't live anymore.");
        window.location.reload();
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // ------------------------Omniyua isEvent & publish status change --------------------//

  //change isPublish true of omniyua
  const handleisPublishFalse = async (_id) => {
    const changeIsEvent = false;

    const confirmation = window.confirm(
      "Are you sure to unpublish this course ?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-isPublish-false/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsEvent }),
        }
      );

      if (response.ok) {
        toast.success("You just make this course unpublished.");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //change isPublish true of omniyua
  const handleIsPublishTrue = async (_id) => {
    const changeIsEvent = true;

    const confirmation = window.confirm("Are you sure to publish this course?");

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-isPublish-true/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsEvent }),
        }
      );

      if (response.ok) {
        toast.success("Congrates !!! Your course is live now.");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //change isEent false
  const handleIsEventFalse = async (_id) => {
    const changeIsEvent = false;

    const confirmation = window.confirm(
      "Are you sure to remove this course from event?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-omniyua-isEvent-false/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsEvent }),
        }
      );

      if (response.ok) {
        toast.success("Removed from event successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //change isEent true
  const handleIsEventTrue = async (_id) => {
    const changeIsEvent = true;

    const confirmation = window.confirm(
      "Are you sure to add this course to event?"
    );

    if (!confirmation) {
      return;
    }

    try {
      const response = await fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/make-omniyua-isEvent-true/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ changeIsEvent }),
        }
      );

      if (response.ok) {
        toast.success("Added to event successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="overflow-x-auto bg-white px-5 py-5 md:px-10 lg:px-16 xl:px-20 3xl:px-28 4xl:px-28 pt-20 h-screen">
      <div className="flex items-center space-x-5 float-right">
        <div
          onClick={handleAddNewObject}
          className="flex justify-between mb-7 items-center   text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add omnilife course</button>
        </div>
        <div
          onClick={handleAddNewOmniyuaObject}
          className="flex justify-between mb-7 items-center   text-gray-800 hover:text-blue-500 px-3 py-1 border rounded-lg"
        >
          <span className="inline-flex text-2xl ">
            <AiOutlinePlusCircle />
          </span>
          <button className="text-2xl ml-2">Add omniyua course</button>
        </div>
      </div>
      <div className="pt-14">
        {/* omni yua */}
        <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-3">
          Omniyua courses{" "}
        </h1>
        {omniyuaCourses.length === 0 ? (
          <p className="text-xl text-gray-900 text-center px-2 py-4">
            No course available
          </p>
        ) : (
          <table className="w-full ">
            <tbody className="py-3">
              {omniyuaCourses.map((course, index) => (
                <tr className="my-2 border-b-2">
                  <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                    {index + 1}
                  </td>
                  <td className="text-center text-xl text-gray-900 w-3/12 px-2 py-4">
                    {course.courseName.slice(0, 15)}
                  </td>
                  <td className="text-xl text-center text-gray-900 w-4/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                    <Link
                      to={`/AdminofOmnilife/update-omniyua-course/all-information/${course._id}`}
                    >
                      Upadate course
                    </Link>
                  </td>
                  {course.isEvent === true ? (
                    <td
                      onClick={() => handleIsEventFalse(course._id)}
                      className="text-xl text-gray-900 w-2/12 px-2 py-4  hover:text-blue-500 cursor-pointer"
                    >
                      Remove event
                    </td>
                  ) : (
                    <td className="px-2 py-4">
                      {" "}
                      <button
                        onClick={() => handleIsEventTrue(course._id)}
                        className="text-xl text-gray-900  hover:text-blue-500 cursor-pointer"
                      >
                        Add for event
                      </button>
                    </td>
                  )}
                  {course.isPublish === false ? (
                    <td
                      onClick={() => handleIsPublishTrue(course._id)}
                      className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                    >
                      <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                        <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                      </div>
                    </td>
                  ) : (
                    <td
                      onClick={() => handleisPublishFalse(course._id)}
                      className="px-2 py-1  text-lg font-semibold cursor-pointer text-center"
                    >
                      <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                        <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                      </div>
                    </td>
                  )}
                  <td
                    onClick={() => handleOmniyuaDelete(course)}
                    className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                  >
                    <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                      <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* omni life */}
        <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10 py-5 border-b w-full pb-2 mt-10">
          Omni-life courses{" "}
        </h1>
        <table className="w-full ">
          <tbody className="">
            {courses.map((course, index) => (
              <tr className="my-2 border-b-2">
                <td className="text-xl text-gray-900 w-1/12 px-2 py-4">
                  {index + 1}
                </td>
                <td className="text-xl text-gray-900 w-2/12 px-2 py-4">
                  {course.courseName.slice(0, 10)}
                </td>
                <td className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer">
                  <Link
                    to={`/AdminofOmnilife/update-course/all-information/${course._id}`}
                  >
                    Upadate course
                  </Link>
                </td>

                {course.isUpcoming === true ? (
                  <td
                    onClick={() => {
                      const img = course.img;
                      handleUpcomingFalse(course._id, img);
                    }}
                    id="img"
                    className="text-xl text-gray-900 w-4/12 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  >
                    Mark as previous experience
                  </td>
                ) : (
                  <td className="cursor-not-allowed text-xl text-gray-900 w-4/12 px-2 py-4 hover:text-blue-500">
                    Marked as previous experience
                  </td>
                )}
                {course.isEvent === true ? (
                  <td
                    onClick={() => handleEventFalse(course._id)}
                    className="text-xl text-gray-900 w-2/12 px-2 py-4  hover:text-blue-500 cursor-pointer"
                  >
                    Remove event
                  </td>
                ) : (
                  <td
                    onClick={() => handleEventTrue(course._id)}
                    className="text-xl text-gray-900 w-2/12 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  >
                    Add for event
                  </td>
                )}
                {course.isEvent === false && course.isUpcoming === false ? (
                  <td
                    onClick={() => handlePublishTrue(course._id)}
                    title="Publish"
                    className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                  >
                    <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                      <MdOutlinePublishedWithChanges className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                    </div>
                  </td>
                ) : (
                  <td
                    onClick={() => handleUnpublishTrue(course._id)}
                    title="Unpublish"
                    className="px-2 py-1 text-lg font-semibold cursor-pointer text-center"
                  >
                    <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-blue-500">
                      <MdUnpublished className="text-3xl text-center text-gray-800 hover:text-white hover:bg-blue-500" />
                    </div>
                  </td>
                )}
                <td
                  onClick={() => handleDelete(course)}
                  title="Remove"
                  className="px-3 py-1 my-2 font-semibold cursor-pointer text-center"
                >
                  <div className="flex justify-center items-center h-12 w-12 rounded-full duration-200 hover:bg-red-500">
                    <TbTrashOff className="text-3xl text-center text-gray-800 hover:text-white " />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminAllCourses;
