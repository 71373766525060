import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import logo from "../assets/logo1.png";
import hamb from "../assets/OmniVillage/button.png";

const OmniVillageNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const hamburgerMenuRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const smartOmniSection = document.getElementById("smartOmni");
      const pilotSection = document.getElementById("PL");
      const fundingSection = document.getElementById("F&V");
      const mediaSection = document.getElementById("media");
      const nominateSection = document.getElementById("nominate");
      const networkSection = document.getElementById("network");

      if (
        !smartOmniSection ||
        !pilotSection ||
        !fundingSection ||
        !mediaSection ||
        !nominateSection ||
        !networkSection
      ) {
        return;
      }

      const extra = 15; // Adjust this value based on your layout
      const extra2 = 17; // Another adjustment value if needed

      if (
        scrollPosition >= smartOmniSection.offsetTop - extra &&
        scrollPosition < pilotSection.offsetTop - extra2
      ) {
        setActiveSection("smartOmni");
      } else if (
        scrollPosition >= pilotSection.offsetTop - extra2 &&
        scrollPosition < fundingSection.offsetTop - extra
      ) {
        setActiveSection("PL");
      } else if (
        scrollPosition >= fundingSection.offsetTop - extra &&
        scrollPosition < mediaSection.offsetTop - extra
      ) {
        setActiveSection("F&V");
      } else if (
        scrollPosition >= mediaSection.offsetTop - extra &&
        scrollPosition < nominateSection.offsetTop - extra
      ) {
        setActiveSection("media");
      } else if (
        scrollPosition >= nominateSection.offsetTop - extra &&
        scrollPosition < networkSection.offsetTop - extra
      ) {
        setActiveSection("nominate");
      } else if (scrollPosition >= networkSection.offsetTop - extra) {
        setActiveSection("network");
      } else {
        setActiveSection(null); // No section is active when scrolling at the top
      }

      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target)
      ) {
        setIsHamburgerMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSectionClick = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    sectionElement.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      setActiveSection(sectionId);
    }, 300); // Adjust the delay to match the smooth scroll duration
  };

  return (
    <div
      className={`fixed px-1 py-5 w-full sm:max-w-full md:min-w-full lg:max-w-screen-xl 2xl:max-w-full md:px-10 lg:px-8 3xl:px-14 lg:py-[20px] xl:px-15 2xl:px-20 4xl:px-28 ${
        isScrolled ? "bg-white" : "bg-transparent"
      }`}
      style={{ zIndex: 1000 }} // Add a higher z-index value to keep the navbar on top
    >
      <div className="relative flex items-center justify-between ">
        <Link to="/projects">
          <button className="relative inline-block text-lg group  xl:mt-1">
            <span className="relative z-10 block px-1  overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
              <span className="absolute inset-0 w-full h-full px-5  rounded-lg bg-gray-50"></span>
              <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
              <span className="relative">
                <MdOutlineKeyboardBackspace className="text-2xl" />
              </span>
            </span>
            <span
              className="absolute bottom-0 right-0  h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
              data-rounded="rounded-lg"
            ></span>
          </button>
        </Link>
        <a href="#">
          <p className="font-bold text-base lg:text-[12px] xl:text-[25px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 block py-2">
            Smart Omni Village
          </p>
        </a>

        <ul className="flex  items-center hidden lg:space-x-4 xl:space-x-[30px] 2xl:space-x-[32px] 6xl:space-x-[41px] lg:flex ">
          <li>
            <a
              href="#smartOmni"
              className={`font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 ${
                activeSection === "smartOmni" ? "underline" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick("smartOmni");
              }}
            >
              Philosophy
            </a>
          </li>
          <li>
            <a
              href="#PL"
              className={`font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 ${
                activeSection === "PL" ? "underline" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick("PL");
              }}
            >
              Pilot Locations
            </a>
          </li>
          <li>
            <a
              href="#F&V"
              className={`font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 ${
                activeSection === "F&V" ? "underline" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick("F&V");
              }}
            >
              Funding & Volunteering
            </a>
          </li>
          <li>
            <a
              href="#media"
              className={`font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 ${
                activeSection === "media" ? "underline" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick("media");
              }}
            >
              Media
            </a>
          </li>
          <li>
            <a
              href="#nominate"
              className={`font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 ${
                activeSection === "nominate" ? "underline" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick("nominate");
              }}
            >
              Nominate
            </a>
          </li>
          <li>
            <a
              href="#network"
              className={`font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800 ${
                activeSection === "network" ? "underline" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSectionClick("network");
              }}
            >
              Network & Expertise
            </a>
          </li>
          <li>
            <a
              href={require("../assets/OmniVillage/SOV White Paper.pdf")}
              className="font-medium text-base lg:text-[11px] xl:text-[11px] 6xl:text-[22px] uppercase tracking-wide text-gray-800"
              download="SOV White Paper.pdf"
            >
              White Paper
            </a>
          </li>
        </ul>
        <div className="lg:hidden z-10" ref={hamburgerMenuRef}>
          <button
            onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
            className="px-2 py-1 text-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <img
              src={hamb}
              alt="Hamburger Menu"
              className="w-[35px] h-[30px]"
            />
          </button>
          {isHamburgerMenuOpen && (
            <div className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl">
              <Link
                to="#smartOmni"
                className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 ${
                  activeSection === "smartOmni" ? "underline" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionClick("smartOmni");
                  setIsHamburgerMenuOpen(false); // Close the menu
                }}
              >
                Philosophy
              </Link>
              <Link
                to="#PL"
                className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 ${
                  activeSection === "PL" ? "underline" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionClick("PL");
                  setIsHamburgerMenuOpen(false); // Close the menu
                }}
              >
                Pilot Locations
              </Link>
              <Link
                to="#F&V"
                className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 ${
                  activeSection === "F&V" ? "underline" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionClick("F&V");
                  setIsHamburgerMenuOpen(false); // Close the menu
                }}
              >
                Funding & Volunteering
              </Link>
              <Link
                to="#media"
                className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 ${
                  activeSection === "media" ? "underline" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionClick("media");
                  setIsHamburgerMenuOpen(false); // Close the menu
                }}
              >
                Media
              </Link>
              <Link
                to="#nominate"
                className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 ${
                  activeSection === "nominate" ? "underline" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionClick("nominate");
                  setIsHamburgerMenuOpen(false); // Close the menu
                }}
              >
                Nominate
              </Link>
              <Link
                to="#network"
                className={`block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 ${
                  activeSection === "network" ? "underline" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleSectionClick("network");
                  setIsHamburgerMenuOpen(false); // Close the menu
                }}
              >
                Network & Expertise
              </Link>
              <a
                href={require("../assets/OmniVillage/SOV White Paper.pdf")}
                className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                download="SOV White Paper.pdf"
              >
                White Paper
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OmniVillageNav;