import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import UpdateProjectLink from "../../../Modal/UpdateProjectLink/UpdateProjectLink";

const AdminAllProject = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-projects")
      .then((res) => res.json())
      .then((data) => {
        setProjects(data);
      });
  }, [projects]);

  //delete project
  const handleDelete = (project) => {
    const agree = window.confirm("Are you ready to delete ?");
    if (agree) {
      fetch(
        `https://omniverse-server-3222344a8118.herokuapp.com/delete-project/${project._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success(" This project has been deleted successfully !");
          }
        });
    }
  };

  return (
    <div className="bg-gray-100 pl-3 md:pl-5 lg:pl-10 xl:pl-14 px-7 md:px-10">
      <h1 className="text-xl md:text-3xl text-center text-gray-800 font-semibold mb-10  py-2 border-b w-full pb-2 mt-3">
        All projects{" "}
      </h1>
      <div className="">
        <table className="w-full ">
          <tbody className="py-3">
            {projects.map((project, index) => (
              <tr className="my-2 border-b-2 text-center">
                <td className="text-xl text-gray-900 w-1/4 px-2 py-4">
                  {index + 1}
                </td>
                <td className="text-xl text-gray-900 w-1/4 px-2 py-4  b">
                  {project.projectTitle}
                </td>

                <td className=" text-xl text-gray-900 w-1/4 px-2 py-4 hover:text-blue-500 cursor-pointer">
                  <>
                    <label htmlFor={`project-${project._id}`}>
                      Update project
                    </label>
                    <UpdateProjectLink
                      _id={project._id}
                      projectLink={project.projectLink}
                      projectTitle={project.projectTitle}
                      description={project.description}
                    />
                  </>
                </td>

                <td
                  className="text-xl text-gray-900 w-2/4 px-2 py-4 hover:text-blue-500 cursor-pointer"
                  onClick={() => handleDelete(project)}
                >
                  Remove
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminAllProject;
