import React, { useEffect, useState } from "react";
import Footer from "../../HomePage/Footer";
import meet1 from "../../assets/shoonya/aa 1.png";
import meet2 from "../../assets/shoonya/aa1 1.png";
import meet3 from "../../assets/shoonya/aa2 1.png";
import meet4 from "../../assets/shoonya/aa3 1.png";
import meet5 from "../../assets/shoonya/aa5 1.png";

const Omnilife = ({ activeTab }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-course-status/true"
    )
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
        console.log("data", data);
      });
  }, []);

  // previous experience
  const [allExp, setAllExp] = useState([]);

  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-all-previous-courses"
    )
      .then((res) => res.json())
      .then((data) => {
        setAllExp(data);
        console.log("data", data);
      });
  }, []);

  const meets = [
    {
      id: 1,
      image: meet1,
    },
    {
      id: 2,
      image: meet2,
    },
    {
      id: 3,
      image: meet3,
    },
    {
      id: 4,
      image: meet4,
    },
  ];

  return (
    <article
      content="Upcoming Experiences"
      id="omnilife"
      className="bg-white px-5 py-5 md:px-10 md:pt-10 md:pb-5 xl:px-20 xl:py-10 font-sans"
    >
      <div className="bg-white">
        {/* course */}
        <h1 className="text-2xl md:text-[45px] lg:text-5xl font-semibold lg:font-normal text-center lg:text-start pt-7 md:py-5 text-gray-800 leading-9 md:leading-9 pb-5 border-b-2">
          Upcoming Experiences
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 xl:gap-10 mt-10">
          {courses.map((course) => (
            <div className="flex flex-col space-y-4 md:space-y-5 lg:space-y-7 items-start">
              <a href={`/participate/omni-life/${course.dynamicUrl}`}>
                <img
                  src={course.img}
                  alt="Event"
                  className="h-[400px] w-full lg:w-full  md:h-[400px] 3xl:h-[500px] cursor-pointer"
                />

                <div className="space-y-4 ">
                  <h1 className="font-semibold tracking-wider font-sans  text-[23px] xl:text-3xl 6xl:text-4xl text-gray-800 pt-6">
                    {course.courseName}
                  </h1>
                  <p className="font-normal font-sans text-start text-[20px] xl:text-2xl 6xl:text-5xl text-gray-800 py-2 3xl:leading-10">
                    {course?.otherInfos[0].slice(0, 69)}
                  </p>
                  <p className="font-sans text-start text-[20px] xl:text-2xl 6xl:text-5xl text-gray-800">
                    <span className="font-semibold">Starting from : </span>
                    {course?.date}
                  </p>
                </div>
              </a>
              <a href={`/participate/omni-life/${course.dynamicUrl}`}>
                <button
                  class="relative inline-block text-lg group"
                  type="submit"
                >
                  <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                    <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                    <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                    <span class="relative">Know more</span>
                  </span>
                  <span
                    class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                    data-rounded="rounded-lg"
                  ></span>
                </button>
              </a>
            </div>
          ))}
        </div>

        {/* exp */}

        <div className="py-5 md:pt-20 md:pb-10">
          <h1 className="text-2xl md:text-[45px] lg:text-5xl font-semibold lg:font-normal text-center lg:text-start pt-7 md:py-5 text-gray-800 leading-9 md:leading-9 pb-5 border-b-2">
            Previous Experiences
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 md:gap-x-10 md:gap-y-8 lg:gap-16 4xl:gap-20 py-8 3xl:pt-12 lg:max-h-[100vh] lg:overflow-y-auto lg:scrollbar-hide">
            {allExp.map((exp) => (
              <img
                src={exp.img}
                alt="previousCourse"
                className="md:h-96 lg:h-[450px]  w-full"
              />
            ))}
          </div>
        </div>
        {/* meet */}
        <div>
          <h1 className="text-2xl md:text-[27px] lg:text-[26px] xl:text-[29px] 3xl:text-[32px] 4xl:text-[37px] font-medium text-start lg:text-start pt-7 md:py-5 text-gray-800 leading-9 md:leading-9 3xl:leading-[43px] 4xl:leading-[46px] 5xl:leading-[51px] pb-5">
            A growing community of more than 700+ participants from 5 different
            countries
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-10 md:gap-y-8 lg:gap-10 4xl:gap-14 py-8 3xl:pt-12">
            {meets.map((exp) => (
              <img
                src={exp.image}
                alt="tratak"
                className="md:h-60 lg:h-72 xl:h-96 2xl:h-80 6xl:h-96 w-full"
              />
            ))}
          </div>
          <img
            src={meet5}
            alt="tratak"
            className="h-40 md:h-60 lg:h-72 xl:h-96 2xl:h-80 6xl:h-96  w-full"
          />
        </div>
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </article>
  );
};

export default Omnilife;
