import React from "react";
import { toast } from "react-hot-toast";
import { FaPencilAlt } from "react-icons/fa";

const AdminHeroSection = () => {
  const handleUpadteHeroTitle = (e) => {
    e.preventDefault();
    const form = e.target;
    const heroTitle = { title: form.title.value };

    fetch(
      `https://omniverse-server-3222344a8118.herokuapp.com/update-hero-title/647ff868dd6bc4cfddfddf8e`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(heroTitle),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        toast.success("This content is updated successfully");

        form.reset("");
      })
      .catch((err) => console.error(err));
  };

  const handleUpdateParaOne = (e) => {
    e.preventDefault();
    const form = e.target;
    const paraOne = { paraOne: form.paraOne.value };

    fetch(
      `https://omniverse-server-3222344a8118.herokuapp.com/update-para-one/647ff868dd6bc4cfddfddf8e`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(paraOne),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        toast.success("This content is updated successfully");

        form.reset("");
      })
      .catch((err) => console.error(err));
  };

  const handleUpdateParaTwo = (e) => {
    e.preventDefault();
    const form = e.target;
    const paraTwo = { paraTwo: form.paraTwo.value };
    console.log(paraTwo);
    fetch(
      `https://omniverse-server-3222344a8118.herokuapp.com/update-para-two/647ff868dd6bc4cfddfddf8e`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(paraTwo),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toast.success("This content is updated successfully");

        form.reset("");
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="bg-gray-100 flex  justify-center items-start py-10 px-3 md:px-10 lg:py-0 lg:items-center min-h-screen ">
      <div className="flex flex-col justify-center items-center ">
        <div className="flex flex-col justify-center items-center space-y-1 my-5 w-full lg:w-[800px] px-5 lg:p-10 md:space-y-3 border-2 rounded-md bg-white ">
          <h1 className="text-xl py-2 md:text-3xl text-center text-gray-800 font-semibold mb-10  border-b w-full pb-2">
            Update Hero section{" "}
            <FaPencilAlt className="inline-block text-teal-accent-400 mb-2" />
          </h1>

          <form
            onSubmit={handleUpadteHeroTitle}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update the title
            </lable>
            <textarea
              name="title"
              placeholder="Update the title"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none text-gray-800"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>

          <form
            onSubmit={handleUpdateParaOne}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update first paragraph
            </lable>
            <textarea
              name="paraOne"
              placeholder="Update first paragraph"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none text-gray-800"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>

          <form
            onSubmit={handleUpdateParaTwo}
            className="flex flex-col items-start space-y-3 border-2 p-2 rounded-lg hover:shadow-gray-700 w-full"
          >
            <lable className="text-xl font-semibold pl-2 py-2">
              Update second paragraph
            </lable>
            <textarea
              name="paraTwo"
              placeholder="Update second paragraph"
              className="w-full h-20 md:h-32 pt-3 px-2 text-xl border bg-white border-gray-200 rounded-md outline-none text-gray-800"
            ></textarea>
            <button className="bg-[#fff] w-full text-black border border-gray-300 px-6 py-3 md:py-3 text-xl md:text-2xl font-semibold  shadow-gray-500 shadow-md rounded-md ">
              Update now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminHeroSection;
