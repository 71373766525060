import React from "react";
import ocean from "../assets/ocean.png";
import bracket from "../assets/bracket.png";
import right from "../assets/right.png";
import pahar from "../assets/pahar.png";
import mbOcean from "../assets/rr.png";

const MobileOcean = () => {
  return (
    <div className="">
      <img src={mbOcean} className="w-full md:hidden  " alt="" />
      <div className="relative hidden md:block lg:hidden -mt-20 -mb-10">
        <img src={ocean} className="w-full   " alt="" />
        <img
          src={bracket}
          className="w-5 h-14 md:w-12 md:h-36 absolute top-[155px] left-16 md:top-[350px]  md:left-36"
          alt=""
        />
        <img
          src={bracket}
          className="w-5 h-16 md:w-12 md:h-36  absolute top-[213px] left-16 md:top-[500px]  md:left-36"
          alt=""
        />

        <img
          src={right}
          className="absolute top-[290px] left-6 w-20 h-5 md:w-44 md:h-10 md:top-[698px] md:left-20"
          alt=""
        />

        <p className="text-[6px] md:text-[12px] text-white absolute top-44 left-2 md:top-[400px] md:left-10">
          {" "}
          What appears
        </p>
        <p className="text-[6px] md:text-[12px] text-white absolute top-[225px] left-2 md:top-[550px] md:left-10">
          What lies bellow
        </p>
        <p className="text-[6px] md:text-[12px] text-white absolute top-[233px] left-2 md:top-[570px] md:left-10">
          the surface
        </p>

        <p className="text-[6px] md:text-[12px] font-semibold text-white absolute top-[262px] left-2 md:top-[650px] md:left-10">
          The Root Cause
        </p>
        <p className="text-[7px] md:text-[13px]  font-semibold text-red-500 absolute top-[269px] left-2 md:top-[670px] md:left-10">
          Illusion of Separation
        </p>
        {/* pahar */}
        <div className="grid grid-cols-3 gap-x-2 md:gap-x-3 absolute top-[149px] left-24 md:top-[330px] md:left-56  z-0 pr-2">
          {/* pahar 1*/}
          <div className="relative">
            {/* row1 */}
            <div className="">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Climate change,
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Bio-diversity loss{" "}
              </p>
            </div>
            <img
              src={pahar}
              className="w-20 h-[116px] md:w-40 md:h-72 py-1"
              alt=""
            />
            {/* row-2 */}
            <div className="absolute top-[67px]  left-2 md:top-[165px] md:left-4">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Ineffective Economic
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                & Educational System{" "}
              </p>
            </div>
            {/* row 3*/}
            <div className="absolute top-[87px] left-3 md:top-[220px] md:left-7">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Lack of reverence
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                for nature{" "}
              </p>
            </div>
            {/* row -4 */}
            <p className="text-[5px] md:text-[11px] text-center text-white">
              Of Self from Nature
            </p>
          </div>
          {/* pahar 2*/}
          <div className="relative">
            {/* row1 */}
            <div className="">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Wars, Social Inequity
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Community breakdown{" "}
              </p>
            </div>
            <img
              src={pahar}
              className="w-20 h-[116px] md:w-40 md:h-72 py-1"
              alt=""
            />
            {/* row-2 */}
            <div className="absolute top-[67px] left-3 md:top-[165px] md:left-8">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Inappropriate
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Social Structures{" "}
              </p>
            </div>
            {/* row 3*/}
            <div className="absolute top-[87px] left-3 md:top-[220px] md:left-7">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Lack of empathy
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                &
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                cooperation{" "}
              </p>
            </div>
            {/* row -4 */}
            <p className="text-[5px] md:text-[11px] text-center text-white">
              of Self from Society
            </p>
          </div>
          {/* pahar 3*/}
          <div className="relative">
            {/* row1 */}
            <div className="">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Anxiety, diseases,
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                depression{" "}
              </p>
            </div>
            <img
              src={pahar}
              className="w-20 h-[116px] md:w-40 md:h-72 py-1"
              alt=""
            />
            {/* row-2 */}
            <div className="absolute top-[67px] left-2 md:top-[165px] md:left-5">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Conflicting
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Paradigms & Behavior{" "}
              </p>
            </div>
            {/* row 3*/}
            <div className="absolute top-[87px] left-3 md:top-[220px] md:left-8">
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Lack of virtuous
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                values & Self
              </p>
              <p className="text-[5px] md:text-[11px] text-center text-white">
                Realisation{" "}
              </p>
            </div>
            {/* row -4 */}
            <p className="text-[5px] md:text-[11px] text-center text-white">
              of Self from Higher Self
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileOcean;
