import React, { useRef, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MdOutlineAlternateEmail } from "react-icons/md";
import emailjs from "@emailjs/browser";
import bird from "../assets/bird1.png";
import toast from "react-hot-toast";

const Footer = () => {
  const [email, setEmail] = useState("");
  const mailtoHref = "mailto:snehapoddar11@gmail.com";
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const url =
      "https://script.google.com/macros/s/AKfycbwc4XLsxSCSjb_GGL2wbLnwykYCr_sjtr5_T567AiXYvH4jPuKYSYUJtFKKlOJ6vdYP/exec";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setEmail("");
        toast.success("Thanks for subscribing to our newsletter !!!");
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jxmlgvg",
        "template_omniverse",
        form.current,
        "wsGx46LaoIgI5AnHf"
      )
      .then(
        (result) => {
          toast.success("Your message has been sent successfully !!!");
          form.current.reset();
        },
        (error) => {
          alert(error.text);
        }
      );
  };

  return (
    <div className="mt-32 bg-transparent border-t-2 border-black pt-6 lg:border-none lg:pt-0 overflow-hidden lg:px-28">
      <div className="flex flex-col lg:flex-row justify-between items-end space-y-7 lg:space-y-0 lg:pt-14">
        {/* left */}
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-start space-y-5 pb-5 w-full lg:w-2/5 xl:w-1/2"
        >
          <p className="text-[20px] md:text-2xl 5xl:text-[30px] font-semibold text-gray-800">
            Stay updated!
          </p>
          <div className="space-y-5 lg:space-y-0 lg:space-x-5 3xl:space-x-5">
            <input
              name="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="w-full xl:w-[350px] h-14 px-2 shadow-lg text-lg md:text-xl border border-gray-200 rounded-lg outline-none text-gray-900 bg-white"
              required
            />
            {/* button */}
            <button
              className="relative inline-block text-lg group"
              type="submit"
            >
              <span className=" relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span className="relative">Subscribe</span>
              </span>
              <span className=" absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"></span>
            </button>
          </div>
        </form>
        {/* right */}
        <form
          ref={form}
          onSubmit={sendEmail}
          className="relative flex flex-col items-start justify-end space-y-3 pb-6 w-full lg:w-2/5 xl:w-1/2"
        >
          <p className="text-[20px] md:text-2xl 5xl:text-[30px] font-semibold text-gray-800">
            We're already connected!
          </p>
          <input
            name="email"
            type="email"
            placeholder="Email address"
            className="w-full h-14 px-2 shadow-lg text-lg md:text-xl border border-gray-200 rounded-lg outline-none text-gray-900 bg-white"
            required
          />
          <textarea
            name="message"
            type="text"
            placeholder="Send a message"
            className="w-full h-20 md:h-32 xl:h-40 px-2 shadow-lg text-lg md:text-xl border border-gray-200 rounded-lg outline-none text-gray-900 bg-white"
            required
          />
          {/* button */}
          <button className="relative inline-block text-lg group" type="submit">
            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
              <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
              <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
              <span className="relative">Send</span>
            </span>
            <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"></span>
          </button>
          <div className="hidden lg:block absolute lg:right-0 lg:-top-28 xl:-right-14 xl:-top-24">
            <img
              src={bird}
              className="lg:hidden lg:h-36 lg:w-52 xl:h-48 xl:w-60"
              alt="bird"
            />
          </div>
        </form>
      </div>

      <ul className="flex justify-around w-full lg:w-1/3 my-8 px-4 lg:px-0">
        <li className="bg-white px-3 py-2 md:px-4 md:py-3 rounded-full border-2 border-gray-300 shadow-xl shadow-gray-200 hover:scale-125 duration-200 cursor-pointer">
          <a href={mailtoHref} target="blank" className="text-blue-500">
            <MdOutlineAlternateEmail className="text-xl md:text-3xl text-gray-800" />
          </a>
        </li>
        <li className="bg-white px-3 py-2 md:px-4 md:py-3 rounded-full border-2 border-gray-300 shadow-xl shadow-gray-200 hover:scale-125 duration-200 cursor-pointer">
          <a href="https://www.facebook.com/omniverse.life/" target="blank">
            <FaFacebook className="text-xl md:text-3xl text-gray-800" />
          </a>
        </li>
        <li className="bg-white px-3 py-2 md:px-4 md:py-3 rounded-full border-2 border-gray-300 shadow-xl shadow-gray-200 hover:scale-125 duration-200 cursor-pointer">
          <a href="https://www.youtube.com/@omniverselife7594/" target="blank">
            <FaYoutube className="text-xl md:text-3xl text-gray-800" />
          </a>
        </li>
        <li className="bg-white px-3 py-2 md:px-4 md:py-3 rounded-full border-2 border-gray-300 shadow-xl shadow-gray-200 hover:scale-125 duration-200 cursor-pointer">
          <a
            href="https://www.linkedin.com/company/omniverselife/mycompany/"
            target="blank"
          >
            <FaLinkedinIn className="text-xl md:text-3xl text-gray-800" />
          </a>
        </li>
        <li className="bg-white px-3 py-2 md:px-4 md:py-3 rounded-full border-2 border-gray-300 shadow-xl shadow-gray-200 hover:scale-125 duration-200 cursor-pointer">
          <a href="https://www.instagram.com/omniverse.life/" target="blank">
            <FaInstagram className="text-xl md:text-3xl text-gray-800" />
          </a>
        </li>
        <li className="bg-white px-3 py-2 md:px-4 md:py-3 rounded-full border-2 border-gray-300 shadow-xl shadow-gray-200 hover:scale-125 duration-200 cursor-pointer">
          <a href="https://omniverselife.substack.com/" target="blank">
            <SiSubstack className="text-xl md:text-3xl text-gray-800" />
          </a>
        </li>
      </ul>

      <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start border-t-2 border-black pt-3 lg:pt-2  ">
        <p className="text-[13.65px] md:text-[16px] 6xl:text-[19px] text-center lg:text-left text-gray-800 lg:pr-0 xl:pr-0 5xl:pr-9 lg:w-5/12 xl:7/12 2xl:w-7/12">
          Omniverse Life is an initiative hybridly supported by a social
          enterprise and a not-for-profit organisation registered in India and
          approved by the Government of India as a tax-exempt charitable
          organisation.
        </p>
        <div className="flex flex-col justify-center items-center lg:flex-row lg:items-end lg:justify-end pt-4 lg:pt-0 w-full lg:w-7/12 xl:5/12 2xl:w-4/12">
          <a
            href="/privacy-policy"
            className="text-[13.65px] md:text-[16px] 6xl:text-[19px] text-gray-800 cursor-pointer"
          >
            Privacy Policy |
          </a>
          <a
            href="/terms-condition"
            className="text-[13.65px] md:text-[16px] 6xl:text-[19px] text-gray-800 pl-0 lg:pl-0 cursor-pointer"
          >
            Terms of Use |
          </a>
          <p className="text-[13.65px] md:text-[16px] 6xl:text-[19px] text-gray-800 pt-4 lg:pt-0">
            © 2023 omniverse.life
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
