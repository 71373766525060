import React from "react";
import slider1 from "../../assets/slider1.png";

export default function SliderUi() {
  return (
    <div className="px-5 py-9 md:px-10 lg:px-10 xl:px-20 3xl:px-14  md:pb-5   4xl:px-28 6xl:px-32 bg-white lg:h-[380px]  2xl:h-[420px] 6xl:h-[500px] ">
      <div className="flex flex-col-reverse lg:flex-row justify-around items-center">
        <img
          src={slider1}
          className="h-60 w-60 md:h-[400px] md:w-[400px] xl:h-[500px] xl:w-[500px] 3xl:h-[600px] 3xl:w-[600px] space-y-3"
          alt="sliderimage"
        />
        <div className="space-y-3 xl:space-y-4 3xl:space-y-5 6xl:space-y-7">
          <p className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 tracking-normal xl:tracking-wide">
            The Reality is that we live in a web of interconnected systems
            within systems.
          </p>
          <p className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 tracking-normal xl:tracking-wide py-1">
            The simple truth is that wellbeing of one is interdependent on the
            wellbeing of all like a flower of life in an intricate balance.
          </p>
          <p className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 tracking-normal xl:tracking-wide">
            The problem of our time is of imbalance. Due to ignorant human
            activities we have made a hell within and without creating havoc for
            all life forms on Earth.
          </p>
          <p className="font-light text-[13.65px] md:text-[20px] lg:text-[15px] xl:text-[18px] 3xl:text-[21px] 5xl:text-[23px] 6xl:text-[26px] text-gray-800 tracking-normal xl:tracking-wide">
            The challenge now is now how do we transition or evolve from
            hell-being to well-being?
          </p>
        </div>
      </div>
    </div>
  );
}
