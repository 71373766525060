import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./project.css";
import { Navigation } from "swiper";

export default function Project() {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-projects")
      .then((res) => res.json())
      .then((data) => {
        setSliders(data);
      });
  }, []);

  return (
    <div
      id="projects"
      className="px-0 pt-4 xl:pt-10 md:pt-0 pb-5  md:px-7  lg:px-8 xl:px-7 4xl:px-10  6xl:px-20 xl:py-0 bg-white "
    >
      <h1 className="text-3xl md:text-[45px] 2xl:text-5xl font-semibold lg:font-normal text-center lg:text-start  text-gray-800 mb-5 md:mb-16 xl:mb-5 lg:pl-12">
        Projects
      </h1>
      {sliders.length === 1 ? (
        sliders.map((slider) => (
          <div className="flex flex-col-reverse lg:flex-row justify-around items-center px-5 md:px-4 lg:px-6 xl:px-0">
            <div className="lg:px-2 xl:px-12">
              <p className="text-[16px] md:text-2xl lg:text-3xl xl:text-2xl text-center lg:text-start font-semibold text-gray-800 tracking-normal xl:tracking-wide">
                {slider.projectTitle}
              </p>
              <p className="hidden lg:block text-[13.65px] md:text-2xl xl:text-[18px] 6xl:text-[24px] text-center md:text-start font-semibold py-3 text-gray-800 tracking-normal xl:tracking-wide">
                Description
              </p>

              <p className="pt-4 lg:pt-0 font-light text-[13.65px] md:text-[16px] xl:text-[20px] 6xl:text-[24px] text-start  text-gray-800 tracking-wide xl:tracking-wide">
                {slider.description}
              </p>
              {slider.projectLink ? (
                <a href={slider.projectLink} target="blank">
                  <button
                    className="relative inline-block text-lg group mt-3"
                    type="submit"
                  >
                    <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                      <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                      <span className="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                      <span className="relative">Read more</span>
                    </span>
                    <span
                      className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                      data-rounded="rounded-lg"
                    ></span>
                  </button>
                </a>
              ) : (
                <p className="text-[18px] md:text-2xl xl:text-[18px] 6xl:text-[24px] text-start font-semibold  text-gray-800 tracking-normal xl:tracking-wide mt-3">
                  Revealing Soon
                </p>
              )}
            </div>
            <img
              src={slider.img}
              className="h-60 w-full 2xl:w-6/12 6xl:w-5/12  md:h-[450px]  lg:h-[380px] lg:w-[400px] xl:h-[450px] xl:w-[500px] 5xl:h-[520px] rounded-lg 3xl:rounded-2xl  lg:px-0 mb-5 xl:pr-10  2xl:pr-10 4xl:pr-14"
              alt="sliderimage"
            />
          </div>
        ))
      ) : (
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {sliders.map((slider) => (
            <SwiperSlide
              className="pl-0 pt-0 xl:pt-9  lg:pl-10 xl:pl-0"
              key={slider.id}
            >
              <div className="flex flex-col-reverse lg:flex-row justify-around items-center">
                <div className="px-12 md:px-16 lg:px-2 xl:px-12">
                  <p className="text-[16px] md:text-2xl lg:text-3xl xl:text-2xl text-center lg:text-start font-semibold text-gray-800 tracking-normal xl:tracking-wide">
                    {slider.projectTitle}
                  </p>
                  <p className="hidden lg:block text-[13.65px] md:text-2xl xl:text-[18px] 6xl:text-[24px] text-center md:text-start font-semibold py-3 text-gray-800 tracking-normal xl:tracking-wide">
                    Description
                  </p>

                  <p className="pt-4 lg:pt-0 font-light text-[13.65px] md:text-[16px] xl:text-[20px] 6xl:text-[24px] text-start  text-gray-800 tracking-wide xl:tracking-wide">
                    {slider.description}
                  </p>
                </div>
                <img
                  src={slider.img}
                  className="h-52 w-52 md:h-[400px] md:w-[600px] lg:h-[400px] lg:w-[400px] xl:h-[450px] xl:w-[500px] rounded-lg 3xl:rounded-2xl md:px-6 lg:px-0 mb-5 lg:pr-12 2xl:pr-20 4xl:pr-14"
                  alt="sliderimage"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
