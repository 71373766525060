import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "./Swiper.css";

export default function PublicationSlider() {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setSliders(data);
      });
  }, []);

  return (
    <div className="bg-white flex flex-col justify-center space-y-5 md:space-y-10">
      <div className="bg-white smaller-carousel">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {sliders.map((slider) => (
            <SwiperSlide key={slider.id} className="flex flex-col items-center">
              <a href={slider.link} target="_blank" className="flex flex-col ">
                <div className="px-4 md:px-6 lg:px-8 mx-6">
                  <img
                    src={slider.img}
                    className="h-40 md:h-80 lg:h-100  w-full  rounded-xl object-cover"
                    alt="sliderimage"
                  />
                  <p className="font-medium text-[16px] md:text-2xl lg:text-[28px] text-center py-2 text-gray-800">
                    {slider.title}
                  </p>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex justify-center"> {/* Center the button */}
          <a href="https://omniverse.life/articles" className="cursor-pointer">
            <label className="relative inline-block text-lg group" type="submit">
              <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span className="absolute left-0 w-full h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span className="relative">View all articles</span>
              </span>
              <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
            </label>
          </a>
        </div>
      </div>
    </div>
  );
}
