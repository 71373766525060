import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../HomePage/Footer";

const PublicationPage = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetch("https://omniverse-server-3222344a8118.herokuapp.com/all-blogs")
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
      });
  }, []);
  return (
    <div className="bg-white">
      <Navbar />

      <div className="px-5 py-5 md:px-10 lg:px-10 xl:px-20 3xl:px-16 4xl:px-28 max-h-[100vh] overflow-y-auto scrollbar-hide">
        {blogs.map((blog, index) => (
          <div
            className="flex flex-col  lg:flex-row justify-between my-9 md:my-16"
            key={index}
          >
            {index % 2 === 0 ? (
              <div
                className="flex flex-col-reverse  lg:flex-row justify-between items-center"
                style={{ float: "right" }}
              >
                <div className="flex flex-col justify-center items-center lg:items-start pr-0 lg:pr-10 space-y-3 3xl:space-y-6 w-full lg:w-7/12 5xl:w-6/12 ">
                  <p className="text-center lg:text-left text-[20px] md:text-[28px] font-medium  text-gray-800 w-full">
                    {blog.title}
                  </p>
                  <p className="hidden 3xl:block text-[21px] md:text-[22px] font-normal  text-gray-800 w-full">
                    {blog?.description?.slice(0, 400)} ...
                  </p>
                  <p className="text-center lg:text-left 3xl:hidden text-[16px] md:text-[22px] font-normal  text-gray-800 w-full pb-2 lg:pb-3">
                    {blog?.description?.slice(0, 320)} ...
                  </p>
                  <a href={blog.link} target="blank">
                    <button
                      class="relative inline-block text-lg group"
                      type="submit"
                    >
                      <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                        <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                        <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                        <span class="relative">Read more</span>
                      </span>
                      <span
                        class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                        data-rounded="rounded-lg"
                      ></span>
                    </button>
                  </a>
                </div>
                <img
                  src={blog.img}
                  alt=""
                  className="w-full lg:w-5/12 5xl:w-6/12 h-[250px] md:h-[400px] lg:h-[380px] 5xl:h-[450px] mb-5 lg:mb-0"
                />
              </div>
            ) : (
              <div
                className="flex flex-col-reverse lg:flex-row-reverse justify-between items-center"
                style={{ float: "right" }}
              >
                <div className="flex flex-col justify-center items-center lg:items-start pl-0 lg:pl-10 space-y-3 3xl:space-y-6 w-full lg:w-6/12 5xl:w-6/12">
                  <p className="text-center lg:text-left text-[20px] md:text-[28px] font-medium  text-gray-800 w-full">
                    {blog.title}
                  </p>
                  <p className="hidden 3xl:block text-[21px] md:text-[22px] font-normal  text-gray-800 w-full">
                    {blog?.description?.slice(0, 400)} ...
                  </p>
                  <p className="text-center lg:text-left 3xl:hidden text-[16px] md:text-[22px] font-normal  text-gray-800 w-full pb-2 lg:pb-3">
                    {blog?.description?.slice(0, 320)} ...
                  </p>
                  <a href={blog.link} target="blank">
                    <button
                      class="relative inline-block text-lg group"
                      type="submit"
                    >
                      <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                        <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                        <span class="absolute left-0 w-56 h-48 -ml-0 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                        <span class="relative">Reads more</span>
                      </span>
                      <span
                        class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                        data-rounded="rounded-lg"
                      ></span>
                    </button>
                  </a>
                </div>
                <img
                  src={blog.img}
                  alt=""
                  className="w-full lg:w-6/12 5xl:w-6/12 h-[250px] md:h-[400px] lg:h-[380px] 5xl:h-[450px] mb-5 lg:mb-0"
                />
              </div>
            )}
          </div>
        ))}
        <div className="pt-36">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PublicationPage;
