import React, { useEffect, useState } from "react";
import Footer from "../../HomePage/Footer";

const Soonya = () => {
  const [videos, setVideos] = useState([]);
  const [visibleVideos, setVisibleVideos] = useState(2);
  const [showAllVideos, setShowAllVideos] = useState(false);

  const handleShowMoreVideos = () => {
    if (!showAllVideos) {
      setVisibleVideos(videos.length);
    } else {
      setVisibleVideos(2);
    }
    setShowAllVideos((prevShowAllVideos) => !prevShowAllVideos);
  };

  useEffect(() => {
    fetch(
      "https://omniverse-server-3222344a8118.herokuapp.com/get-shoonya-videos"
    )
      .then((res) => res.json())
      .then((data) => {
        setVideos(data);
      });
  }, []);
  return (
    <div
      id="shoonya"
      className=" bg-white px-5 pt-5 md:px-10 md:pt-10 md:pb-5 xl:px-20 xl:pt-10 font-sans"
    >
      <h1 className="text-[25px] md:text-[35px] lg:text-[37px] xl:text-[47px] 2xl:text-[50px] 3xl:text-[55px] 4xl:text-[58px] 5xl:text-[60px] 6xl:text-[65px] text-gray-800 font-normal  text-center lg:text-start md:leading-[57px] lg:leading-[57px] xl:leading-[59px] 2xl:leading-[64px] 3xl:leading-[69px] 4xl:leading-[76px] 6xl:leading-[70px] py-5 md:pb-10 lg:pb-10">
        Shoonya Conversation Series
      </h1>
      <div className="flex flex-col justify-center items-start space-y-6 xl:space-y-6 mb-5 md:pb-10">
        <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
          Shoonya, the Sanskrit word for "zero" or "emptiness," represents the
          limitless potential and infinite possibilities that arise when we
          create space for authentic conversation. It is in this emptiness that
          ideas can flourish, perspectives can be shared, and new insights can
          emerge.
        </p>

        <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px] font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
          Our conversation series brings together diverse voices from various
          backgrounds, cultures, and perspectives. We believe that by embracing
          diversity, we can expand our own horizons and deepen our understanding
          of the world around us. From thought leaders and experts to artists
          and everyday individuals, our speakers share their wisdom,
          experiences, and unique insights, inviting you to join them in
          exploring the depths of human existence.
        </p>
        <p className="text-[16px] md:text-[20px] lg:text-[16px] xl:text-[18px] 6xl:text-[28px]  font-normal text-gray-800 leading-8 md:leading-9 lg:leading-8 xl:leading-[38px] 6xl:leading-[44px]">
          Each conversation is carefully curated to delve into a specific topic
          or theme, ranging from personal growth and spirituality to societal
          issues and global challenges. Through thought-provoking questions and
          meaningful discussions, we aim to ignite curiosity, spark
          introspection, and inspire positive change. Join us as we embark on a
          journey of discovery and connection, where ideas collide, perspectives
          intersect, and new possibilities unfold.
        </p>
      </div>
      <div className="bg-white pt-5 pb-10 md:pb-10 lg:py-16 5xl:pt-20">
        {videos.length === 0 ? (
          <div className="flex flex-row  justify-center items-center">
            <h1 className="text-3xl md:text-[45px] 2xl:text-4xl font-normal text-center  py-3 md:py-8 text-gray-800 tracking-wide  font-montserrat">
              COMING SOON
            </h1>
          </div>
        ) : (
          <div className="">
            {videos.slice(0, visibleVideos).map((video, index) => (
              <div
                className="flex justify-between my-5 md:my-8 lg:my-14 5xl:my-20"
                key={index}
              >
                {index % 2 === 0 ? (
                  <div
                    className="flex flex-col-reverse lg:flex-row justify-between items-center"
                    style={{ float: "right" }}
                  >
                    <p className="pr-5 3xl:pr-8 5xl:pr-10 text-center lg:text-start text-[16px] md:text-[22px] lg:text-[18px] 3xl:text-[20px] font-normal 3xl:leading-[35px]  text-gray-800 w-full">
                      {video?.videoTitle.length > 500
                        ? video?.videoTitle.slice(0, 480) + " ..."
                        : video?.videoTitle}
                    </p>
                    <iframe
                      src={video.videoLink}
                      title="YouTube video player"
                      frameborder="0"
                      allowfullscreen
                      className="h-[270px] md:h-[350px] lg:h-[320px] w-full mb-5 lg:mb-0"
                      controls
                    ></iframe>
                  </div>
                ) : (
                  <div
                    className="flex flex-col-reverse lg:flex-row-reverse justify-between items-center"
                    style={{ float: "right" }}
                  >
                    <p className="pl-5 3xl:pl-8 5xl:pl-10 text-center lg:text-start text-[16px] md:text-[22px] lg:text-[18px] 3xl:text-[20px] font-normal 3xl:leading-[35px] text-gray-800 w-full ">
                      {video?.videoTitle.length > 500
                        ? video?.videoTitle.slice(0, 480) + " ..."
                        : video?.videoTitle}
                    </p>
                    <iframe
                      src={video.videoLink}
                      title="YouTube video player"
                      frameborder="0"
                      allowfullscreen
                      className="h-[270px] md:h-[350px] lg:h-[320px] w-full mb-5 lg:mb-0"
                      controls
                    ></iframe>
                  </div>
                )}
              </div>
            ))}
            <div className="flex flex-col-reverse items-center justify-center  my-4">
              {videos.length > 2 && (
                <button
                  class="relative inline-block text-lg group "
                  onClick={handleShowMoreVideos}
                >
                  <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                    <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                    <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                    <span class="relative">
                      {showAllVideos ? "Close all" : "More Videos"}
                    </span>
                  </span>
                  <span
                    class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                    data-rounded="rounded-lg"
                  ></span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="bg-white pt-10">
        <Footer />
      </div>
    </div>
  );
};

export default Soonya;
